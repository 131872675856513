
import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { BehaviorSubject, catchError, Observable, tap } from 'rxjs';
import { AdminBidderHistoryWrapper } from '../models/AdminBidderHistoryWrapper';
import { AdminBidderRegistrationWrapper } from '../models/AdminBidderRegistrationWrapper';
import { AuctionEntityDto } from '../models/AuctionEntityDto';
import { CompanyDto } from '../models/CompanyDto';
import { ServerAPIResponseDto } from '../models/ServerAPIResponseDto';
import { AddAdminDto } from '../models/user/AddAdminDto';
import { UserAuctionBidsHistoryDto } from '../models/UserAuctionBidsHistoryDto';
import { UserAuctionRegistrationDto } from '../models/UserAuctionRegistrationDto';
import { AuctionwareConstants } from '../util/AuctionwareConstants';
import { AuctionwareUtil } from '../util/AuctionwareUtil';
import { ErrorService } from './error.service';
import { BidderDetailsSaveRequest } from '../models/BidderDetailsSaveRequest';
import { ClientBrandDetailsDto } from '../models/ClientBrandDetailsDto';

@Injectable({
  providedIn: 'root'
})
export class AdminAuctionService {

  companyDto$ = new BehaviorSubject<CompanyDto | null>(null);
  auctionEntityDto$ = new BehaviorSubject<AuctionEntityDto | null>(null);
  _updateRegistrationUserList$ = new BehaviorSubject<boolean>(false);
  // _bucketSize$ = new BehaviorSubject<number>(0);
  _userAuctionRegistrationDto$ = new BehaviorSubject<UserAuctionRegistrationDto | null>(null);
  // selectedUserId?: string;

  _adminBidderHistoryWrapper$ = new BehaviorSubject<AdminBidderHistoryWrapper | null>(null)
  _adminBidderHistoryWrapper?: AdminBidderHistoryWrapper;

  _adminBidderRegistrationWrapper$ = new BehaviorSubject<AdminBidderRegistrationWrapper | null>(null);
  _adminBidderRegistrationWrapper?: AdminBidderRegistrationWrapper;

  _clientBrandDetailsList$ = new BehaviorSubject<Array<ClientBrandDetailsDto> | []>([])
  _clientBrandDetailsList?: Array<ClientBrandDetailsDto> = [];

  constructor(
    private httpClient: HttpClient,
    private errorService: ErrorService,
  ) { }

  get getCompanyDto$() { return this.companyDto$ };
  get getAuctionEntityDto$() { return this.auctionEntityDto$ };
  get getAdminBidderHistoryWrapper$() { return this._adminBidderHistoryWrapper$ };
  get getAdminBidderRegistrationWrapper$() { return this._adminBidderRegistrationWrapper$ };
  get getClientBrandDetailsList$() { return this._clientBrandDetailsList$ };

  updateCompanyDto(companyDto: CompanyDto){
    this.companyDto$.next(companyDto);
  }

  updateAuctionEntityDto(auctionEntityDto: AuctionEntityDto){
    this.auctionEntityDto$.next(auctionEntityDto);
  }

  updateRegistrationUserList() {
    this._updateRegistrationUserList$.next(true);
  }

  // updateBucketSize(bucketSize?: number) {
  //   this._bucketSize$.next(bucketSize!);
  // }

  updateAdminBidderRegistrationWrapper(currentUserId: string, highestMaxBid: number) {
    if (this._adminBidderRegistrationWrapper && this._adminBidderRegistrationWrapper?.userAuctionRegistrationDtos
      && this._adminBidderRegistrationWrapper?.userAuctionRegistrationDtos.length > 0) {
      let selectedUserData = this._adminBidderRegistrationWrapper?.userAuctionRegistrationDtos.find(item => item.userId == currentUserId);

      if (selectedUserData) {
        selectedUserData.highestMaxBid = highestMaxBid;
        this._adminBidderRegistrationWrapper$.next(this._adminBidderRegistrationWrapper);
      }
    }
  }

  updateClientBrandDetailsData(clientBrandDetails: Array<ClientBrandDetailsDto>){
    this._clientBrandDetailsList = clientBrandDetails;
    this._clientBrandDetailsList$.next(this._clientBrandDetailsList);
  }

  // Load Data
  getUserBidsHistoryData() {
    this.getBidderHistoryData().subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == AuctionwareConstants.SUCCESS_CODE) {
          this._adminBidderHistoryWrapper = apiResponseDto.data as AdminBidderHistoryWrapper;
          this._adminBidderHistoryWrapper$.next(this._adminBidderHistoryWrapper);
        }
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

    // Load Data
    getClientBrandDetailsData() {
      this.getClientBrandDetailsList().subscribe({
        next: (apiResponseDto: ServerAPIResponseDto) => {
          if (apiResponseDto && apiResponseDto.code == AuctionwareConstants.SUCCESS_CODE) {
            this._clientBrandDetailsList = apiResponseDto.data as Array<ClientBrandDetailsDto>;
            this._clientBrandDetailsList$.next(this._clientBrandDetailsList);
          }
        },
        error: (error) => {
          console.error(error);
        }
      });
    }



  getBidderRegistrationData() {
    this.getBidderRegistrationList().subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == AuctionwareConstants.SUCCESS_CODE) {
          this._adminBidderRegistrationWrapper = apiResponseDto.data as AdminBidderRegistrationWrapper;
          this._adminBidderRegistrationWrapper$.next(this._adminBidderRegistrationWrapper);
        }
      },
      error: (error) => {
        console.error(error);
      }
    });
  }


  // Api Calls
  saveCompanyDetails(companyDto: CompanyDto) {
    return this.httpClient.post<ServerAPIResponseDto>('company', companyDto).pipe(
      tap(_ => console.log("Saved Company to DB" + companyDto.companyName)),
      catchError(this.errorService.handleError<any>("Error while Saving Company to DB" + companyDto.companyName)))
  }

  saveAuctionDetails(auctionEntityDto: AuctionEntityDto) {
    return this.httpClient.post<ServerAPIResponseDto>('auction', auctionEntityDto).pipe(
      tap(_ => console.log("Saved Auction to DB" + auctionEntityDto.auctionId)),
      catchError(this.errorService.handleError<any>("Error while Saving Auction to DB" + auctionEntityDto.auctionId)))
  }

  saveBidderDetails(bidderDetailsSaveRequest: BidderDetailsSaveRequest) {
    return this.httpClient.post<ServerAPIResponseDto>('saveBidderDetails', bidderDetailsSaveRequest).pipe(
      tap(_ => console.log("Saved Bidder Details" + bidderDetailsSaveRequest.userId)),
      catchError(this.errorService.handleError<any>("Error while Saving Bidder Details to DB" + bidderDetailsSaveRequest.userId)))
  }


  changeSiteMode() {
    return this.httpClient.post<ServerAPIResponseDto>('changeSite',null).pipe(
      tap(_ => console.log("change site: " + AuctionwareUtil.getFullDomainName())),
      catchError(this.errorService.handleError<any>("Error while change site" + AuctionwareUtil.getFullDomainName())))
  }

  deleteAllBids() {
    return this.httpClient.post<ServerAPIResponseDto>('deleteAllBids',null).pipe(
      tap(_ => console.log("deleteAllBids: " + AuctionwareUtil.getFullDomainName())),
      catchError(this.errorService.handleError<any>("Error while deleteAllBids" + AuctionwareUtil.getFullDomainName())))
  }

  deleteUser(userId: string) {
    let params = new HttpParams().set('userId', userId);
    return this.httpClient.get<ServerAPIResponseDto>('deleteBidder',{params}).pipe(
      tap(_ => console.log("deleteBidder: " + userId)),
      catchError(this.errorService.handleError<any>("Error while delete user" +userId)))
  }

  overrideRegistration(userId: string) {
    let params = new HttpParams().set('userId', userId);
    return this.httpClient.get<ServerAPIResponseDto>('overrideRegistration',{params}).pipe(
      tap(_ => console.log("overwriteRegistration: " + userId)),
      catchError(this.errorService.handleError<any>("Error while override Registration" + userId)))
  }

  deleteAllUsers() {
    return this.httpClient.post<ServerAPIResponseDto>('deleteAllUsers',null).pipe(
      tap(_ => console.log("deleteAllUsers: " + AuctionwareUtil.getFullDomainName())),
      catchError(this.errorService.handleError<any>("Error while deleteAllUsers" + AuctionwareUtil.getFullDomainName())))
  }

  sendTestEmail() {
    return this.httpClient.get<ServerAPIResponseDto>('testEmail').pipe(
      tap(_ => console.log("Send Test Email Successfully")),
      catchError(this.errorService.handleError<any>("Error while send Test Email")))
  }

  changeClientBrandName(brandName: string) {
    let params = new HttpParams().set('brandName', brandName);
    return this.httpClient.get<ServerAPIResponseDto>('changeClientBrandName', {params}).pipe(
      tap(_ => console.log("changeClientBrandName Successfully")),
      catchError(this.errorService.handleError<any>("Error while changeClientBrandName")))
  }


  getBidderRegistrationList() {
    return this.httpClient.get<ServerAPIResponseDto>('registrationList').pipe(
      tap(_ => console.log("Getting Registration List to DB")),
      catchError(this.errorService.handleError<any>("Error while getting Registration List from DB")))
  }

  getBidderHistoryData() {
    return this.httpClient.get<ServerAPIResponseDto>('userBidsHistoryData').pipe(
      tap(_ => console.log("Getting bidder history data to DB")),
      catchError(this.errorService.handleError<any>("Error while getting bidder history data from DB")))
  }

  getBidHistoryData() {
    return this.httpClient.get<ServerAPIResponseDto>('auctionBidHistoryInfo').pipe(
      tap(_ => console.log("Getting auction Bid HistoryInfo data to DB")),
      catchError(this.errorService.handleError<any>("Error while getting auction Bid HistoryInfo from DB")))
  }

  auctionEnableAndDisable(statusAuction: string) {
    let parms = new HttpParams().set('statusAuction', statusAuction);
    return this.httpClient.post<ServerAPIResponseDto>('auctionEnableAndDisable', null, { params: parms }).pipe(
      tap(_ => console.log("Auction Enable And Disable" + statusAuction)),
      catchError(this.errorService.handleError<any>("Error while Auction Enable And Disable" + statusAuction)))
  }

  changeRegistrationStatus(userId: string, registrationStatus: string) {
    let params = new HttpParams().set('userId', userId).set('registrationStatus', registrationStatus);
    return this.httpClient.post<ServerAPIResponseDto>('changeRegistrationStatus', null, { params: params }).pipe(
      tap(_ => console.log(`Change Status : userId => " + ${userId} | status => ${status}`)),
      catchError(this.errorService.handleError<any>("Error while change user status" + userId)));
  }

  notifyBidder(userId: string) {
    let params = new HttpParams().set('userId', userId);
    return this.httpClient.get<ServerAPIResponseDto>('notifyBidder', { params: params }).pipe(
      tap(_ => console.log(`notify bidder : userId => " + ${userId}`)),
      catchError(this.errorService.handleError<any>("Error while notify user" + userId)));
  }

  rejectBid(userId: string, auctionId: string, bidPrice: number) {
    let params = new HttpParams().set('userId', userId).set('auctionId', auctionId).set('bidPrice', bidPrice);
    return this.httpClient.post<ServerAPIResponseDto>('rejectBid', null, { params: params }).pipe(
      tap(_ => console.log(`Reject Bid for User : userId => " + ${userId} | status => ${status} | bidPrice => ${bidPrice}`)),
      catchError(this.errorService.handleError<any>("Error while rejecting bid for user" + userId)));
  }

  addUser(addAdminDto: AddAdminDto): Observable<any> {
    return this.httpClient.post<ServerAPIResponseDto>('addUser', addAdminDto).pipe(
      tap(_ => console.log("Admin Add Successfully : " + addAdminDto.emailId)),
      catchError(this.errorService.handleError("Error while adding admin : " + addAdminDto.emailId)))
  }

  updateAddUser(addAdminDto: AddAdminDto): Observable<any> {
    return this.httpClient.post<ServerAPIResponseDto>('updateAddUser', addAdminDto).pipe(
      tap(_ => console.log("Admin update Successfully : " + addAdminDto.emailId)),
      catchError(this.errorService.handleError("Error while update admin : " + addAdminDto.emailId)))
  }

  fetchUsers(): Observable<any> {
    return this.httpClient.get<ServerAPIResponseDto>('fetchUsers').pipe(
      tap(_ => console.log("Admin Fetch Successfully")),
      catchError(this.errorService.handleError("Error while fetching admins")))
  }

  downloadBidderList(){
    const headers = new HttpHeaders().set('Content-Type', 'text/csv');
    const httpOptions = {
        'responseType'  : 'arraybuffer' as 'json',
         'headers' : headers
      };
    return this.httpClient.get<any>('downloadBidderList', httpOptions);
  }

  getClientBrandDetailsList() {
    return this.httpClient.get<ServerAPIResponseDto>('clientBrandDetailsList').pipe(
      tap(_ => console.log("Getting clientBrandDetails List to DB")),
      catchError(this.errorService.handleError<any>("Error while getting clientBrandDetails List from DB")))
  }

  saveClientBrandDetails(clientBrandDetailsDto: ClientBrandDetailsDto) {
    return this.httpClient.post<ServerAPIResponseDto>('clientBrandDetails', clientBrandDetailsDto).pipe(
      tap(_ => console.log("Saved Company to DB" + clientBrandDetailsDto.brandName)),
      catchError(this.errorService.handleError<any>("Error while Saving client brand detils to DB" + clientBrandDetailsDto.brandName)))
  }

}
