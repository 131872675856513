
<div class="e-popup ee-width-s" id="e-popuploading">
  <div class="e-popupblock ea-popupremove"></div>
  <div class="e-popuppage">
    <div class="e-popupmain">
      <div class="e-maintext e-maintext-m">
        <h1>{{message}}</h1>
      </div>
    </div>
  </div>
</div>