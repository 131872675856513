import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { NgbDatepickerModule, NgbDropdownModule, NgbModule, NgbPaginationModule, NgbPopoverModule, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { RegistrationComponent } from '../layouts/landing/registration/registration.component';
import { LoginComponent } from '../layouts/landing/login/login.component';
import { ForgotPasswordComponent } from '../layouts/landing/forgot-password/forgot-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderComponent } from './components/loader/loader.component';
import { ChangePasswordComponent } from '../layouts/supplier/change-password/change-password.component';
import { MyDetailsComponent } from '../layouts/supplier/my-details/my-details.component';
import { LogoutPopupModalComponent } from '../layouts/landing/logout-popup-modal/logout-popup-modal.component';
import { HtmlViewComponent } from './components/html-view/html-view.component';
import { LandingDashboardSidebarComponent } from '../layouts/landing/landing-dashboard-sidebar/landing-dashboard-sidebar.component';
import { SupplierDashboardSidebarComponent } from '../layouts/supplier/supplier-dashboard-sidebar/supplier-dashboard-sidebar.component';
import { AdminPropertySetupModalComponent } from '../layouts/admin/admin-property-setup-modal/admin-property-setup-modal.component';
import { AngularEditorModule } from 'src/assets/plugins/angular-editor';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { GalleryImagesComponent } from './components/gallery-images/gallery-images.component';
import { LoadingPopupComponent } from './components/loading-popup/loading-popup.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { LongPressDirective } from './directive/long-press.directive';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { BidderUserTermsComponent } from './components/bidder-user-terms/bidder-user-terms.component';
import { SellerUserTermsComponent } from './components/seller-user-terms/seller-user-terms.component';
import { TermsOfUseForAuctionComponent } from './components/terms-of-use-for-auction/terms-of-use-for-auction.component';
import { MessagePopupComponent } from './components/message-popup/message-popup.component';
import { RouterModule } from '@angular/router';
import { SafePipe } from './pipes/safe.pipe';
import { PrivacyPolicyAuctionwareComponent } from './components/privacy-policy-auctionware/privacy-policy-auctionware.component';
import { TermsOfUseForAuctionAuctionwareComponent } from './components/terms-of-use-for-auction-auctionware/terms-of-use-for-auction-auctionware.component';
import { SellerUserTermsAuctionwareComponent } from './components/seller-user-terms-auctionware/seller-user-terms-auctionware.component';
import { BidderUserTermsAuctionwareComponent } from './components/bidder-user-terms-auctionware/bidder-user-terms-auctionware.component';
import { ShareButtonsComponent } from './components/share-buttons/share-buttons.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    RegistrationComponent,
    LoginComponent,
    ForgotPasswordComponent,
    LoaderComponent,
    ChangePasswordComponent,
    MyDetailsComponent,
    LogoutPopupModalComponent,
    HtmlViewComponent,
    SupplierDashboardSidebarComponent,
    LandingDashboardSidebarComponent,
    AdminPropertySetupModalComponent,
    GalleryImagesComponent,
    LoadingPopupComponent,
    CarouselComponent,
    LongPressDirective,
    PrivacyPolicyComponent,
    BidderUserTermsComponent,
    SellerUserTermsComponent,
    TermsOfUseForAuctionComponent,
    MessagePopupComponent,
    SafePipe,
    PrivacyPolicyAuctionwareComponent,
    TermsOfUseForAuctionAuctionwareComponent,
    SellerUserTermsAuctionwareComponent,
    BidderUserTermsAuctionwareComponent,
    ShareButtonsComponent
  ],
  imports: [
    CommonModule,
    NgbDropdownModule,
    FormsModule,
    ReactiveFormsModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgbPopoverModule,
    NgbPaginationModule,
    AngularEditorModule,
    GooglePlaceModule,
    DragDropModule,
    RouterModule,
    NgbModule
  ],
  exports: [
    CommonModule,
    HeaderComponent,
    FooterComponent,
    NgbDropdownModule,
    RegistrationComponent,
    LoginComponent,
    ForgotPasswordComponent,
    LoaderComponent,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgbPopoverModule,
    ChangePasswordComponent,
    MyDetailsComponent,
    LogoutPopupModalComponent,
    SupplierDashboardSidebarComponent,
    LandingDashboardSidebarComponent,
    NgbPaginationModule,
    AdminPropertySetupModalComponent,
    AngularEditorModule,
    GooglePlaceModule,
    GalleryImagesComponent,
    LoadingPopupComponent,
    CarouselComponent,
    LongPressDirective,
    DragDropModule,
    SafePipe,
    ShareButtonsComponent
  ]
})
export class SharedModule { }
