<div class="e-popup ee-width-s" id="e-popuploading">
  <div class="e-popupblock ea-popupremove"></div>
  <div class="e-popuppage">
    <header class="e-popupheader">
      <h1>Loading</h1>
    </header>
    <div class="e-popupmain">

      <div class="e-maintext e-maintext-m">
        <div class="e-spinner" style="position: relative !important; top: 0 !important"></div>
      </div>

    </div>
  </div>
</div>