export class RegistrationUserModelDto {
  mobileNo?: string;
  name?: string;
  officeName? : string;
  officeNo?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  signatureName?: string;
  otpId?: string
  userEnteredOtp?: string
}
