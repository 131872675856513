import { Component, OnInit, ViewChild, HostListener, ElementRef, AfterViewChecked } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { LoaderComponent } from 'src/app/shared/components/loader/loader.component';
import { TermsOfUseForAuctionComponent } from 'src/app/shared/components/terms-of-use-for-auction/terms-of-use-for-auction.component';
import { AuctionEntityDto } from 'src/app/shared/models/AuctionEntityDto';
import { AuthenticationResponseDto } from 'src/app/shared/models/AuthenticationResponseDto';
import { ClientBrandDetailsDto } from 'src/app/shared/models/ClientBrandDetailsDto';
import { RegistrationUserModelDto } from 'src/app/shared/models/RegistrationUserModelDto';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { AuthenticationService } from 'src/app/shared/services/common/authentication.service';
import { LandingDashboardService } from 'src/app/shared/services/landing-auction-service';
import { SupplierAuctionService } from 'src/app/shared/services/supplier-auction-service';
import { UserService } from 'src/app/shared/services/user.service';
import { AuctionwareConstants } from 'src/app/shared/util/AuctionwareConstants';
import { AuctionwareUtil } from 'src/app/shared/util/AuctionwareUtil';
import { Pattern } from 'src/app/shared/util/Patterns';
import { PasswordStrengthValidator } from 'src/app/shared/validators/password-strength.validators';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.sass']
})
export class RegistrationComponent implements OnInit, AfterViewChecked {

  @ViewChild('appLoader', { static: false })
  appLoader?: LoaderComponent;
  show = false;
  checked = false;
  currentStep: number = 1;
  totalStep: number = 1;
  _validationErrorPresent$ = new BehaviorSubject<boolean>(false);
  _isSaveButtonDisable$ = new BehaviorSubject<boolean>(false);
  _isPasswordMatch$ = new BehaviorSubject<boolean>(false);
  otpId?: string;
  _showSuccessMsg$ = new BehaviorSubject<boolean>(false);
  _successMsg$ = new BehaviorSubject<string>("");

  @ViewChild('termsConditions') termsConditions?: ElementRef;
  @ViewChild('confidentialAgreement') confidentialAgreement?: ElementRef;

  isPasswordVisible: boolean = false;
  isPasswordVisibleRepeat: boolean = false;

  auctionEntityDto?: AuctionEntityDto;
  clientBrandDetails?: ClientBrandDetailsDto;

  formGroup = this.formBuilder.group({
    email: new FormControl('', [Validators.required, Validators.pattern(Pattern.email)]),
    mobileNo: new FormControl('', [Validators.required, Validators.pattern(Pattern.mobile)]),
    password: new FormControl('', [Validators.required, Validators.minLength(9), PasswordStrengthValidator]),
    repeatPassword: new FormControl('', [Validators.required]),
    fullName: new FormControl('', Validators.required),
    officeName: new FormControl(''),
    officeNumber: new FormControl('', [Validators.pattern(Pattern.mobile)]),
    addressLine1: new FormControl('', Validators.required),
    addressLine2: new FormControl(''),
    city: new FormControl('', Validators.required),
    state: new FormControl('', Validators.required),
    zipCode: new FormControl('', [Validators.required, Validators.maxLength(5), Validators.minLength(5)]),
    agreeToTerms: [false, Validators.required],
    agreeToConfidentialityTerms: [false, Validators.required],
    signatureName: new FormControl('', Validators.required),
    otp: new FormControl('', Validators.required)
  });

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private userService: UserService,
    public authService: AuthenticationService,
    public supplierService: SupplierAuctionService,
    private landingDashboardService: LandingDashboardService,
  ) { }

  ngOnInit() {
    this.authService.errorMsg = "";
    this.formGroup.reset();

    this.supplierService.getAuctionEntityDto$.subscribe((data) => {
      if (data) {
        this.auctionEntityDto = data;
        this.totalStep = data.confidentialAgreement && data.confidentialAgreement != '' ? 5 : 4;
      }
    })

    this.landingDashboardService.getClientBrandDetails$.subscribe(data => {
      this.clientBrandDetails = data;
    })
  }

  ngAfterViewChecked(): void {
    if (this.termsConditions && this.termsConditions?.nativeElement) {
      this.termsConditions!.nativeElement.innerHTML = this.auctionEntityDto?.termsConditions!;
    }

    if (this.confidentialAgreement && this.confidentialAgreement?.nativeElement) {
      this.confidentialAgreement!.nativeElement.innerHTML = this.auctionEntityDto?.confidentialAgreement!;
    }
  }

  get fc(): any { return this.formGroup.controls; }

  togglePassword() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  togglePasswordRepeat() {
    this.isPasswordVisibleRepeat = !this.isPasswordVisibleRepeat;
  }

  next() {

    if (!this.formValidationFailed(this.currentStep)) {
      this._validationErrorPresent$.next(false);
      this.authService.errorMsg = "";

      if (this.currentStep != this.totalStep) {
        this.currentStep += 1;
      }
      if (this.currentStep == this.totalStep) {
        this.generateOTP();
      }
    } else {
      this._validationErrorPresent$.next(true);
    }
  }

  onChange() {
    this.authService.errorMsg = null!;
    if (this.formGroup.controls['password'].value == this.formGroup.controls['repeatPassword'].value) {
      this._isPasswordMatch$.next(true);
    } else {
      this._isPasswordMatch$.next(false);
    }
  }

  formValidationFailed(currentStep: number) {
    if (currentStep == 1) {
      if (!this.formGroup.controls['email'].valid) {
        //this.authService.errorMsg = "Please Fill All Input Field"
        return true;
      }

      if (!this.formGroup.controls['mobileNo'].valid) {
        //this.authService.errorMsg = "Please Fill All Input Field"
        return true;
      }

      if (!this.formGroup.controls['password'].valid) {
        //this.authService.errorMsg = "Please Fill All Input Field"
        return true;
      }

      if (!this.formGroup.controls['repeatPassword'].valid) {
        //this.authService.errorMsg = "Please Fill All Input Field"
        return true;
      }

      if (this.formGroup.controls['password'].value != this.formGroup.controls['repeatPassword'].value) {
        this.authService.errorMsg = "Please Enter Same Password"
        return true;
      }
    }
    if (currentStep == 2) {

      if (!this.formGroup.controls['addressLine1'].valid) {
        //this.authService.errorMsg = "Please Fill All Input Field"
        return true;
      }

      if (!this.formGroup.controls['addressLine2'].valid) {
        //this.authService.errorMsg = "Please Fill All Input Field"
        return true;
      }

      if (!this.formGroup.controls['city'].valid) {
        //this.authService.errorMsg = "Please Fill All Input Field"
        return true;
      }

      if (!this.formGroup.controls['state'].valid) {
        //this.authService.errorMsg = "Please Fill All Input Field"
        return true;
      }

      if (!this.formGroup.controls['zipCode'].valid) {
        //this.authService.errorMsg = "Please Fill All Input Field"
        return true;
      }
    }
    if (currentStep == 3) {

      if (this.formGroup.controls['agreeToTerms'].value == null || this.formGroup.controls['agreeToTerms'].value == false) {
        //this.authService.errorMsg = "Please Accept Terms And Condition"
        return true;
      }

    }

    if (this.totalStep == 5) {
      if (currentStep == 4) {
        if (this.formGroup.controls['agreeToConfidentialityTerms'].value == null || this.formGroup.controls['agreeToConfidentialityTerms'].value == false) {
          //this.authService.errorMsg = "Please Accept  Confidentiality And Condition"
          return true;
        }
  
        if (!this.formGroup.controls['signatureName'].valid) {
          //this.authService.errorMsg = "Please Accept  Confidentiality And Condition"
          return true;
        }
      }
    } else {
      let fullName = this.formGroup.controls['fullName'].value;
      this.formGroup.controls['agreeToConfidentialityTerms'].patchValue(true);
      this.formGroup.controls['signatureName'].patchValue(fullName);
      this.formGroup.updateValueAndValidity();
    }
    
    if (currentStep == this.totalStep) {
      if (!this.formGroup.controls['otp'].valid) {
        return true
      }
    }

    return false;
  }

  openModal(content: any) {
    this.closeModal()
    this.authService.errorMsg = "";
    this.formGroup.reset();
    this.modalService.open(content, {
      size: 'md'
    });
  }

  handleValidSubmit() {

    if (this.formValidationFailed(this.currentStep)) {
      this._validationErrorPresent$.next(true);
      return;
    }

    if (this.formGroup.dirty) {
      if (this.formGroup.invalid) {
        return;
      }
      this._isSaveButtonDisable$.next(true);
      this.appLoader?.openLoaderIcon(AuctionwareConstants.USER_REGISTRATION_PROGRESS, "User Registration in Progress...");
      let emailId = this.formGroup.controls['email'].value!;
      let password = this.formGroup.controls['password'].value!;


      let registrationUserModal = new RegistrationUserModelDto();
      registrationUserModal.otpId = this.otpId;
      registrationUserModal.userEnteredOtp = this.formGroup.controls['otp'].value!;
      registrationUserModal.mobileNo = this.formGroup.controls['mobileNo'].value!;
      registrationUserModal.name = this.formGroup.controls['fullName'].value!;
      registrationUserModal.officeName = this.formGroup.controls['officeName'].value!;
      registrationUserModal.officeNo = this.formGroup.controls['officeNumber'].value!;
      registrationUserModal.addressLine1 = this.formGroup.controls['addressLine1'].value!;
      registrationUserModal.addressLine2 = this.formGroup.controls['addressLine2'].value!;
      registrationUserModal.city = this.formGroup.controls['city'].value!;
      registrationUserModal.state = this.formGroup.controls['state'].value!;
      registrationUserModal.zipCode = this.formGroup.controls['zipCode'].value!;
      registrationUserModal.signatureName = this.formGroup.controls['signatureName'].value!;
      this.userService.registerUsers(registrationUserModal, emailId, password).subscribe({
        next: (apiResponseDto: ServerAPIResponseDto) => {
          if (apiResponseDto && apiResponseDto.code == AuctionwareConstants.SUCCESS_CODE) {
            let data = apiResponseDto.data as AuthenticationResponseDto;
            this.userService.setUser(data.sessionInfoDto!);
            localStorage.setItem('AUC_SESSION_ID', data.sessionID!);
            this.authService.handleServerResponse(apiResponseDto.data, this._isSaveButtonDisable$);
          } else {
            this._isSaveButtonDisable$.next(false);
            this.appLoader?.closeLoaderIcon(AuctionwareConstants.USER_REGISTRATION_PROGRESS);
            if (apiResponseDto.code == "USER-103") {
              this.authService.errorMsg = "Email Id Already Registered";
            } else if (apiResponseDto.code == "OTP-102") {
              this.authService.errorMsg = "No OTP found for a given ID";
            }
            else if (apiResponseDto.code == "OTP-103") {
              this.authService.errorMsg = "OTP Doesn't Match";
            }
            else if (apiResponseDto.code == "OTP-104") {
              this.authService.errorMsg = "OTP Expired";
            }
            else {
              this.authService.errorMsg = "Error while Registration of User"
            }
          }
        },
        error: (err) => {
          this._isSaveButtonDisable$.next(false);
          this.authService.errorMsg = "Error while Registration of User"
        }
      })
    }
  }

  closeModal() {
    this.modalService.dismissAll();
    this._validationErrorPresent$.next(false);
  }

  toggleShowMore() {
    this.show = !this.show
  }

  changeTerms() {
    let value = this.formGroup.controls['agreeToTerms'].value;
    this.formGroup.controls['agreeToTerms'].patchValue(!value);
  }

  changeConfidentialityTerms() {
    let value = this.formGroup.controls['agreeToConfidentialityTerms'].value;
    this.formGroup.controls['agreeToConfidentialityTerms'].patchValue(!value);
  }

  generateOTP() {
    let email = this.formGroup.controls['email'].value as string;
    let name = this.formGroup.controls['fullName'].value as string;
    let mobileNo = this.formGroup.controls['mobileNo'].value as string;
    this.userService.generateOTP(email, name, mobileNo, 'REGISTRATION').subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == AuctionwareConstants.SUCCESS_CODE) {
          this.otpId = apiResponseDto.data as string;
        } else {
          this.authService.errorMsg = "Error While OTP Generate";
        }
      },
      error: (err) => {
        this.authService.errorMsg = "Error While OTP Generate";
      }
    })
  }

  resendOTP() {
    let email = this.formGroup.controls['email'].value as string;
    let name = this.formGroup.controls['fullName'].value as string;
    let mobileNo = this.formGroup.controls['mobileNo'].value as string;
    this.userService.resendOTP(email, name, mobileNo, this.otpId as string).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == AuctionwareConstants.SUCCESS_CODE) {
          this.otpId = apiResponseDto.data as string;
          this._showSuccessMsg$.next(true);
          this._successMsg$.next("OTP resent")
          setTimeout(() => {
            this._showSuccessMsg$.next(false);
          this._successMsg$.next("")
          }, 2000);
        } else {
          this.authService.errorMsg = "Error While sending OTP";
        }
      },
      error: (err) => {
        this.authService.errorMsg = "Error While sending OTP";
      }
    })
  }

  skipOTPVerification() {
    this.formGroup.get('otp')?.reset();
    this.formGroup.get('otp')?.clearValidators();
    this.formGroup.get('otp')?.updateValueAndValidity();

    this.handleValidSubmit();
  }

  checkValidValue() {
    if(this.formGroup.controls['zipCode'].value){
      let value = this.formGroup.controls['zipCode'].value as string;
      this.formGroup.controls['zipCode'].setValue(AuctionwareUtil.onlyNumbers(value));
    }
  }

  openTermsOfUseModal() {
    let termsUseModalRef = this.modalService.open(TermsOfUseForAuctionComponent, {
      size: 'md', centered: true
    });
    
    termsUseModalRef.componentInstance.isTermsPage = true;
    termsUseModalRef.componentInstance.content = this.clientBrandDetails?.termsOfUse;
    termsUseModalRef.componentInstance.headerName = this.clientBrandDetails?.footerName;
    termsUseModalRef.componentInstance.sellerContent = this.clientBrandDetails?.sellerBody;
    termsUseModalRef.componentInstance.bidderContent = this.clientBrandDetails?.bidderBody;
  }

  @HostListener('document:keyup', ['$event'])
  public onKeyUp(eventData: KeyboardEvent) {
    if (eventData?.key == 'Enter') {
      if(this.currentStep == 1 || this.currentStep == 2 || this.currentStep == 3 || this.currentStep == 4 ){
        this.next();
      }else if(this.currentStep == 5){
        this.handleValidSubmit();
      }
    }
  }

}
