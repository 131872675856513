import { Component, ElementRef, Input, OnInit, SecurityContext, ViewChild, ViewChildren } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { AuctionEntityDto } from '../../models/AuctionEntityDto';
import { FileInfoDto } from '../../models/FileInfoDto';
import { AuctionwareUtil } from '../../util/AuctionwareUtil';

@Component({
  selector: 'app-gallery-images',
  templateUrl: './gallery-images.component.html',
  styleUrls: ['./gallery-images.component.sass']
})
export class GalleryImagesComponent implements OnInit {
  @Input() auctionEntity$?: Observable<AuctionEntityDto|null>;

  activeImage: number = 0;

  propertyImages: Array<FileInfoDto> = [];

  @ViewChildren('videoCarousel') videoCarousels?: Array<ElementRef<HTMLVideoElement>>;
  @ViewChildren('iframeCarousel') iframeCarousels?: Array<ElementRef<HTMLIFrameElement>>;

  constructor(
    private activeModal: NgbActiveModal,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    this.auctionEntity$?.subscribe((data) => {
      if (data && data.propertyImages) {
        this.propertyImages = data.propertyImages;
      }
    })
  }

  getImageUrl(item: any) {
    if (this.isValidUrl(item.fileId)) {
      return AuctionwareUtil.getVideoThumbnailFromUrl(item.fileId);
    }
    return this.sanitizer.sanitize(SecurityContext.URL, `/downloadLandingBlob?fileId=${item?.fileId}`);
  }

  prevImage() { 
    if (this.activeImage != 0) {
      this.activeImage--;
      this.stopCarouselVideo();
    }
  }

  nextImage() { 
    if (this.activeImage < (this.propertyImages.length - 1)) {
      this.activeImage++;
      this.stopCarouselVideo();
    }
  }

  closeModal(): void {
    this.activeModal.close();
  }

  changeImage(i: number) {
    this.activeImage = i;
    this.stopCarouselVideo();
  }

  isValidUrl = (urlString: string) => {
    try {
      return Boolean(new URL(urlString));
    }
    catch (e) {
      return false;
    }
  }

  stopCarouselVideo() {
    if (this.videoCarousels && this.videoCarousels.length > 0) {
      this.videoCarousels.forEach((item) => {
        item?.nativeElement.pause();
      })
    }

    if (this.iframeCarousels && this.iframeCarousels.length > 0) {
      this.iframeCarousels.forEach((item) => {
        let element = item.nativeElement;
        if (element) {
          var iframeSrc = element.src;
          element.src = iframeSrc;
        }
      })
    }
  }
}
