<footer id="e-footer">
	<img alt="" src="assets/blob/property/01.jpg" />
	<section id="e-footerdisclaimer">
		<ul>
			<li><a (click)="openModal(PrivacyPolicy, 'privacy')">Privacy Policy</a></li>
			<!-- <li><a (click)="openModal(BidderUserTerms)">Bidder User Terms</a></li>
			<li><a (click)="openModal(SellerUserTerms)">Seller User Terms</a></li> -->
			<li><a (click)="openModal(TermsofUseForAuctionWare, 'terms')">Terms of Use</a></li>
		</ul>
		<p class="text-white">&copy; {{getCurrentYear()}} <a  target="_blank" href="{{clientBrandDetails?.footerUrl}}">{{clientBrandDetails?.footerName}}</a></p>
    <!-- <p>&copy; {{getCurrentYear()}} <a target="_blank">Auctionware</a></p> -->
	</section>
</footer>
<ng-template #PrivacyPolicy>
	<app-privacy-policy [content]="clientBrandDetails?.privacy" [headerName]="clientBrandDetails?.footerName"></app-privacy-policy>
</ng-template>
<ng-template #BidderUserTerms>
	<app-bidder-user-terms ></app-bidder-user-terms>
</ng-template>
<ng-template #SellerUserTerms>
	<app-seller-user-terms></app-seller-user-terms>
</ng-template>
<ng-template #TermsofUseForAuctionWare>
	<app-terms-of-use-for-auction [content]="clientBrandDetails?.termsOfUse" [headerName]="clientBrandDetails?.footerName" [sellerContent]="clientBrandDetails?.sellerBody" [bidderContent]="clientBrandDetails?.bidderBody"></app-terms-of-use-for-auction>
</ng-template>
