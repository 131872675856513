import { DatePipe } from '@angular/common';
import { OnDestroy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { AuctionBidsDetailsUIDto } from 'src/app/shared/models/AuctionBidsDetailsUIDto';
import { AuctionEntityDto } from 'src/app/shared/models/AuctionEntityDto';
import { SupplierAuctionService } from 'src/app/shared/services/supplier-auction-service';
import { UserService } from 'src/app/shared/services/user.service';
import { AuctionwareUtil } from 'src/app/shared/util/AuctionwareUtil';

@Component({
  selector: 'app-landing-dashboard-sidebar',
  templateUrl: './landing-dashboard-sidebar.component.html',
  styleUrls: ['./landing-dashboard-sidebar.component.sass']
})
export class LandingDashboardSidebarComponent implements OnInit, OnDestroy {

  @Input() auctionEntityDto$?: Observable<AuctionEntityDto|null>;

  auctionEntityDto? : AuctionEntityDto;

  @ViewChild("BidderApproved")
  BidderApproved!:NgbModal;

  @ViewChild("BidderRejected")
  BidderRejected!:NgbModal;

  isBid: string | undefined;
  isLoggedIn = false

  shortDateFrom = new Date();
  shortDateTo = new Date('tomorrow');

  countdownTimerDays = 0;
  countdownTimerHours = 0;
  countdownTimerMinutes = 0;
  countdownTimerSeconds = 0;
  counterFunction: any;

  _auctionBidDetailsSubscription?: Subscription;

  constructor(
    private datePipe: DatePipe,
    private userService: UserService,
    private supplierAuctionService: SupplierAuctionService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.auctionEntityDto$?.subscribe(data => {
      if(data){
        this.auctionEntityDto = data;
        this.countdown();
      }
    })

    this._auctionBidDetailsSubscription = this.supplierAuctionService.getAuctionBidsDetails$?.subscribe((data) => {
      if (data && data.fullUpdate) {

        this.showRegistrationChangeMsg(data);
        this.userService.updateRegistrationStatus(data.registrationStatus!);
      }
    })
  }

  ngOnDestroy(): void {
    if (this._auctionBidDetailsSubscription) {
      this._auctionBidDetailsSubscription.unsubscribe();
    }
  }

  closeModal() {
    this.modalService.dismissAll()
  }

  showRegistrationChangeMsg(data: AuctionBidsDetailsUIDto){
    let userEntityDto = this.userService.getUserEntity();
    if(userEntityDto && userEntityDto.registrationStatus && data.registrationStatus && (data.registrationStatus != userEntityDto.registrationStatus)){
      //console.log("registrationStatus: "+userEntityDto.registrationStatus);
      //console.log("newRegistrationStatus: "+data.registrationStatus);
      if(data.registrationStatus == "APPROVED_BIDDERS"){
        this.modalService.open(this.BidderApproved, {
          size: 'md'
        });

      }else if(data.registrationStatus == "REJECTED_BIDDERS"){
        this.modalService.open(this.BidderRejected, {
          size: 'md'
        });

      }
    }
  }

  countdown(){
    if(this.counterFunction){
      //console.log("Clearing counter function :" + this.counterFunction);
      clearInterval(this.counterFunction);
    }

    this.counterFunction = setInterval(() => {
      let date = this.auctionEntityDto?.status == 'LIVE' ? this.auctionEntityDto?.endDate : this.auctionEntityDto?.startDate;
      let time = this.auctionEntityDto?.status == 'LIVE' ? this.auctionEntityDto?.endTime : this.auctionEntityDto?.startTime;

      this.countdownTimerDays = this.getCountdownTimerDays(date, time, this.auctionEntityDto?.timeZone);
      this.countdownTimerHours = this.getCountdownTimerHours(date, time, this.auctionEntityDto?.timeZone);
      this.countdownTimerMinutes = this.getCountdownTimerMinutes(date, time, this.auctionEntityDto?.timeZone);
      this.countdownTimerSeconds = this.getCountdownTimerSeconds(date, time, this.auctionEntityDto?.timeZone);

    }, 1000);

    console.log("Creating a new countdown function " + this.counterFunction);
  }

  getCountdownTimerDays(date?: string, time?: string, timeZone?: string) {
    if (date != undefined && time != undefined) {
      let convertedDate = date + " " + time;
      return AuctionwareUtil.getCountdownTimerDays(convertedDate.toString(), timeZone!);
    } return 0
  }

  getCountdownTimerHours(date?: string, time?: string, timeZone?: string) {
    if (date != undefined && time != undefined) {
      let convertedDate = date + " " + time;
      return AuctionwareUtil.getCountdownTimerHours(convertedDate, timeZone!);
    } return 0
  }

  getCountdownTimerMinutes(date?: string, time?: string, timeZone?: string) {
    if (date != undefined && time != undefined) {
      let convertedDate = date + " " + time;
      return AuctionwareUtil.getCountdownTimerMinutes(convertedDate, timeZone!);
    } return 0
  }

  getCountdownTimerSeconds(date?: string, time?: string, timeZone?: string) {
    if (date != undefined && time != undefined) {
      let convertedDate = date + " " + time;
      return AuctionwareUtil.getCountdownTimerSeconds(convertedDate, timeZone!);
    } return 0
  }

  getDisplayDay(date?: string) {
    if (date != null) {
      return AuctionwareUtil.getDisplayDay(date, this.datePipe);
    }
    return '';
  }

  getDisplayDate(date?: string) {
    if (date != null) {
      return AuctionwareUtil.getDateInMMMdd(date, this.datePipe);
    }
    return '';
  }

  getDisplayYear(date?: string) {
    if (date != null) {
      return AuctionwareUtil.getDisplayYear(date);
    }
    return '';
  }

  getDisplayTime(time?: string) {
    if (time != null) {
      return AuctionwareUtil.getDisplayTimeInHHmm(time, this.datePipe);
    }
    return '';
  }

  getDisplayMeridiem(time?: string) {
    if (time != null) {
      return AuctionwareUtil.getDisplayMeridiem(time, this.datePipe);
    }
    return '';
  }

  formatTimezone(shortTimezone?: string) {
    return AuctionwareUtil.formatShortTimezone(shortTimezone);
  }

}
