import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { UserService } from '../user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuctionwareConstants } from '../../util/AuctionwareConstants';
import { SessionInfoDto } from '../../models/SessionInfoDto';
import { LoaderComponent } from '../../components/loader/loader.component';
import { UserEntityDto } from '../../models/UserEntityDto';
import { ErrorService } from '../error.service';
import { navigationService } from './navigationService';
import { RoutingService } from './routing-service';
import { AuctionwareUtil } from '../../util/AuctionwareUtil';
import { NotificationService } from '../notification-service';
import { UserPrivilege } from '../../models/UserPrivilege';
import { CookieService } from '../CookieService';
import { FirestoreListenerService } from '../firestore-listener.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  loginModal = new BehaviorSubject(false);
  errorMsg: string = "";

  constructor(
    private httpClient: HttpClient,
    private userService: UserService,
    private modalService: NgbModal,
    private router: Router,
    private errorService: ErrorService,
    private navigationService: navigationService,
    private routingService: RoutingService,
    private notificationService: NotificationService,
    private cookiesService : CookieService,
    private firestoreService: FirestoreListenerService
  ) { }

  isUserLoggedIn() {
    let user = localStorage.getItem('username')
    //console.log(!(user === null))
    return !(user === null)
  }

  logout() {
    localStorage.clear();
    this.modalService.dismissAll();
    this.errorService.setResetDialogFlag();
    let email = this.userService.getUserEntity().emailId;
    this.userService.clear();
    this.router.navigate(['/']);
    this.userService.logout(email).subscribe(apiResponseDto => {
      if (apiResponseDto) {
        if (apiResponseDto.code == AuctionwareConstants.SUCCESS_CODE) {
          //console.log("Logout called");
          this.notificationService.notifyDataLoading(AuctionwareUtil.getNotificationEvent(false));
          // window.location.reload();

        }
      }
    });
  }

  handleServerResponse(data: any, _isSaveButtonEnable$: BehaviorSubject<boolean>) {

      let sessionInfo = data.sessionInfoDto as SessionInfoDto;
      let currentUser: UserEntityDto = sessionInfo.userEntityDto!;
      _isSaveButtonEnable$.next(false);
      this.navigationService.emitChange(currentUser);
      this.userService.setUser(sessionInfo);
      this.routingService.doRouting(currentUser);
      this.modalService.dismissAll();
      // Initialize Firestore Listeners
      if (currentUser?.userPrivilege == UserPrivilege.BIDDER) {
        this.firestoreService.auctionBidsDetailsListener();
      }

  }

  authenticate(username?: string, password?: string): Observable<any> {
    //const headers = new HttpHeaders({ Authorization: 'Basic ' + window.btoa(encodeURIComponent(username!) + ':' + password) });
    const headers = new HttpHeaders({ Authorization: 'Basic ' + window.btoa(username + ':' + password) });
    headers.append('Content-Type', 'application/x-www-form-urlencoded');


    return this.httpClient.post<any>('authenticate', null, { headers, observe: 'response' }).pipe(
      map(
        apiResponseDto => {
          if (apiResponseDto.body.code == AuctionwareConstants.SUCCESS_CODE) {
            // session infor only when authetication is successfull.
            let sessionInfo = <SessionInfoDto>apiResponseDto.body.data.sessionInfoDto;
            this.userService.setUser(sessionInfo);
            localStorage.setItem('AUC_SESSION_ID', apiResponseDto.body.data.sessionID);
          }
          return apiResponseDto.body;
        }
      )
    )
  }

  doAuthentication(userName: string, password: string, appLoader: LoaderComponent, _isSaveButtonEnable$: BehaviorSubject<boolean>) {
    this.authenticate(userName, password).subscribe({
      next: (apiResponseDto) => {
        if(apiResponseDto && apiResponseDto.code == AuctionwareConstants.SUCCESS_CODE){
          this.handleServerResponse(apiResponseDto.data, _isSaveButtonEnable$);
        }else{
          localStorage.clear();
          this.notificationService.notifyDataLoading(AuctionwareUtil.getNotificationEvent(false));
          appLoader.closeLoaderForcefully();
          _isSaveButtonEnable$.next(false)
          this.userService.clear();
          if(apiResponseDto.code == "USER-107"){
            this.errorMsg = "Email Id Not Registered";
          }else{
            this.errorMsg = "Wrong Credentials";
          }

        }
      },
      error: (error) => {
        _isSaveButtonEnable$.next(false)
        appLoader.closeLoaderForcefully();
        this.errorMsg = "Wrong Credentials";
        localStorage.clear();
      }
  });
  }

}
