import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ErrorModel } from '../models/ErrorModel';
import { SubDomainService } from './sub-domain.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  constructor(
    private http: HttpClient,
    private subDomainService: SubDomainService
  ) {}

  public sendErrorToServer(operation: string, error: any): void {

    // let errorObject = new ErrorModel();
    // errorObject.errorMessage = (operation + "failed:" + error.message);
    // errorObject.user = localStorage.getItem('userId');
    // errorObject.hostname = this.subDomainService.getHostName();
    // errorObject.subdomain = this.subDomainService.getSubDomain();
    // errorObject.ErrorDate = new Date().toDateString();

    // this.doSendErrorToServer(errorObject);

    // open Model if error needs to be motified to user
  }

  doSendErrorToServer(errorObject: ErrorModel): void {

    this.http.post<ErrorModel>("saveClientError", errorObject).subscribe(data => {
      console.log("Got data" +  data);
    })

  }

  private handleErrorLocally<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {


      console.error("Error in Handle error service -- > " + error); // log to console instead
      console.log(`Error in Handle error service -- > ${operation} failed: ${error.message}`);

      // TODO: better job of transforming error for user consumption
      //this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
