<div class="e-popup ee-width-s" id="e-popuplogin">
  <div class="e-popupblock ea-popupremove"></div>
  <div class="e-popuppage">
    <div class="e-popupalert ee-error" *ngIf="this.authService.errorMsg">{{authService.errorMsg}}</div>
    <header class="e-popupheader">
      <h1>Login</h1><a class="e-popupclose ea-popupremove" (click)="closeModal()">Close</a>
    </header>
    <div class="e-popupmain">
      <div class="e-maintext e-maintext-m">
        <p>Not registered? Please <a class="ea-popupremove ea-popupload" (click)="openModal(registrationModal)">register
            to bid</a>.</p>
        <form autocomplete="off" [formGroup]="formGroup" (keyup.enter)="handleValidSubmit()">
          <div class="e-field ea-valid-required ea-valid-email" [ngClass]="{'ee-error': (_validationErrorPresent$ |async) && formGroup.controls['emailId'].invalid}"><label>Email</label>
            <div class="e-fieldtext"><input id="ea-valid-email" name="" type="text" formControlName="emailId" /></div>
            <p class="e-fielderror e-fielderror-required" *ngIf="(_validationErrorPresent$ |async) && this.formGroup.controls['emailId'].invalid  && (!this.formGroup.controls['emailId'].value)">Required</p>
            <p class="e-fielderror e-fielderror-required" *ngIf="(_validationErrorPresent$ |async) && this.formGroup.controls['emailId'].invalid  && (this.formGroup.controls['emailId'].value)">Invalid email address</p>
          </div>


          <div class="e-field ea-valid-required ea-valid-password" [ngClass]="{'ee-error': (_validationErrorPresent$ |async) && formGroup.controls['password'].invalid}"><label>Password</label>
            <div class="e-fieldtext">
              <span class="ee-suffix ee-size-s" [ngClass]="isPasswordVisible ? 'ei-visible' : 'ei-visible-not'" (click)="togglePassword()"></span>
              <input id="ea-valid-password" name="" formControlName="password" [type]="isPasswordVisible ? 'text' : 'password'" />
              <p class="e-fielderror e-fielderror-required" *ngIf="(_validationErrorPresent$ |async) &&  this.formGroup.controls['password'].invalid &&  (!this.formGroup.controls['password'].value)">Required</p>
              <p class="e-fielderror e-fielderror-required" *ngIf="(_validationErrorPresent$ |async) &&  this.formGroup.controls['password'].invalid &&  (this.formGroup.controls['password'].value)">Password must be more than 8 characters, containing at least one capital letter, one lowercase letter, one number and one special character.</p>
            </div>
          </div>
          <div class="e-formsubmit">
            <a class="e-buttontext ee-size-m ee-vis-s ea-popupremove" (click)="closeModal()">Cancel</a>
            <a class="e-buttontext ee-size-m ee-vis-l ea-submit" [ngClass]="{'disabled':_isSaveButtonDisable$ | async}"
            (click)="handleValidSubmit()">Login</a>
          </div>
          <p><a class="ea-popupremove ea-popupload" (click)="openModal(forgotPasswordModal)">Forgotten Password</a></p>
        </form>
      </div>
    </div>
  </div>
</div>

<ng-template #forgotPasswordModal>
  <app-forgot-password></app-forgot-password>
</ng-template>

<ng-template #registrationModal>
  <app-registration></app-registration>
</ng-template>

<app-loader #appLoader></app-loader>
