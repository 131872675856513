import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { AngularEditorComponent, AngularEditorConfig } from 'src/assets/plugins/angular-editor';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { BehaviorSubject, Observable } from 'rxjs';
import { HtmlViewComponent } from 'src/app/shared/components/html-view/html-view.component';
import { AuctionEntityDto } from 'src/app/shared/models/AuctionEntityDto';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { AdminAuctionService } from 'src/app/shared/services/admin-auction.service';
import { AuctionwareConstants } from 'src/app/shared/util/AuctionwareConstants';
import { AuctionwareUtil } from 'src/app/shared/util/AuctionwareUtil';

@Component({
  selector: 'app-admin-property-setup-modal',
  templateUrl: './admin-property-setup-modal.component.html',
  styleUrls: ['./admin-property-setup-modal.component.sass']
})
export class AdminPropertySetupModalComponent implements OnInit, AfterViewInit {

  formGroup: FormGroup;
  _validationErrorPresent$ = new BehaviorSubject<boolean>(false);

  auctionEntityDto?: AuctionEntityDto | null;
  auctionEntityDto$ = new Observable<AuctionEntityDto | null>();

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  // _isSaveButtonEnable$ = new BehaviorSubject<boolean>(true);

  isShowConfidentialAgreement: boolean = true;
  isLoading: boolean = false;
  latitude?: number;
  longitude?: number;
  currentModal?: string;
  stateAbbreviate?:string;
  public screenWidth: any;

  @ViewChild("placesRef") placesRef?: GooglePlaceDirective;
  @ViewChild("termsEditor") termsEditor?: AngularEditorComponent;
  @ViewChild("detailsEditor") detailsEditor?: AngularEditorComponent;
  @ViewChild("locationDescEditor") locationDescEditor?: AngularEditorComponent;
  @ViewChild("propertyHighlightEditor") propertyHighlightEditor?: ElementRef;
  @ViewChild("confidentialAgreement") confidentialAgreement?: AngularEditorComponent;

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '2',
    sanitize: false,
    toolbarHiddenButtons: [
      [
        'strikeThrough',
        'subscript',
        'superscript',
        'heading',
        'fontName'
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'toggleEditorMode'
      ]
    ],
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'Page Divider',
        class: 'break',
        tag: 'div',
      },
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'hr',
      },
    ],
  };

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.screenWidth = window.innerWidth;
  }

  constructor(
    public modalService: NgbModal,
    private fb: FormBuilder,
    private adminService: AdminAuctionService,
  ) {
    this.formGroup = this.fb.group({
      locationTitle: ['', Validators.required],
      locationDescription: ['', Validators.required],
      addressLine1: ['', [Validators.required, this.validLocation()]],
      addressLine2: [''],
      city: ['', Validators.required],
      zipCode: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(5)]],
      state: ['', Validators.required],
      showAddress: [true],
      propertyTitle: ['', Validators.required],
      propertyHighlightsTitle: ['', Validators.required],
      propertyHighlights: ['', Validators.required],
      propertyDetailsTitle: ['', Validators.required],
      propertyDetails: ['', Validators.required],
      termsConditions: ['', Validators.required],
      showConfidentialAgreement: [true],
      confidentialAgreement: ['']
    })
  }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    this.auctionEntityDto$ = this.adminService.getAuctionEntityDto$;

    this.auctionEntityDto$?.subscribe((auctionEntityDto) => {
      this.auctionEntityDto = auctionEntityDto;

      // Populate data in Form
      if (auctionEntityDto != null) {
        this.latitude = auctionEntityDto.latitude;
        this.longitude = auctionEntityDto.longitude;
        this.stateAbbreviate = auctionEntityDto.stateAbbreviate;
        if (auctionEntityDto.confidentialAgreement && auctionEntityDto.confidentialAgreement != '') {
          this.isShowConfidentialAgreement = true;
        } else {
          this.isShowConfidentialAgreement = false;
        }
        this.formGroup.controls['showConfidentialAgreement'].setValue(this.isShowConfidentialAgreement);
        this.formGroup.patchValue(auctionEntityDto!);
        this.formGroup.updateValueAndValidity();
      }
    })
  }

  ngAfterViewInit(): void {
    this.clearFormatOnPaste('locationDescEditor')
    this.clearFormatOnPaste('detailsEditor')
    this.clearFormatOnPaste('termsEditor')
    this.clearFormatOnPaste('confidentialAgreement')
  }

  get fc(): any { return this.formGroup.controls; }

  validLocation(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let isValidLocation = this.latitude != undefined;
      return control.value && !isValidLocation ? { address: {value: control.value} } : null;
    }
  }



  formValidationFailed() {
    this._validationErrorPresent$.next(false);
    if (this.formGroup.invalid) {
      this._validationErrorPresent$.next(true);
      return true;
    }
    return false;
  }

  saveAuction(saveForcefully: boolean, saveProgress: boolean) {
    let propHighlightsFocused = this.propertyHighlightEditor?.nativeElement == document.activeElement;

    if (!saveForcefully) {
      if (this.locationDescEditor?.focused || this.termsEditor?.focused || this.detailsEditor?.focused
        || this.confidentialAgreement?.focused || propHighlightsFocused) {
        return;
      }
    }


    if (this.formValidationFailed() && !saveProgress) {
      return;
    }

    this.isLoading = true;
    // this._isSaveButtonEnable$.next(false);
    let formValue = this.formGroup.value;

    this.mergeAuctionEntityDto(formValue);

    this.adminService.saveAuctionDetails(this.auctionEntityDto!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == AuctionwareConstants.SUCCESS_CODE) {
          this._showSuccessToast$.next(true);
          this.isLoading = false;
          // this._isSaveButtonEnable$.next(true);
          setTimeout(() => {
            this._showSuccessToast$.next(false);
            if (!saveProgress) this.modalClose();
          }, 2000)
        } else {
          this._showErrorToast$.next(true);
          this.isLoading = false;
          // this._isSaveButtonEnable$.next(true);
          setTimeout(() => {
            this._showErrorToast$.next(false);
          }, 2000)
        }
      },
      error: (error) => {
        console.error(error);
        this.isLoading = false;
        // this._isSaveButtonEnable$.next(true);
        setTimeout(() => {
          this._showErrorToast$.next(false);
        }, 2000)
      }
    })
  }

  changeAddressMode(){
    this.formGroup.controls['showAddress'].setValue(!this.formGroup.controls['showAddress'].value);
  }

  changeConfidentialAgreementMode() {
    this.isShowConfidentialAgreement = !this.isShowConfidentialAgreement;
    this.formGroup.controls['showConfidentialAgreement'].setValue(this.isShowConfidentialAgreement);
    this.formGroup.controls['confidentialAgreement'].reset();
    this.formGroup.updateValueAndValidity();
  }

  mergeAuctionEntityDto(formValue: any) {
    this.auctionEntityDto!.locationTitle = formValue.locationTitle;
    this.auctionEntityDto!.locationDescription = formValue.locationDescription;
    this.auctionEntityDto!.addressLine1 = formValue.addressLine1 ? AuctionwareUtil.sliceString(formValue.addressLine1, " USA") : '';
    this.auctionEntityDto!.addressLine2 = formValue.addressLine2;
    this.auctionEntityDto!.city = formValue.city;
    this.auctionEntityDto!.zipCode = formValue.zipCode;
    this.auctionEntityDto!.state = formValue.state;
    this.auctionEntityDto!.stateAbbreviate = this.stateAbbreviate;
    this.auctionEntityDto!.propertyTitle = formValue.propertyTitle;
    this.auctionEntityDto!.propertyHighlightsTitle = formValue.propertyHighlightsTitle;
    this.auctionEntityDto!.propertyHighlights = formValue.propertyHighlights;
    this.auctionEntityDto!.propertyDetailsTitle = formValue.propertyDetailsTitle;
    this.auctionEntityDto!.propertyDetails = formValue.propertyDetails;
    this.auctionEntityDto!.termsConditions = formValue.termsConditions;
    this.auctionEntityDto!.confidentialAgreement = formValue.confidentialAgreement;
    this.auctionEntityDto!.latitude = this.latitude;
    this.auctionEntityDto!.longitude = this.longitude;
    this.auctionEntityDto!.showAddress = formValue.showAddress;;
  }

  openModal(content: any) {
    this.modalService.open(content, { size: 'md' })
  }

  openViewModal(title: string, content: string) {
    let modalRef = this.modalService.open(HtmlViewComponent, { size: 'md' });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.content = content;
    modalRef.componentInstance.isShowEdit = true;
  }

  modalClose() {
    this.modalService.dismissAll()
    this._validationErrorPresent$.next(false);
    this.currentModal = undefined;
  }

  truncateText(input?: string) {
    if (input) {
      return AuctionwareUtil.truncateString(input, 101);
    }
    return '';
  }

  handleAddressChange(address: Address) {
    console.log(address);
    this.latitude = address.geometry.location.lat();
    this.longitude = address.geometry.location.lng();

    let city = AuctionwareUtil.getAddressByType(address , 'locality');
    if(!city){
      city = AuctionwareUtil.getAddressByType(address , 'neighborhood');
    }
    this.stateAbbreviate = AuctionwareUtil.getAddressShortNameByType(address, 'administrative_area_level_1')
    let state = AuctionwareUtil.getAddressByType(address, 'administrative_area_level_1');
    let zip = AuctionwareUtil.getAddressByType(address, 'postal_code');
    let addressPart1 =  AuctionwareUtil.getAddressByType(address, 'street_number');
    let addressPart2 =  AuctionwareUtil.getAddressByType(address, 'route');

    // let slicedAddresCountry = AuctionwareUtil.sliceString(address.formatted_address, " USA");
    // let slicedAddresZIP = AuctionwareUtil.sliceString(slicedAddresCountry, " "+zip);
    // let slicedAddresState = AuctionwareUtil.sliceString(slicedAddresZIP, " "+state);
    // let slicedAddresCity = AuctionwareUtil.sliceString(slicedAddresState, " "+city);

    this.formGroup.controls['addressLine1'].patchValue( addressPart1 + " " + addressPart2);
    //this.formGroup.controls['addressLine1'].patchValue(addressPart1 + " " + addressPart2 )
    this.formGroup.controls['state'].patchValue(state);
    this.formGroup.controls['city'].patchValue(city);
    this.formGroup.controls['zipCode'].patchValue(zip);
    this.formGroup.updateValueAndValidity();
  }

  checkValidZipCode() {
    let value = this.formGroup.controls['zipCode'].value as string;
    this.formGroup.controls['zipCode'].setValue(AuctionwareUtil.onlyNumbers(value));
  }

  clearFormatOnPaste(editorId: any) {
    let element = document.getElementById(editorId);
    element?.addEventListener('paste', function (e: any) {
      e.preventDefault();

      const text = e.clipboardData
        ? (e.originalEvent || e).clipboardData.getData('text/plain')
        : '';

      if (document.queryCommandSupported('insertText')) {
          document.execCommand('insertText', false, text);
      } else {
          // Insert text at the current position of caret
          const range = document.getSelection()!.getRangeAt(0);
          range.deleteContents();

          const textNode = document.createTextNode(text);
          range.insertNode(textNode);
          range.selectNodeContents(textNode);
          range.collapse(false);

          const selection = window.getSelection();
          selection?.removeAllRanges();
          selection?.addRange(range);
      }
    });
  }

}
