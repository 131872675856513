import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { LoaderComponent } from 'src/app/shared/components/loader/loader.component';
import { AuthenticationService } from 'src/app/shared/services/common/authentication.service';
import { AuctionwareConstants } from 'src/app/shared/util/AuctionwareConstants';
import { Pattern } from 'src/app/shared/util/Patterns';
import { PasswordStrengthValidator } from 'src/app/shared/validators/password-strength.validators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {

  @ViewChild('appLoader', { static: false })
  appLoader?: LoaderComponent;

  _validationErrorPresent$ = new BehaviorSubject<boolean>(false);
  _isSaveButtonDisable$ = new BehaviorSubject<boolean>(false);

  currentModal?: string;

  isPasswordVisible: boolean = false;

  formGroup = new FormGroup({
    emailId: new FormControl('', [Validators.required, Validators.pattern(Pattern.email)]),
    password: new FormControl('', [Validators.required, Validators.minLength(9), PasswordStrengthValidator])
  });

  constructor(
    private modalService: NgbModal,
    public authService: AuthenticationService
  ) {}

  ngOnInit() {
    this.authService.errorMsg = "";
    if (this.formGroup) {
      this.formGroup.get('emailId')?.valueChanges.subscribe(val => {
        this.authService.errorMsg = "";
      });
      this.formGroup.get('password')?.valueChanges.subscribe(val => {
        this.authService.errorMsg = "";
      });
    }
  }

  togglePassword() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  handleValidSubmit() {
    let formValue = this.formGroup.value;

    if (this.formValidationFailed()) {
      return;
    }
    this._isSaveButtonDisable$.next(true);
    this.appLoader?.openLoaderIcon(AuctionwareConstants.USER_REGISTRATION_PROGRESS, "Please wait logging in...");
    let emailId = formValue.emailId;
    let password = formValue.password;
    this.authService.doAuthentication(emailId!, password!, this.appLoader!, this._isSaveButtonDisable$);
  }

  formValidationFailed() {
    this._validationErrorPresent$.next(false);
    if (this.formGroup.invalid) {
      this._validationErrorPresent$.next(true);
      return true;
    }
    return false;
  }

  openModal(content: any) {
    this.closeModal()
    this.authService.errorMsg ="";
    this.modalService.open(content, {
      size: 'md'
    });
  }

  closeModal() {
    this.modalService.dismissAll();
    this._validationErrorPresent$.next(false);
  }

}
