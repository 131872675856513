import { DatePipe } from "@angular/common";
import { DataLoadingEvent } from "../models/DataLoadingEvent";
import * as moment from 'moment-timezone';
import { NgbDateStruct, NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";
import { Address } from "ngx-google-places-autocomplete/objects/address";

export class AuctionwareUtil {

  static bytesToSize(bytes: number) {
    let i: number;
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)) + "");
    return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
  }

  static getPartSubDomainName() {
    let subDomainName = "localhost";
    let hostName = window.location.hostname;
    if (hostName) {
      let parts = hostName.split('.')
      if (parts) {
        subDomainName = parts[0];
        if (subDomainName) {
          return subDomainName
        }
      }
    }
    return subDomainName;

  }

  static getFullDomainName() {
    let basePath = window.location.host;
    console.log(basePath);
    return basePath;
  }

  static removeSpecialCharacters(inputString: string): string {
    const regex = /[^a-zA-Z0-9_ -]/g;
    const cleanedString = inputString.replace(regex, '');
    return cleanedString;
  }

  static isNumber(value: string | number): boolean {
    return ((value != null) &&
      (value !== '') &&
      !isNaN(Number(value.toString())));
  }

  static getNotificationEvent(value: boolean, message?: string) {
    return new DataLoadingEvent(value, message!);
  }

  static getFullTimezone(shortTimezone: string) {
    let timezones = [
      { key: 'America/New_York', value: 'Eastern Standard Time (EST)' },
      { key: 'America/Chicago', value: 'Central Standard Time (CST)' },
      { key: 'America/Denver', value: 'Mountain Standard Time (MST)' },
      { key: 'America/Tijuana', value: 'Pacific Standard Time (PST)' },
      { key: 'America/Anchorage', value: 'Alaskan Standard Time (AKST)' },
      { key: 'Pacific/Honolulu', value: 'Hawaii-Aleutian Standard Time (HST)' },
    ];
    return timezones.find(m => m.key == shortTimezone)?.value;
  }

  static getNgbPickerDate(date: string) {
    let dateParts = date?.split('/')!;
    return {
      year: Number.parseInt(dateParts[2]),
      month: Number.parseInt(dateParts[1]),
      day: Number.parseInt(dateParts[0])
    };
  }

  static getNgbPickerTime(time: string) {
    let timeParts = time?.split(':')!;
    return {
      hour: Number.parseInt(timeParts[0]),
      minute: Number.parseInt(timeParts[1]),
      second: 0
    };
  }

  static getDateFromNgDatePicker(datePipe: DatePipe, ngbDate: NgbDateStruct) {
    let date = new Date();
    date.setDate(ngbDate.day);
    date.setMonth(ngbDate.month - 1);
    date.setFullYear(ngbDate.year);
    return datePipe.transform(date, 'dd/MM/yyyy');
  }

  static getTimeFromNgTimePicker(datePipe: DatePipe, ngbTime: NgbTimeStruct): string {
    let newDate = new Date();
    newDate.setHours(ngbTime.hour, ngbTime.minute, ngbTime.second);
    return datePipe.transform(newDate, 'hh:mm a')!;
  }

  static convertedDate(date: string, time: string): Date | undefined{
    try {
      let d = date.split("/");
      let newDate = new Date(d[2] + '/' + d[1] + '/' + d[0]);
      newDate.setHours(Number.parseInt(time?.split(':')[0]), Number.parseInt(time?.split(':')[1]));
      return newDate;
    } catch (e) {
      let result = (e as Error).message;
      console.log(result);
    }
    return undefined;
  }

  static getDisplayDay(date: string, datePipe: DatePipe): string | undefined | null {
    try {
      let d = date.split("/");
      let newDate = new Date(d[2] + '/' + d[1] + '/' + d[0]);
      return datePipe.transform(newDate, 'EEE');
    } catch (e) {
      let result = (e as Error).message;
      console.log(result);
    }
    return '';
  }

  static getDateInMMMdd(date: string, datePipe: DatePipe) {
    let d = date.split("/");
    let newDate = new Date(d[2] + '/' + d[1] + '/' + d[0]);
    return datePipe.transform(newDate, 'MMM dd');
  }

  static getDisplayYear(date: string) {
    let d = date.split("/");
    let newDate = new Date(d[2] + '/' + d[1] + '/' + d[0]);
    return newDate.getFullYear();
  }

  static getDisplayTimeInHHmm(time: string, datePipe: DatePipe) {
    let date = new Date();
    date.setHours(Number.parseInt(time?.split(':')[0]), Number.parseInt(time?.split(':')[1]));
    return datePipe.transform(date, 'h:mm');
  }

  static getDisplayMeridiem(time: string, datePipe: DatePipe) {
    let date = new Date();
    date.setHours(Number.parseInt(time?.split(':')[0]), Number.parseInt(time?.split(':')[1]));
    return datePipe.transform(date, 'a');
  }

  static formatShortTimezone(shortTimezone?: string) {
    let timezones = [
      { key: 'America/New_York', value: 'EST' },
      { key: 'America/Chicago', value: 'CST' },
      { key: 'America/Denver', value: 'MST' },
      { key: 'America/Tijuana', value: 'PST' },
      { key: 'America/Anchorage', value: 'AKST' },
      { key: 'Pacific/Honolulu', value: 'HST' },
    ];
    let timezone = timezones.find(m => m.key == shortTimezone)?.value;
    return timezone?.replace('S', '');
  }

  static truncateString(input: string, maxLength: number) {
    if (input.length > maxLength) {
      return input.substring(0, maxLength) + '...';
    }
    return input;
  }

  // Countdown Timer
  static getCountdownTimerDays(date: string, timeZone: string) {
    let remainingTime = this.getRemainingTime(date, timeZone);
    if (remainingTime <= 0) return 0;
    return Math.floor(remainingTime / (1000 * 3600 * 24));
  }

  static getCountdownTimerHours(date: string, timeZone: string) {
    let remainingTime = this.getRemainingTime(date, timeZone);
    if (remainingTime <= 0) return 0;
    return Math.floor(Math.abs(remainingTime / (1000 * 3600) % 24));
  }

  static getCountdownTimerMinutes(date: string, timeZone: string) {
    let remainingTime = this.getRemainingTime(date, timeZone);
    if (remainingTime <= 0) return 0;
    return Math.floor(Math.abs(remainingTime / (1000 * 60) % 60));
  }

 static clone(obj: any) {
    if (obj == null || typeof (obj) != 'object')
      return obj;

    var temp = new obj.constructor();
    for (var key in obj)
      temp[key] = this.clone(obj[key]);

    return temp;
  }

  static getCountdownTimerSeconds(date: string, timeZone: string) {
    let remainingTime = this.getRemainingTime(date, timeZone);
    if (remainingTime <= 0) return 0;
    return Math.floor(Math.abs(remainingTime / (1000) % 60));
  }

  static getShortName(name: string) {
    let shortName = '';
    if (name.includes(" ")) {
      let fName = name.split(" ")[0];
      let lName = name.split(" ")[name.split(" ").length - 1];
      shortName = `${fName.charAt(0)} ${lName.charAt(0)}`;
    } else {
      shortName = `${name.charAt(0)} ${name.charAt(1)}`
    }
    return shortName;
  }

  static getFirstName(name: string) {
    let fName = '';
    if (name.includes(" ")) {
      fName = name.split(" ")[0];
    } else {
      fName = name;
    }
    return fName;
  }

  static getLastName(name: string) {
    let lName = '';
    if (name.includes(" ")) {
      lName = name.split(" ")[name.split(" ").length - 1];
    } else {
      lName = name;
    }
    return lName;
  }

  static getRemainingTime(endDate: string, timezone: string) {
    let utcMills = this.getUTCEPCTimeStamp();
    let endDateMills = this.getAuctionEndDateUnixMills(endDate, timezone);
    return endDateMills - utcMills;
  }

  static getUTCEPCTimeStamp(): number {
    return Date.parse(new Date().toISOString());
  }

  static getAuctionEndDateUnixMills(endDate: string, timezone: string) {
    let momentformat = this.convertMomentFormat(endDate);
    return moment.tz(momentformat, timezone).utc().valueOf()
  }

  static convertMomentFormat(dateToConvert: string) {
    let final = '';
    if (dateToConvert) {
      let str1 = dateToConvert.split('/').join('-');
      let str2 = str1.replace(' ', 'T')
      let str3 = str2.substring(0, 16);
      let year = str3.substring(6, 10);
      let month = str3.substring(3, 5);
      let day = str3.substring(0, 2);
      let lastStr = str3.substring(11, 16);
      final = year + "-" + month + "-" + day + " " + lastStr;
    }
    return final;
  }

  static isTimeGreaterThenCurrentTime(endDate: string, timezone: string){
    let toReturn = false;
    let utcMills = this.getUTCEPCTimeStamp();
    let endDateMills = this.getAuctionEndDateUnixMills(endDate, timezone);

    if(endDateMills >= utcMills){
      toReturn = true;
    }
    return toReturn;
  }

  /**
   *  [type]
   *  Zip Code      => postal_code
   *  State         => administrative_area_level_1
   *  City          => administrative_area_level_2, administrative_area_level_3
   *  Locality      => locality
   *  Country       => country
   * */
  static getAddressByType(address: Address, type: string): string {
    let toReturn = '';
    for (var i = 0; i < address.address_components.length; i++) {
      for (var j = 0; j < address.address_components[i].types.length; j++) {
        if (address.address_components[i].types[j] == type) {
          toReturn = address.address_components[i].long_name;
        }
      }
    }
    return toReturn;
  }

  static getAddressShortNameByType(address: Address, type: string): string {
    let toReturn = '';
    for (var i = 0; i < address.address_components.length; i++) {
      for (var j = 0; j < address.address_components[i].types.length; j++) {
        if (address.address_components[i].types[j] == type) {
          toReturn = address.address_components[i].short_name;
        }
      }
    }
    return toReturn;
  }

  static sliceString(inputString : string, sliceString: string){
    if(inputString.includes(sliceString)){
      let length = sliceString.length + 1;
      return inputString.slice(0, -length);
    }else{
      return inputString;
    }
  }

  static base64toFile(base64:any, filename: string, mimeType:string){
    return (fetch(base64)
        .then(function(res){return res.arrayBuffer();})
        .then(function(buf){return new File([buf], filename,{type:mimeType});})
    );
  }

  static convertTime12to24(time12h: string) {
    const [time, modifier] = time12h.split(' ');

    let [hours, minutes] = time.split(':');

    if (hours === '12') {
      hours = '00';
    }

    if (modifier === 'PM') {
      hours = (parseInt(hours, 10) + 12).toString();
    }

    return `${hours}:${minutes}`;
  }

  static convertTime24to12(time24h: string, datePipe: DatePipe) {
    let [hours, minutes] = time24h.split(':');

    let date = new Date();
    date.setHours(Number(hours), Number(minutes), 0, 0);

    return datePipe.transform(date, 'hh:mm a');
  }

  static onlyNumbers(value: string) {
    return value.replace(/[^0-9]/g,'');
  }

  static getUrlExtension(url: any) {
    return url.split(/[#?]/)[0].split('.').pop().trim();
  }

  static getYoutubeId(url: any) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return (match && match[2].length === 11)
      ? match[2]
      : null;
  }

  static isYoutubeUrl(url: any) {
    if (url) {
      var regExp = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      if (url.match(regExp)) {
        return true;
      }
    }
    return false;
  }

  static isVimeoUrl(url: string) {
    if (url) {
      var regExp = /^(http\:\/\/|https\:\/\/)?(www\.)?(vimeo\.com\/)([0-9]+)$/;
      if (url.match(regExp)) {
        return true;
      }
    }
    return false;
  }

  static getVimeoId(url: any) {
    var match = /vimeo.*\/(\d+)/i.exec(url);
    if (match) {
      return match[1];
    }
    return null;
  }

  static getVideoThumbnailFromUrl(url: string) {
    if (this.isYoutubeUrl(url)) {
      return `https://img.youtube.com/vi/${this.getYoutubeId(url)}/0.jpg`;
    } else if (this.isVimeoUrl(url)) {
      return `https://vumbnail.com/${this.getVimeoId(url)}.jpg`;
    } else {
      return '/assets/image/video_thumbnail.svg';
    }
  }

  static ensureHttps(input: string): string {
    // Check if the input text starts with "http://" or "https://"
    if (input.startsWith("http://") || input.startsWith("https://")) {
      return input; // If it does, return the input text
    } else {
      // Otherwise, prepend "https://" to the input text and return
      return "https://" + input;
    }
  }

}
