<div class="e-popup ee-width-m" id="e-popupproperty">
  <div class="e-popupblock ea-popupremove"></div>
  <div class="e-popuppage">
    <div class="e-spinner" *ngIf="isLoading"></div>
    <div class="e-popupalert ee-success" *ngIf="_showSuccessToast$ | async">Property Saved</div>
    <div class="e-popupalert ee-error" *ngIf="_showErrorToast$ | async">Error Saving Property</div>
    <header class="e-popupheader">
      <h1 class="modal-header">Property Details</h1>
      <a class="e-buttontext ee-size-m ee-vis-l ea-submit save-progress" [ngClass]="{'disabled': isLoading}"
        (click)="saveAuction(true, true)" *ngIf="screenWidth > 768">Save Progress
      </a>
      <a class="e-popupclose ea-popupremove" [ngClass]="{'disabled': isLoading}" (click)="modalClose()">Close</a>
    </header>
    <div class="e-popupmain">
      <div class="e-maintext e-maintext-m">
        <form autocomplete="off" [formGroup]="formGroup" (keyup.enter)="saveAuction(false, false)">
          <div class="e-field ea-valid-required" [ngClass]="{'ee-error': formGroup.controls['locationTitle'].invalid}">
            <label>Auction Title</label>
            <div class="e-fieldtext">
              <input placeholder="eg. Downtown Miami Strip Center" type="text" formControlName="locationTitle" />
            </div>
            <div
              *ngIf="(_validationErrorPresent$ | async) || fc.locationTitle.invalid && (fc.locationTitle.dirty || fc.locationTitle.touched)">
              <p class="e-fielderror e-fielderror-required" *ngIf="fc.locationTitle.errors?.required">Required</p>
            </div>
          </div>
          <div class="e-field ea-valid-required">
            <label>Short Description</label>
            <div class="e-fieldtext ee-height-s">
              <angular-editor #locationDescEditor formControlName="locationDescription" id="locationDescEditor"
                [config]="config"
                placeholder="eg. Downtown Miami Strip Center on 5 acres with 10 tennants on long-tem lease">
              </angular-editor>
            </div>
            <div
              *ngIf="(_validationErrorPresent$ | async) || fc.locationDescription.invalid && (fc.locationDescription.dirty || fc.locationDescription.touched)">
              <p class="e-fielderror e-fielderror-required" *ngIf="fc.locationDescription.errors?.required">Required</p>
            </div>
          </div>
          <div class="e-field ea-valid-required">
            <div class="col-auto e-field ea-valid-checked pe-1">
              <div class="e-fieldcheck ea-fieldcheck" (click)="changeAddressMode()">
                <input name="showAddress" type="checkbox" formControlName="showAddress"/>
                <label>SHOW ADDRESS
                </label>
              </div>
            </div>
          </div>

          <div class="e-formrow ee-pad-l">
            <div class="e-formcol em-width-50">
              <div class="e-field ea-valid-required"
                [ngClass]="{'ee-error': formGroup.controls['addressLine1'].invalid}">
                <label>Address Line 1</label>
                <div class="e-fieldtext">
                  <input ngx-google-places-autocomplete #placesRef="ngx-places"
                    (onAddressChange)="handleAddressChange($event)" type="text" formControlName="addressLine1" />
                </div>
                <div
                  *ngIf="(_validationErrorPresent$ |async) || fc.addressLine1.invalid && (fc.addressLine1.dirty || fc.addressLine1.touched)">
                  <p class="e-fielderror e-fielderror-required" *ngIf="fc.addressLine1.errors?.required">Required</p>
                  <p class="e-fielderror e-fielderror-required" *ngIf="fc.addressLine1.errors?.address">Invalid</p>
                </div>
              </div>
            </div>
            <div class="e-formcol em-width-50">
              <div class="e-field">
                <label>Address Line 2</label>
                <div class="e-fieldtext">
                  <input type="text" formControlName="addressLine2" />
                </div>
              </div>
            </div>
          </div>
          <div class="e-formrow ee-pad-l">
            <div class="e-formcol em-width-50">
              <div class="e-field ea-valid-required" [ngClass]="{'ee-error': formGroup.controls['city'].invalid}">
                <label>City</label>
                <div class="e-fieldtext">
                  <input type="text" formControlName="city" />
                </div>
                <div
                  *ngIf="(_validationErrorPresent$ | async) || fc.city.invalid && (fc.city.dirty || fc.city.touched)">
                  <p class="e-fielderror e-fielderror-required" *ngIf="fc.city.errors?.required">Required</p>
                </div>
              </div>
            </div>
            <div class="e-formcol em-width-50">
              <div class="e-field ea-valid-required" [ngClass]="{'ee-error': formGroup.controls['state'].invalid}">
                <label>State</label>
                <div class="e-fieldtext">
                  <input type="text" formControlName="state" />
                </div>
                <div
                  *ngIf="(_validationErrorPresent$ | async) || fc.state.invalid && (fc.state.dirty || fc.state.touched)">
                  <p class="e-fielderror e-fielderror-required" *ngIf="fc.state.errors?.required">Required</p>
                </div>
              </div>
            </div>
          </div>
          <div class="e-formrow ee-pad-l">
            <div class="e-formcol em-width-50">
              <div class="e-field ea-valid-required" [ngClass]="{'ee-error': formGroup.controls['zipCode'].invalid}">
                <label>Zip Code</label>
                <div class="e-fieldtext">
                  <input type="text" formControlName="zipCode" (keyup)="checkValidZipCode()" />
                </div>
                <div
                  *ngIf="(_validationErrorPresent$ | async) || fc.zipCode.invalid && (fc.zipCode.dirty || fc.zipCode.touched)">
                  <p class="e-fielderror e-fielderror-required" *ngIf="fc.zipCode.errors?.required">Required</p>
                  <p class="e-fielderror e-fielderror-required" *ngIf="fc.zipCode.errors?.maxlength">Invalid Zip Code
                  </p>
                  <p class="e-fielderror e-fielderror-required" *ngIf="fc.zipCode.errors?.minlength">Invalid Zip Code
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="e-field ea-valid-required" [ngClass]="{'ee-error': formGroup.controls['propertyTitle'].invalid}">
            <label>Property Title</label>
            <div class="e-fieldtext">
              <input placeholder="eg. PROPERTY" type="text" formControlName="propertyTitle" />
            </div>
            <div
              *ngIf="(_validationErrorPresent$ | async) || fc.propertyTitle.invalid && (fc.propertyTitle.dirty || fc.propertyTitle.touched)">
              <p class="e-fielderror e-fielderror-required" *ngIf="fc.propertyTitle.errors?.required">Required</p>
            </div>
          </div>

          <div class="e-field ea-valid-required"
            [ngClass]="{'ee-error': formGroup.controls['propertyHighlightsTitle'].invalid}">
            <label>Investment Highlights Title</label>
            <div class="e-fieldtext">
              <input placeholder="eg. INVESTMENT HIGHLIGHTS" type="text" formControlName="propertyHighlightsTitle" />
            </div>
            <div
              *ngIf="(_validationErrorPresent$ | async) || fc.propertyHighlightsTitle.invalid && (fc.propertyHighlightsTitle.dirty || fc.propertyHighlightsTitle.touched)">
              <p class="e-fielderror e-fielderror-required" *ngIf="fc.propertyHighlightsTitle.errors?.required">Required
              </p>
            </div>
          </div>
          <div class="e-field">
            <label>Investment Highlights (Add new line to create a new highlight bullet)</label>
            <div class="e-fieldtext ee-height-s">
              <textarea #propertyHighlightEditor formControlName="propertyHighlights"></textarea>
            </div>
            <div
              *ngIf="(_validationErrorPresent$ | async) || fc.propertyHighlights.invalid && (fc.propertyHighlights.dirty || fc.propertyHighlights.touched)">
              <p class="e-fielderror e-fielderror-required" *ngIf="fc.propertyHighlights.errors?.required">Required</p>
            </div>
          </div>
          <div class="e-field ea-valid-required"
            [ngClass]="{'ee-error': formGroup.controls['propertyDetailsTitle'].invalid}">
            <label>Property Details Title</label>
            <div class="e-fieldtext">
              <input placeholder="eg. PROPERTY DETAILS" type="text" formControlName="propertyDetailsTitle" />
            </div>
            <div
              *ngIf="(_validationErrorPresent$ | async) || fc.propertyDetailsTitle.invalid && (fc.propertyDetailsTitle.dirty || fc.propertyDetailsTitle.touched)">
              <p class="e-fielderror e-fielderror-required" *ngIf="fc.propertyDetailsTitle.errors?.required">Required
              </p>
            </div>
          </div>
          <div class="e-field">
            <label>Property Details</label>
            <div class="e-fieldtext ee-height-m">
              <angular-editor #detailsEditor formControlName="propertyDetails" id="detailsEditor" [config]="config">
              </angular-editor>
            </div>
            <div
              *ngIf="(_validationErrorPresent$ | async) || fc.propertyDetails.invalid && (fc.propertyDetails.dirty || fc.propertyDetails.touched)">
              <p class="e-fielderror e-fielderror-required" *ngIf="fc.propertyDetails.errors?.required">Required</p>
            </div>
          </div>
          <div class="e-field">
            <label>Terms &amp; Conditions</label>
            <div class="e-fieldtext ee-height-m">
              <angular-editor #termsEditor formControlName="termsConditions" id="termsEditor" [config]="config">
              </angular-editor>
            </div>
            <div
              *ngIf="(_validationErrorPresent$ | async) || fc.termsConditions.invalid && (fc.termsConditions.dirty || fc.termsConditions.touched)">
              <p class="e-fielderror e-fielderror-required" *ngIf="fc.termsConditions.errors?.required">Required</p>
            </div>
          </div>
          <div class="e-field ea-valid-required">
            <div class="col-auto e-field ea-valid-checked pe-1">
              <div class="e-fieldcheck ea-fieldcheck" (click)="changeConfidentialAgreementMode()">
                <input name="showConfidentialAgreement" type="checkbox" formControlName="showConfidentialAgreement"/>
                <label>Use Confidentiality Agreement
                </label>
              </div>
            </div>
          </div>
          <div class="e-field" *ngIf="isShowConfidentialAgreement">
            <label>Confidentiality Agreement</label>
            <div class="e-fieldtext ee-height-m">
              <angular-editor #confidentialAgreement formControlName="confidentialAgreement" id="confidentialAgreement"
                [config]="config"></angular-editor>
            </div>
            <div
              *ngIf="(_validationErrorPresent$ | async) || fc.confidentialAgreement.invalid && (fc.confidentialAgreement.dirty || fc.confidentialAgreement.touched)">
              <p class="e-fielderror e-fielderror-required" *ngIf="fc.confidentialAgreement.errors?.required">Required
              </p>
            </div>
          </div>
          <div class="e-formsubmit">
            <a role="button" class="e-buttontext ee-size-m ee-vis-s ea-popupremove" (click)="modalClose()">Cancel</a>
            <a class="e-buttontext ee-size-m ee-vis-l ea-submit" [ngClass]="{'disabled': isLoading}"
              (click)="saveAuction(true, true)" *ngIf="screenWidth > 768">Save Progress</a>
            <a role="button" class="e-buttontext ee-size-m ee-vis-l ea-submit" [ngClass]="{'disabled': isLoading}"
              (click)="saveAuction(true, false)">Save</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
