<div class="e-popup ee-width-s" id="e-popupforgot">
  <div class="e-popupblock ea-popupremove"></div>
  <div class="e-popuppage">
    <div class="e-spinner" *ngIf="isLoading$ | async"></div>
    <div class="e-popupalert ee-error" *ngIf="showErrorMsg$ | async">{{message}}</div>
    <div class="e-popupalert ee-success" *ngIf="showSuccessMsg$ | async">{{message}}</div>
    <header class="e-popupheader">
      <h1>Forgotten Password</h1>
      <a class="e-popupclose ea-popupremove" (click)="closeModal()">Close</a>
    </header>
    <div class="e-popupmain">
      <div class="e-maintext e-maintext-m">
        <p>Not registered? Please
          <a class="ea-popupremove ea-popupload" (click)="openModal(registrationModal)">register to bid</a>.
        </p>
        <form autocomplete="off">
          <div class="e-field ea-valid-required ea-valid-email">
            <label>Email</label>
            <div class="e-fieldtext"
              [ngClass]="{'ee-error': ctrlEmail.invalid && (ctrlEmail.dirty || ctrlEmail.touched)}">
              <input id="ea-valid-email" type="text" [formControl]="ctrlEmail" />
              <div *ngIf="ctrlEmail.invalid && (ctrlEmail.dirty || ctrlEmail.touched)">
                <p class="e-fielderror e-fielderror-required" *ngIf="ctrlEmail.errors?.['required']">Required</p>
                <p class="e-fielderror e-fielderror-required" *ngIf="ctrlEmail.errors?.['pattern']">Invalid</p>
              </div>
            </div>
          </div>
          <div class="e-formsubmit">
            <a class="e-buttontext ee-size-m ee-vis-s ea-popupremove" (click)="closeModal()">Cancel</a>
            <a class="e-buttontext ee-size-m ee-vis-l ea-submit" (click)="generateOtp(otpModal)">Next</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- Register Modal -->
<ng-template #registrationModal>
  <app-registration></app-registration>
</ng-template>

<!-- OTP Modal -->
<ng-template #otpModal>
  <div class="e-popup ee-width-s" id="e-popupregister">
    <div class="e-popupblock ea-popupremove"></div>
    <div class="e-popuppage">
      <div class="e-spinner" *ngIf="isLoading$ | async"></div>
      <div class="e-popupalert ee-error" *ngIf="showErrorMsg$ | async">{{message}}</div>
      <div class="e-popupalert ee-success" *ngIf="showSuccessMsg$ | async">{{message}}</div>
      <header class="e-popupheader">
        <h1>Forgot Password</h1>
        <a class="e-popupclose ea-popupremove" (click)="closeModal()" ngbAutofocus>Close</a>
      </header>
      <div class="e-popupmain">
        <div class="e-maintext e-maintext-m">
          <p>Enter the One Time Password (OTP) which was just sent to the email address registered with. Be sure to
            check your spam folder.</p>
          <form [formGroup]="formGroup" autocomplete="off" class="needs-validation" novalidate
            (keyup.enter)="verifyOTP()">
            <div class="e-formrow ee-pad-l">
              <div class="e-formcol em-width-100">
                <div class="e-field ea-valid-required ea-valid-otp">
                  <label>OTP</label>
                  <div class="e-fieldtext" [ngClass]="{'ee-error': fc.otp.invalid && (fc.otp.dirty || fc.otp.touched)}">
                    <input type="text" id="otp" formControlName="otp" />
                  </div>
                  <div *ngIf="fc.otp.invalid && (fc.otp.dirty || fc.otp.touched)">
                    <p class="e-fielderror e-fielderror-required" *ngIf="fc.otp.errors?.['required']">Required</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="e-formrow ee-pad-l">
              <div class="e-formcol em-width-100">
                <div class="e-field e-valid-required e-valid-password">
                  <label>Password</label>
                  <div class="e-fieldtext"
                    [ngClass]="{'ee-error': fc.password.invalid && (fc.password.dirty || fc.password.touched)}">
                    <span class="ee-suffix ee-size-s" [ngClass]="isPasswordVisible ? 'ei-visible' : 'ei-visible-not'" (click)="togglePassword()"></span>
                    <input id="e-valid-password" name="" [type]="isPasswordVisible ? 'text' : 'password'" formControlName="password" required autocomplete="new-password"
                    (keyup)="onChange()" (paste)="onChange()"/>
                  </div>
                  <div *ngIf="fc.password.invalid && (fc.password.dirty || fc.password.touched)">
                    <p class="e-fielderror e-fielderror-required" *ngIf="fc.password.errors?.['required']">Required</p>
                    <p class="e-fielderror e-fielderror-required" *ngIf="fc.password.invalid && fc.password.value">
                      Password must be more than 8 characters, containing at least one capital letter, one lowercase
                      letter, one number and one special character.</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="e-formrow ee-pad-l">
              <div class="e-formcol em-width-100">
                <div class="e-field e-valid-required e-valid-password e-valid-match" data-match="#e-valid-password">
                  <label>Confirm Password</label>
                  <div class="e-fieldtext"
                    [ngClass]="{'ee-error': fc.repeatPassword.invalid && (fc.repeatPassword.dirty || fc.repeatPassword.touched)}">
                    <span class="ee-suffix ee-size-s" [ngClass]="isPasswordVisibleRepeat ? 'ei-visible' : 'ei-visible-not'"
                    (click)="togglePasswordRepeat()"></span>
            <input name="" [type]="isPasswordVisibleRepeat ? 'text' : 'password'" formControlName="repeatPassword" (keyup)="onChange()" (paste)="onChange()" />
                  </div>
                  <div *ngIf="fc.repeatPassword.invalid && (fc.repeatPassword.dirty || fc.repeatPassword.touched)">
                    <p class="e-fielderror e-fielderror-required" *ngIf="fc.repeatPassword.errors?.['required']">
                      Required</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="e-formsubmit">
              <a class="e-buttontext ee-size-m ee-vis-s ea-popupremove" (click)="closeModal()">Cancel</a>
              <a class="e-buttontext ee-size-m ee-vis-l ea-popupremove ea-popupload" (click)="verifyOTP()">Verify</a>
            </div>
          </form>
          <p>
            <a class="ea-popupremove ea-popupload" (click)="resendOTP()">Resend OTP ?</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</ng-template>
