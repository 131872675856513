import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-html-view',
  templateUrl: './html-view.component.html',
  styleUrls: ['./html-view.component.sass']
})
export class HtmlViewComponent implements AfterViewInit {

  @Input() title?: string;
  @Input() content?: string;
  @Input() isShowEdit?: boolean;

  @ViewChild('viewContent') viewContent?: ElementRef

  constructor(
    private activeModal: NgbActiveModal,
    private modalService: NgbModal
  ) {}

  ngAfterViewInit(): void {
    if (this.title != 'Highlights') {
      this.viewContent!.nativeElement.innerHTML = this.content!;
    }
  }

  getContent(content: string) {
    return content.split('\n');
  }

  closeModal() {
    this.activeModal.close()
  }

  openEditModal(content: any) {
    this.modalService.open(content, {
      size: 'lg'
    })
  }

}
