import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuctionEntityDto } from '../../models/AuctionEntityDto';
import { SupplierAuctionService } from '../../services/supplier-auction-service';
import { HtmlViewComponent } from '../html-view/html-view.component';
import { ClientBrandDetailsDto } from '../../models/ClientBrandDetailsDto';
import { LandingDashboardService } from '../../services/landing-auction-service';
import { PrivacyPolicyAuctionwareComponent } from '../privacy-policy-auctionware/privacy-policy-auctionware.component';
import { TermsOfUseForAuctionAuctionwareComponent } from '../terms-of-use-for-auction-auctionware/terms-of-use-for-auction-auctionware.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.sass'],
})
export class FooterComponent implements OnInit, AfterViewInit {

  auctionEntityDto?: AuctionEntityDto;
  clientBrandDetails?: ClientBrandDetailsDto;

  constructor(
    private landingDashboardService: LandingDashboardService,
    private modalService: NgbModal,
    private supplierService: SupplierAuctionService,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.landingDashboardService.getClientBrandDetailsData();
    this.supplierService.getAuctionEntityDto$.subscribe(data => {
      if (data) {
        this.auctionEntityDto = data;
      }
    })
  }

  ngAfterViewInit(): void {
    this.landingDashboardService.getClientBrandDetails$.subscribe(data => {
      this.clientBrandDetails = data;
    })
  }

  openViewModal(title: string, fileName: string) {
    this.http.get(`assets/policy/${fileName}.html`, { responseType: 'text' })
      .subscribe((data) => {
        if (data) {
          let modalRef = this.modalService.open(HtmlViewComponent, { size: 'md' });
          modalRef.componentInstance.title = title;
          modalRef.componentInstance.content = data;
          modalRef.componentInstance.isShowEdit = false;
        }
      })
  }

  openModal(content: any, type: string) {
    if (this.clientBrandDetails?.brandName == 'Auctionware') {
      let component = type == 'privacy' ? PrivacyPolicyAuctionwareComponent : TermsOfUseForAuctionAuctionwareComponent;
      let modalRef = this.modalService.open(component, {
        size: 'xl'
      });

      if (type == 'privacy') {
        modalRef.componentInstance.content = this.clientBrandDetails?.privacy;
        modalRef.componentInstance.headerName = this.clientBrandDetails?.footerName;
      } else {
        modalRef.componentInstance.content = this.clientBrandDetails?.termsOfUse;
        modalRef.componentInstance.headerName = this.clientBrandDetails?.footerName;
        modalRef.componentInstance.sellerContent = this.clientBrandDetails?.sellerBody;
        modalRef.componentInstance.bidderContent = this.clientBrandDetails?.bidderBody;
      }
    } else {
      this.modalService.open(content, {
        size: 'xl'
      })
    }
  }

  getCurrentYear() {
    return new Date().getFullYear();
  }

}
