export class DataLoadingEvent {

  value: boolean;
  message: string;

  constructor(value: boolean, message: string) {
    this.value = value;
    this.message = message;
  }

}
