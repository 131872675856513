import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { ChangePasswordDto } from 'src/app/shared/models/user/ChangePasswordDto';
import { UserEntityDto } from 'src/app/shared/models/UserEntityDto';
import { AucProBidTosterService } from 'src/app/shared/services/common/Aucprobid-toaster-service';
import { UserService } from 'src/app/shared/services/user.service';
import { AuctionwareConstants } from 'src/app/shared/util/AuctionwareConstants';
import { PasswordStrengthValidator } from 'src/app/shared/validators/password-strength.validators';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.sass']
})
export class ChangePasswordComponent implements OnInit {

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  errorMsg?: string;
  isLoading: boolean = false;

  isPasswordVisibleOld: boolean = false;
  isPasswordVisibleNew: boolean = false;
  isPasswordVisibleRepeat: boolean = false;

  formGroup = this.formBuilder.group({
    oldPassword: new FormControl('', Validators.required),
    newPassword: new FormControl('', [Validators.required , Validators.minLength(9), PasswordStrengthValidator]),
    repeatPassword: new FormControl('', Validators.required)
  });

  constructor(public formBuilder: FormBuilder, private modalService: NgbModal,
    private userService: UserService,
    private toasterService: AucProBidTosterService) {

  }

  get fc(): any { return this.formGroup.controls; }

  ngOnInit() {
    this.formGroup.controls['oldPassword'].valueChanges.subscribe( data => {
      this._showErrorToast$.next(false);
    })
    this.formGroup.controls['newPassword'].valueChanges.subscribe( data => {
      this._showErrorToast$.next(false);
    })
    this.formGroup.controls['repeatPassword'].valueChanges.subscribe( data => {
      this._showErrorToast$.next(false);
    })
  }

  togglePasswordOld() {
    this.isPasswordVisibleOld = !this.isPasswordVisibleOld;
  }

  togglePasswordNew() {
    this.isPasswordVisibleNew = !this.isPasswordVisibleNew;
  }

  togglePasswordRepeat() {
    this.isPasswordVisibleRepeat = !this.isPasswordVisibleRepeat;
  }

  changePassword() {
    if (this.formGroup.dirty) {
      if (this.formGroup.invalid) {
        this.toasterService.openErrorToster("Form Invalid");
        return;
      }
      if (this.formGroup.controls['oldPassword'].value == this.formGroup.controls['newPassword'].value) {
        this.errorMsg = "Old Password is Same as New Password";
        this._showErrorToast$.next(true);
        return;
      }
      if (this.formGroup.controls['newPassword'].value != this.formGroup.controls['repeatPassword'].value) {
        this.errorMsg = "Password Mismatch";
        this._showErrorToast$.next(true);
        return;
      }
      this.isLoading = true;
      let changePasswordDto = new ChangePasswordDto();
      changePasswordDto.userName = this.userService.getUserEntity().name;
      changePasswordDto.emailId = this.userService.getUserEntity().emailId;
      changePasswordDto.newPassword = this.formGroup.controls['newPassword'].value!;
      changePasswordDto.oldPassword = this.formGroup.controls['oldPassword'].value!;
      this.userService.changePassword(changePasswordDto).subscribe({
        next: (apiResponseDto: ServerAPIResponseDto) => {
          if (apiResponseDto && apiResponseDto.code == AuctionwareConstants.SUCCESS_CODE) {
            this.isLoading = false;
            this._showSuccessToast$.next(true);
            this.userService.setUserDto(apiResponseDto.data as UserEntityDto);
            setTimeout(() => {
              this._showSuccessToast$.next(false);
              this.closeModal();
              this.toasterService.openSuccessToster("Password Updated Successfully");
            }, 2000)
          } else {
            this.isLoading = false;
            this.errorMsg = apiResponseDto.message;
            this._showErrorToast$.next(true);
            setTimeout(() => {
              this._showErrorToast$.next(false);
            }, 2000)
          }
        },
        error: (err: any) => {
          this.isLoading = false;
          this.errorMsg = "Error while changing password";
          this._showErrorToast$.next(true);
          setTimeout(() => {
            this._showErrorToast$.next(false);
          }, 2000)
          this.toasterService.openErrorToster("Error while changing user password");
        }
      })
    }
  }

  closeModal() {
    this.modalService.dismissAll();
  }

}
