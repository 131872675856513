import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, debounceTime, distinctUntilChanged } from 'rxjs';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { ForgotPasswordDto } from 'src/app/shared/models/user/ForgotPasswordDto';
import { AuthenticationService } from 'src/app/shared/services/common/authentication.service';
import { UserService } from 'src/app/shared/services/user.service';
import { AuctionwareConstants } from 'src/app/shared/util/AuctionwareConstants';
import { Pattern } from 'src/app/shared/util/Patterns';
import { PasswordStrengthValidator } from 'src/app/shared/validators/password-strength.validators';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.sass']
})
export class ForgotPasswordComponent implements OnInit {
  ctrlEmail: FormControl = new FormControl('', [Validators.required, Validators.pattern(Pattern.email)]);
  formGroup: FormGroup;

  otpId?: string;
  message?: string;

  showSuccessMsg$ = new BehaviorSubject<boolean>(false);
  showErrorMsg$ = new BehaviorSubject<boolean>(false);
  isLoading$ = new BehaviorSubject<boolean>(false);

  isPasswordVisible: boolean = false;
  isPasswordVisibleRepeat: boolean = false;
  _isPasswordMatch$ = new BehaviorSubject<boolean>(false);

  constructor(
    private modalService: NgbModal,
    private authService: AuthenticationService,
    private userService: UserService,
    private fb: FormBuilder
  ) {
    this.formGroup = this.fb.group({
      otp: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(9), PasswordStrengthValidator]],
      repeatPassword: ['', Validators.required],
    })
  }

  ngOnInit() {
    this.formGroup.controls['password'].valueChanges
    .pipe(debounceTime(1000))
    .pipe(distinctUntilChanged()).subscribe((value) => {
      this.message = "";
      this.showErrorMsg$.next(false);
    })

    this.formGroup.controls['repeatPassword'].valueChanges
    .pipe(debounceTime(1000))
    .pipe(distinctUntilChanged()).subscribe((value) => {
      this.message = "";
      this.showErrorMsg$.next(false);
    })
  }

  get fc(): any { return this.formGroup.controls; };

  openModal(content: any) {
    this.isLoading$.next(false);
    this.authService.errorMsg = "";
    this.modalService.open(content, {
      size: 'md'
    });
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  hideToaster() {
    setTimeout(() => {
      this.showErrorMsg$.next(false);
      this.showSuccessMsg$.next(false);
      this.message = "";
    }, 2000);
  }

  hideToasterAndClose() {
    setTimeout(() => {
      this.showErrorMsg$.next(false);
      this.showSuccessMsg$.next(false);
      this.message = "";
      this.closeModal();
    }, 2000);
  }

  resetModal() {
    this.otpId = undefined;
    this.message = undefined;
  }

  togglePassword() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  togglePasswordRepeat() {
    this.isPasswordVisibleRepeat = !this.isPasswordVisibleRepeat;
  }
  onChange() {

    if (this.formGroup.controls['password'].value == this.formGroup.controls['repeatPassword'].value) {
      this._isPasswordMatch$.next(true);
    } else {
      this._isPasswordMatch$.next(false);
    }
  }

  generateOtp(content: any) {
    this.resetModal();

    if (this.ctrlEmail.invalid) {
      return;
    }

    this.isLoading$.next(true);
    let email = (this.ctrlEmail.value as string).trim();

    this.userService.generateOTP(email, '', '', 'FORGOT_PASSWORD').subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == AuctionwareConstants.SUCCESS_CODE) {
          this.otpId = apiResponseDto.data as string;
          this.isLoading$.next(false);
          this.openModal(content);
        } else {
          this.message = apiResponseDto.message;
          this.showErrorMsg$.next(true);
          this.isLoading$.next(false);
          this.hideToaster();
        }
      },
      error: (err) => {
        this.message = "Error While OTP Generate";
        this.showErrorMsg$.next(true);
        this.isLoading$.next(false);
        this.hideToaster();
      }
    })
  }

  resendOTP() {
    let email = (this.ctrlEmail.value as string).trim();
    this.userService.resendOTP(email, '', '', this.otpId as string).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == AuctionwareConstants.SUCCESS_CODE) {
          this.otpId = apiResponseDto.data as string;

          this.message = "OTP resent";
          this.showSuccessMsg$.next(true);

          setTimeout(() => {
            this.message = "";
            this.showSuccessMsg$.next(false);
          }, 2000);
        } else {
          this.authService.errorMsg = "Error While sending OTP";
        }
      },
      error: (err) => {
        this.authService.errorMsg = "Error While sending OTP";
      }
    })
  }

  verifyOTP() {
    this.showErrorMsg$.next(false);
    this.message = '';

    let email = (this.ctrlEmail.value as string).trim();
    let formValue = this.formGroup.value;

    if (this.formGroup.invalid) {
      return;
    }

    if (formValue.password != formValue.repeatPassword) {
      this.message = "Password Mismatch";
      this.showErrorMsg$.next(true);
      return;
    }

    this.isLoading$.next(true);

    let forgotPasswordDto = new ForgotPasswordDto();
    forgotPasswordDto.otpId = this.otpId;
    forgotPasswordDto.userEnteredOtp = formValue.otp;

    this.userService.forgotPassword(forgotPasswordDto, email, formValue.password).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == AuctionwareConstants.SUCCESS_CODE) {
          this.isLoading$.next(false);

          this.message = "Password changed successfully!";
          this.showSuccessMsg$.next(true);
          this.hideToasterAndClose();
        } else {
          this.message = apiResponseDto.message;
          this.showErrorMsg$.next(true);
          this.isLoading$.next(false);
          this.hideToaster();
        }
      },
      error: (err) => {
        this.message = "Error While OTP Verification.";
        this.showErrorMsg$.next(true);
        this.isLoading$.next(false);
        this.hideToaster();
      }
    })
  }

}
