import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FileInfoDto } from '../../models/FileInfoDto';
import { FileService } from '../../services/file.service';

@Component({
  selector: 'app-share-buttons',
  templateUrl: './share-buttons.component.html',
  styleUrls: ['./share-buttons.component.sass']
})
export class ShareButtonsComponent implements OnInit {
  @Input() button!: string;
  @Input() url?: string;
  @Input() description?: string;
  @Input() fileInfoDto?: FileInfoDto;

  ngbModelRef?: NgbModalRef;

  @ViewChild('barCodeTemp', { static: true }) barCodeTemp!: ElementRef;

  linkInDescription = {
    description: (p: any) => {
      return p.description ? `${p.description}\r\n${p.url}` : p.url;
    }
  };

  SHARE_BUTTONS = {
    facebook: {
      type: 'facebook',
      text: 'Facebook',
      ariaLabel: 'Share on Facebook',
      icon: ['fab', 'facebook-f'],
      color: '#4267B2',
      share: {
        desktop: 'https://www.facebook.com/sharer/sharer.php?'
      },
      params: {
        url: 'u',
        description: 'quote'
      }
    },
    twitter: {
      type: 'twitter',
      text: 'X',
      ariaLabel: 'Share on X',
      icon: ['fab', 'twitter'],
      color: '#00acee',
      share: {
        desktop: 'https://twitter.com/intent/tweet?'
      },
      params: {
        url: 'url',
        description: 'text',
        tags: 'hashtags',
        via: 'via'
      }
    },
    linkedin: {
      type: 'linkedin',
      text: 'LinkedIn',
      ariaLabel: 'Share on LinkedIn',
      icon: ['fab', 'linkedin-in'],
      color: '#006fa6',
      share: {
        desktop: 'https://www.linkedin.com/feed?shareActive=true&'
      },
      params: {
        url: 'text'
      }
    },
    whatsapp: {
      type: 'whatsapp',
      text: 'WhatsApp',
      ariaLabel: 'Share on WhatsApp',
      icon: ['fab', 'whatsapp'],
      color: '#25D366',
      share: {
        desktop: 'https://web.whatsapp.com/send?',
        android: 'whatsapp://send?',
        ios: 'https://api.whatsapp.com/send?'
      },
      params: {
        description: 'text'
      },
      paramsFunc: this.linkInDescription
    },
    copy: {
      type: 'copy',
      text: 'Copy URL',
      ariaLabel: 'Share',
      icon: ['fas', 'copy'],
      color: '#007bff'
    },
    BarCode: {
      type: 'BarCode',
      text: 'QRCode',
      ariaLabel: 'QRCode',
      icon: ['fas', 'qrcode'],
      color: '#007bff'
    },
  };

  constructor(
    private clipboard: Clipboard,
    private fileService: FileService,
    private ngbModal: NgbModal,
  ) { }

  ngOnInit(): void { }

  get getConfig() {
    return (this.SHARE_BUTTONS as any)[this.button];
  }

  get getIcon() {
    return this.getConfig.type;
  }

  share() {
    let url;

    switch (this.button) {
      case 'BarCode':
        this.openBarModel();
        break;
      case 'whatsapp':
        url = `${this.getConfig.share.desktop}${this.getConfig.params.description}=${encodeURIComponent(this.description + '\r\n' + this.url)}`;
        break;
      case 'linkedin':
        url = `${this.getConfig.share.desktop}${this.getConfig.params.url}=${encodeURIComponent(this.description + '\n' + this.url)}`;
        break;
      case 'copy':
        this.copyToClipboard();
        return;
      default:
        url = `${this.getConfig.share.desktop}${this.getConfig.params.url}=${encodeURIComponent(this.url!)}&${this.getConfig.params.description}=${encodeURIComponent(this.description!)}`;
        break;
    }

    if (url) {
      window.open(url, '_blank');
    }
  }

  copyToClipboard() {
    if (this.url) {
      this.clipboard.copy(this.url);
    }
  }

  downloadQRCode() {
    if (this.fileInfoDto) {
      this.fileService.downloadLandingFile(this.fileInfoDto.fileId!).subscribe(data => {
        if (data) {
          const file = new Blob([data], { type: 'image/png' });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(file);
          link.download = this.fileInfoDto!.fileName!;
          link.click();
        }
      });
    }
  }

  openBarModel() {
    this.ngbModelRef = this.ngbModal.open(this.barCodeTemp, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    });
  }

  closeModal() {
    if (this.ngbModelRef) {
      this.ngbModelRef.close();
    }
  }
}
