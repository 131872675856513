<div class="e-splashbid">
  <div class="ee-row">
    <div class="ee-col">
      <h1>{{sidebarTitle}}</h1>
      <p>ENDS IN:</p>
      <div class="e-countdown" data-date="2022/12/06 15:00" data-format="hms">
        <ul class="e-countdownmenu">
          <li><strong>{{countdownTimerDays}}</strong> <span>Days</span></li>
          <li><strong>{{countdownTimerHours}}</strong> <span>Hrs</span></li>
          <li><strong>{{countdownTimerMinutes}}</strong> <span>Mins</span></li>
          <li><strong>{{countdownTimerSeconds}}</strong> <span>Secs</span></li>
        </ul>
      </div>
      <div class="row justify-content-center align-items-center" *ngIf="auctionEntityDto?.antiSnipe">
        <div class="col-auto pe-0">
          <p>Subject to soft close of {{auctionEntityDto?.antiSnipe}} mins.</p>
        </div>
        <div class="col-auto ps-1">
          <span class="e-buttonbullet ee-size-s ei-view soft-close-info"
            [ngbPopover]="popContentSoftClose" triggers="manual" #p1="ngbPopover" placement="left" container="body"
            (click)="p1.open()">
          </span>
        </div>
      </div>
    </div>
    <div class="ee-col" [ngClass]="{'ea-disabled' : !(_showBidPlaceButton$ | async) && isLoggedIn}">
      <p class="text-white" *ngIf="isReservedNotMet$ | async ">RESERVE NOT MET</p>
      <ul class="ee-total">
        <li>
          <small>{{highestBidUserId ? 'Current High Bid' : 'Starting Bid'}}</small>
          <strong>${{getFormattedPrice(highestBidPrice$ | async)}}</strong>
        </li>
        <li>
          <small>Bid Increment</small>
          <span class="e-buttonbullet ee-size-s ei-view new-eye-icon"
            [ngbPopover]="popContentBidIncrement" triggers="manual" #p="ngbPopover" placement="left" container="body"
            (click)="p.open()">
          </span>
          <strong>${{getFormattedPrice(incrementalBidPrice$ | async)}}</strong>
        </li>
      </ul>
      <form *ngIf="!isLoggedIn" autocomplete="off" class="ea-formbid" data-bid="100000" data-inc="1000"
        data-max="111000" data-prefix="$">
        <div class="e-field ea-formbid-field">
          <div class="e-fieldbutton">
            <a class="e-buttontext ee-size-m ee-vis-l ee-empty ei-minus ea-popupload" (click)="openLogoutModal(logoutModal)"></a>
            <div class="e-fieldtext">
              <div class="ee-fake ea-formbid-text">${{getFormattedPrice(currentBidPrice$ | async)}}</div>
            </div>
            <a class="e-buttontext ee-size-m ee-vis-l ee-empty ei-plus ea-popupload" (click)="openLogoutModal(logoutModal)" ></a>
          </div>
        </div>
        <input class="ea-formbid-input" type="hidden" value="101000" />
        <div class="e-formsubmit">
          <a class="e-buttontext ee-size-m ee-vis-l ea-popupload" (click)="openLogoutModal(logoutModal)">Place Bid</a>
        </div>
      </form>
      <form *ngIf="isLoggedIn" autocomplete="off" class="ea-formbid" data-bid="100000" data-inc="1000" data-max="111000"
        data-prefix="$">
        <div class="e-field ea-formbid-field">
          <div class="e-fieldbutton">
            <a class="e-buttontext ee-size-m ee-vis-l ee-empty ei-minus ea-formbid-minus"
            longPress (mouseLongPress)="decrementUserBid()"(click)="decrementUserBid()"></a>
            <div class="e-fieldtext">
              <!-- <div class="ee-fake ea-formbid-text">${{getFormattedPrice(currentBidPrice$ | async)}}</div> -->
              <input class="ee-fake ea-formbid-text" type="text" (keyup)="checkUserInputCurrentBid()" [formControl]="userInputCurrentBid">
            </div>
            <a class="e-buttontext ee-size-m ee-vis-l ee-empty ei-plus ea-formbid-plus"
            longPress (mouseLongPress)="incrementUserBid()" (click)="incrementUserBid()"></a>
          </div>
        </div>
        <input class="ea-formbid-input" type="hidden" value="101000" />
        <div class="e-formsubmit">
          <a class="e-buttontext ee-size-m ee-vis-l ea-formbid-submit" (click)="openBidPlaceModal(placeBidModal)">
            Place Bid
          </a>
        </div>
      </form>
      <div class="ee-max" *ngIf="isLoggedIn">
        <small>Your Max Bid</small>
        <strong>{{getBucket(auctionBidsDetails!)}}</strong>
        <a class="ea-popupload"
          [ngClass]="{'disabled': !(_showBidPlaceButton$ | async)}" (click)="openMaxBidPlaceModal(maxBidModal)">
          {{getBucket(auctionBidsDetails!) != '' ? 'Edit' : 'Add'}}
        </a>
      </div>
    </div>
  </div>
</div>

<ng-template #popContentBidIncrement>
  <div style="width: 250px;">
    The <b>Bid Increment</b> is the amount between the current bid and the next possible bid.
  </div>
</ng-template>

<ng-template #popContentSoftClose>
  <div style="width: 250px;">
    The auction is subject to a “soft {{auctionEntityDto?.antiSnipe}}-minute close”- which means the timing of the auction closing will be delayed by {{auctionEntityDto?.antiSnipe}} minutes in the event someone places a bid in the last {{auctionEntityDto?.antiSnipe}} minutes of bidding. For each additional bid thereafter, the bidding will extend for an additional {{auctionEntityDto?.antiSnipe}} minutes.
  </div>
</ng-template>

<ng-template #maxBidModal>
  <div class="e-popup ee-width-s" id="e-popupmaxbid">
    <div class="e-popupblock ea-popupremove"></div>
    <div class="e-popuppage">
      <div class="e-popupalert ee-success" *ngIf="_showSuccessToast$ | async">{{responseMessage}}</div>
      <div class="e-popupalert ee-error" *ngIf="_showErrorToast$ | async">
        {{responseMessage}}&nbsp;&nbsp;{{responseValue}}</div>
      <div class="e-popupalert ee-error" *ngIf="_showErrorButtonToast$ | async">
        {{responseMessage}}
        <a (click)="updateMaxBidPrice()"
          class="text-white ms-1 text-decoration-underline">{{getFormattedPrice(nextEligibleMaxBidPrice$.value)}}</a>
      </div>
      <header class="e-popupheader" [ngClass]="{'head-top':(_showErrorButtonToast$ | async)}">
        <h1>Max Bid</h1><a class="e-popupclose ea-popupremove" (click)="closeModelFromUserAction()">Close</a>
      </header>
      <div class="e-popupmain">
        <div class="e-spinner" *ngIf="isLoading$ | async"></div>
        <div class="e-maintext e-maintext-m">
          <p>This is where you can set a maximum bid you cannot exceed.</p>
          <form autocomplete="off">
            <div class="e-field ea-valid-number">
              <label>Max Bid</label>
              <div class="e-fieldtext">
                <span class="ee-prefix">$</span>
                <input name="bucketSize" type="text" (keyup)="checkUserInputMaxCurrentBid()" [formControl]="userInputMaxBid" />
              </div>
            </div>
            <div class="e-formsubmit">
              <a class="e-buttontext ee-size-m ee-vis-s ea-popupremove" (click)="closeModelFromUserAction()">Cancel</a>
              <a class="e-buttontext ee-size-m ee-vis-l ea-submit" [ngClass]="{'disabled' : (_disableBidButton$ |async) || userInputMaxBid.invalid}"
                (click)="doMaxBid()">Save</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #placeBidModal>
  <div class="e-popup ee-width-s" id="e-popupconfirmbid">
    <div class="e-popupblock ea-popupremove"></div>
    <div class="e-popuppage">
      <div class="e-popupalert ee-success" *ngIf="_showSuccessToast$ | async">{{responseMessage}}</div>
      <div class="e-popupalert ee-error" *ngIf="_showErrorToast$ | async">{{responseMessage}}</div>
      <div class="e-popupalert ee-error" *ngIf="_showErrorButtonToast$ | async">
        {{responseMessage}}
        <a (click)="updateCurrentBidPrice()" class="text-white ms-1 text-decoration-underline">{{getFormattedPrice(nextEligibleBidPrice$.value)}}</a>
      </div>
      <header class="e-popupheader" [ngClass]="{'head-top':(_showErrorButtonToast$ | async)}">
        <h1>Confirm bid</h1><a class="e-popupclose ea-popupremove" (click)="closeModelFromUserAction()">Close</a>
      </header>
      <div class="e-popupmain">
        <div class="e-spinner" *ngIf="isLoading$ | async"></div>
        <div class="e-maintext e-maintext-m">
          <p>Are you sure you want to place a bid of ${{getFormattedPrice(currentBidPrice)}}?</p>
          <form autocomplete="off">
            <div class="e-formsubmit">
              <a class="e-buttontext ee-size-m ee-vis-s ea-popupremove" (click)="closeModelFromUserAction()">Cancel</a>
              <a class="e-buttontext ee-size-m ee-vis-l ea-submit" [ngClass]="{'disabled' : (_disableBidButton$ |async)}"
                (click)="doBid(currentBidPrice)">Place Bid</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #logoutModal>
  <app-logout-popup-modal></app-logout-popup-modal>
</ng-template>

<ng-template #BidderApproved>
  <div class="e-popup ee-width-m" id="e-popupconfirmbid">
    <div class="e-popupblock ea-popupremove"></div>
    <div class="e-popuppage e-popup-without-header">

      <div class="e-popupmain">
        <div class="e-maintext e-maintext-m">
          <div class="e-alert ee-success">
            <p>Your registration has been approved, you are all set to bid on this property</p>
          </div>
        </div>
      </div>
      <footer class="">
        <a class="e-buttontext ee-size-s new-ok-btn ee-vis-l ea-popupload" (click)="closeModelFromUserAction()">Ok</a>
      </footer>
    </div>
  </div>

</ng-template>

<ng-template #BidderRejected>
  <div class="e-popup ee-width-m" id="e-popupconfirmbid">
    <div class="e-popupblock ea-popupremove"></div>
    <div class="e-popuppage e-popup-without-header">

      <div class="e-popupmain">
        <div class="e-maintext e-maintext-m">
          <div class="e-alert ee-error">
            <p>Your registration is been rejected, feel free to contact us if you have any questions</p>
          </div>
        </div>
      </div>
      <footer class="">
        <a class="e-buttontext ee-size-s new-ok-btn ee-vis-l ea-popupload " (click)="closeModelFromUserAction()">Ok</a>
      </footer>
    </div>
  </div>
</ng-template>

<ng-template #ConflictingOutbid>
  <div class="e-popup ee-width-m" id="e-popupconfirmbid">
    <div class="e-popupblock ea-popupremove"></div>
    <div class="e-popuppage e-popup-without-header">

      <div class="e-popupmain">
        <div class="e-maintext e-maintext-m">
          <div class="e-alert ee-error">
            <p style="margin-top: 0 !important;">
              Another bidder has already placed a max bid with the same amount.
              Please consider increasing your bid. The next eligible bid is
              <a (click)="openBidPlaceModal(placeBidModal)">${{getFormattedPrice(currentBidPrice$ | async)}}</a>
            </p>
          </div>
        </div>
      </div>
      <footer class="">
        <a class="e-buttontext ee-size-s ee-vis-s ea-popupremove me-2" (click)="closeModelFromUserAction()">Cancel</a>
        <a class="e-buttontext ee-size-s ee-vis-l ea-popupload" (click)="openBidPlaceModal(placeBidModal)">Ok</a>
      </footer>
    </div>
  </div>
</ng-template>

<ng-template #bidIncrementViewModal>
  <div class="e-popup ee-width-m" id="e-popupauction">
    <div class="e-popupblock ea-popupremove"></div>
    <div class="e-popuppage">
      <header class="e-popupheader">
        <h1>View Bid Increments</h1>
        <a class="e-popupclose ea-popupremove"  (click)="modalClose()">Close</a>
      </header>
      <div class="e-popupmain">
        <div class="e-maintext e-maintext-m">
          <table class="e-tableinc" data-valid-number="Must be a number"
          data-valid-limitmin="Must be more than previous row" data-valid-limitmax="Must be less than next row"
          data-incmin="1000" data-incmax="10000" data-valid-incmin="Must be no less than $1,000"
          data-valid-incmax="Must be no more than $10,000">
          <thead>
            <tr>
              <th>#</th>
              <th>Up to Amount</th>
              <th>Bid Increment</th>
            </tr>
          </thead>
          <tbody>
            <tr class="ee-inserted" *ngFor="let item of bidIncrementalList; index as i">
              <td><input name="id[]" type="hidden" value=""></td>
              <td>
                <div class="e-field ea-fieldmoney ee-limit">
                  <div class="e-fieldtext">
                    <span class="ee-prefix">$</span>
                    <input name="limit[]" type="text" value="{{item.upToAmount}}" disabled>
                  </div>
                </div>
              </td>
              <td>
                <div class="e-field ea-fieldmoney ee-inc">
                  <div class="e-fieldtext">
                    <span class="ee-prefix">$</span>
                    <input name="inc[]" type="text" value="{{item.incrementValue}}" disabled>
                  </div>
                </div>
              </td>
            </tr>
            <tr class="ee-last">
              <td></td>
              <td>
                <span class="ee-limit">UP TO INFINITY</span>
              </td>
              <td>
                <div class="e-field ea-fieldmoney ee-inc">
                  <div class="e-fieldtext">
                    <span class="ee-prefix">$</span>
                    <input name="last-inc" type="text" value="{{incrementBidValue}}" disabled/>
                  </div>
                </div>
              </td>
              <td></td>
            </tr>
          </tbody>

        </table>
        </div>

      </div>
    </div>
  </div>
</ng-template>
