import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-message-popup',
  templateUrl: './message-popup.component.html',
  styleUrls: ['./message-popup.component.sass']
})
export class MessagePopupComponent implements OnInit {
  @Input() message!: string

  constructor() { }

  ngOnInit(): void {
  }

}
