import { Component } from '@angular/core';

@Component({
  selector: 'app-loading-popup',
  templateUrl: './loading-popup.component.html',
  styleUrls: ['./loading-popup.component.sass']
})
export class LoadingPopupComponent {

}
