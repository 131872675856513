<body class=" doc-content c15"
	[ngStyle]="{'max-width' : isTermsPage ? '100%' : '', 'padding': isTermsPage ? '5%' : ''}">
	<div class="e-popup ee-width-m">
		<div class="e-popupblock ea-popupremove"></div>
		<div class="e-popuppage">

			<header class="e-popupheader">

				<a class="e-popupclose ea-popupremove" (click)="modalClose()">Close
				</a>
			</header>
			<div class="e-popupmain">
				<div class="e-maintext e-maintext-m">
					<p class="c14">
						<span class="c5 c-12">Terms of Use For AuctionWare</span>
					</p>

					<div class="p-3 ">
						<p class="text-center mb-5" #header>
							Click here for <a class="link" (click)="moveTo(bidderBody)">Bidder User Terms</a> | <a class="link"
								(click)="moveTo(sellerBody)">Seller User
								Terms</a>
						</p>
						<div class="terms" #viewContent></div>

						<div #sellerBody></div>
						<app-seller-user-terms-auctionware [isTermsPage]="isTermsPage"
							[content]="sellerContent"></app-seller-user-terms-auctionware>
						<div #bidderBody></div>
						<app-bidder-user-terms-auctionware [isTermsPage]="isTermsPage"
							[content]="bidderContent"></app-bidder-user-terms-auctionware>
						<div class="btn-scroll-topp" [ngClass]="{'btn-scroll-top-terms' : isTermsPage}" (click)="moveTo(header)">
							<a class="e-buttonbullet ee-size-s ei-up scroll-top padding-top-class-terms">
							</a>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</body>