
<body class="c15 doc-content"
	[ngStyle]="{'max-width' : isTermsPage ? '100%' : '', 'padding': isTermsPage ? '5%' : ''}">
	<!-- <header class="e-popupheader" *ngIf="!isTermsPage">
		<a class="e-popupclose ea-popupremove" (click)="modalClose()">Close
		</a>
	</header>
	<p class="c14" #header><span class="c5 c12">Terms of Use For {{headerName}}</span></p>
	<p class="c7 c9"><span class="c4"></span></p>
	<p class="c7 text-center">
		Click here for <a class="link" (click)="moveTo(bidderBody)">Bidder User Terms</a> | <a class="link" (click)="moveTo(sellerBody)">Seller User
			Terms</a>
	</p>

  <div class="terms" #viewContent></div>

	<div #sellerBody></div>
	<app-seller-user-terms [isTermsPage]="isTermsPage" [content]="sellerContent" ></app-seller-user-terms>
	<div #bidderBody></div>
	<app-bidder-user-terms [isTermsPage]="isTermsPage" [content]="bidderContent"></app-bidder-user-terms>
	<div class="btn-scroll-top" [ngClass]="{'btn-scroll-top-terms' : isTermsPage}" (click)="moveTo(header)">
		<a class="e-buttonbullet ee-size-s ei-up scroll-top padding-top-class">
		</a>
	</div> -->
	<div class="e-popup ee-width-m">
		<div class="e-popupblock ea-popupremove"></div>
		<div class="e-popuppage">
			
			<header class="e-popupheader">
			
				<a class="e-popupclose ea-popupremove" (click)="modalClose()">Close
				</a>
			</header>
			<div class="e-popupmain">
				<div class="e-maintext e-maintext-m">
					
				 <div class="p-3 " style="font-size: 18px; font-weight: 400;">
					<p class="text-center"  #header>
						Click here for <a class="link" (click)="moveTo(bidderBody)">Bidder User Terms</a> | <a class="link" (click)="moveTo(sellerBody)">Seller User
							Terms</a>
					</p>
					<div class="terms" #viewContent></div>
	
		<div #sellerBody></div>
		<app-seller-user-terms [isTermsPage]="isTermsPage" [content]="sellerContent" ></app-seller-user-terms>
		<div #bidderBody></div>
		<app-bidder-user-terms [isTermsPage]="isTermsPage" [content]="bidderContent"></app-bidder-user-terms>
		<div class="btn-scroll-topp" [ngClass]="{'btn-scroll-top-terms' : isTermsPage}" (click)="moveTo(header)">
			<a class="e-buttonbullet ee-size-s ei-up scroll-top" [style.margin-left]="isTermsPage ? '0px' : '4px'">
			</a>
		</div>
				 </div>
				 
				</div>
			</div>
		</div>
	</div>
</body>



