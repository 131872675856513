import { Component, ElementRef, Input, OnInit, ViewChildren } from '@angular/core';
import { Observable } from 'rxjs';
import { AuctionEntityDto } from '../../models/AuctionEntityDto';
import { FileInfoDto } from '../../models/FileInfoDto';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.sass']
})
export class CarouselComponent implements OnInit {
  @Input() auctionEntity$?: Observable<AuctionEntityDto|null>;

  @ViewChildren('videoCarousel') videoCarousels?: Array<ElementRef<HTMLVideoElement>>;
  @ViewChildren('iframeCarousel') iframeCarousels?: Array<ElementRef<HTMLIFrameElement>>;
  
  activeImage: number = 0;
  currentPage = 0;
  pageSize = 10;
  maxPages = 0;

  propertyImages: Array<FileInfoDto> = [];

  constructor() {}

  ngOnInit(): void {
    this.auctionEntity$?.subscribe((data) => {
      if (data && data.propertyImages) {
        this.propertyImages = data.propertyImages;
        this.maxPages = Math.ceil(this.propertyImages.length / this.pageSize);
      }
    })
  }

  prevImage() {
    if (this.activeImage !== 0) {
      this.activeImage--;
      this.stopCarouselVideo();
      if (this.activeImage % this.pageSize === this.pageSize - 1 || this.activeImage === this.propertyImages.length - 1) {
        this.currentPage--;
      }
    } else {
      const prevPageIndex = this.currentPage - 1;
      if (prevPageIndex >= 0) {
        this.currentPage--;
      }
    }
  }
  
  
  nextImage() {
    const nextPageIndex = this.currentPage + 1;
    const lastImageIndex = (this.currentPage + 1) * this.pageSize - 1;
    
    if (this.activeImage === lastImageIndex) {
      // If the current active image is the last image on the current page
      // and there's a next page, move to the next page.
      if (nextPageIndex < Math.ceil(this.propertyImages.length / this.pageSize)) {
        this.currentPage++;
        this.activeImage++;
      }
    } else {
      // If the current active image is not the last image on the current page,
      // simply move to the next image.
      if (this.activeImage < (this.propertyImages.length - 1)) {
        this.activeImage++;
      }
    }
  
    this.stopCarouselVideo();
  }
  
  
  
  changeImage(i: number) {
    this.activeImage = i;
    this.stopCarouselVideo();
  }
  
  getPagedImages(): any[] {
    const startIndex = this.currentPage * this.pageSize;
    const endIndex = Math.min(startIndex + this.pageSize, this.propertyImages.length);
    return this.propertyImages.slice(startIndex, endIndex);
  }

  isValidUrl = (urlString: string) => {
    try {
      return Boolean(new URL(urlString));
    }
    catch (e) {
      return false;
    }
  }

  stopCarouselVideo() {
    if (this.videoCarousels && this.videoCarousels.length > 0) {
      this.videoCarousels.forEach((item) => {
        item?.nativeElement.pause();
      })
    }

    if (this.iframeCarousels && this.iframeCarousels.length > 0) {
      this.iframeCarousels.forEach((item) => {
        let element = item.nativeElement;
        if (element) {
          var iframeSrc = element.src;
          element.src = iframeSrc;
        }
      })
    }
  }
}
