import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, catchError, Observable, tap } from "rxjs";
import { AuctionBidsDetailsUIDto } from "../models/AuctionBidsDetailsUIDto";
import { AuctionBidsDto } from "../models/AuctionBidsDto";
import { AuctionEntityDto } from "../models/AuctionEntityDto";
import { CompanyDto } from "../models/CompanyDto";
import { ServerAPIResponseDto } from "../models/ServerAPIResponseDto";
import { ErrorService } from "./error.service";

@Injectable({
  providedIn: 'root'
})
export class SupplierAuctionService {

  companyDto$ = new BehaviorSubject<CompanyDto | null>(null);
  auctionEntityDto$ = new BehaviorSubject<AuctionEntityDto | null>(null);
  auctionBidsDetails$ = new BehaviorSubject<AuctionBidsDetailsUIDto | null>(null);
  auctionBidsDetailsLanding$ = new BehaviorSubject<AuctionBidsDetailsUIDto | null>(null);
  states = [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'District Of Columbia',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming',
  ]


  constructor(
    private httpClient: HttpClient,
    private errorService: ErrorService,
  ) { }

  get getAuctionEntityDto$() { return this.auctionEntityDto$ };
  get getAuctionBidsDetails$() { return this.auctionBidsDetails$ };
  get getAuctionBidsDetailsLanding$() { return this.auctionBidsDetailsLanding$ };


  updateAuctionBidsDetails(auctionBidDetails: AuctionBidsDetailsUIDto){
    /**
     * Make sure the data we are updating of auction bid details is for
     * this domain auction. As server does not distinguish between domain sessions so there could be
     * a possibility that we may get notifications of bids from other domains.
     * */
    if(this.auctionEntityDto$.value && auctionBidDetails.auctionId == this.auctionEntityDto$.value?.auctionId){
      this.auctionBidsDetails$.next(auctionBidDetails);
    }else{
      console.log("Ignoring auction Bids data as current auction is different from notification auction");
      console.log("Bids Events Auction Id :" + auctionBidDetails.auctionId);
      console.log("Current Auction : " + this.auctionEntityDto$.value)
    }
  }

  updateAuctionBidsDetailsLanding(auctionBidDetailsLanding: AuctionBidsDetailsUIDto){
    /**
     * Make sure the data we are updating of auction bid details is for
     * this domain auction. As server does not distinguish between domain sessions so there could be
     * a possibility that we may get notifications of bids from other domains.
     * */
    if(this.auctionEntityDto$.value && auctionBidDetailsLanding.auctionId == this.auctionEntityDto$.value?.auctionId){
      this.auctionBidsDetailsLanding$.next(auctionBidDetailsLanding);
    }else{
      console.log("Ignoring auction Bids data as current auction is different from notification auction");
      console.log("Bids Events Auction Id :" + auctionBidDetailsLanding.auctionId);
      console.log("Current Auction : " + this.auctionEntityDto$.value)
    }
  }

  updateCompanyDto(companyDto: CompanyDto){
    this.companyDto$.next(companyDto);
  }

  updateAuctionEntityDto(auctionEntityDto: AuctionEntityDto){
    this.auctionEntityDto$.next(auctionEntityDto);
  }

  getAuctionBidDetailsInfo(auctionId: string, userId: string): Observable<any> {
    let params = { auctionId: auctionId, userId: userId };

    return this.httpClient.get<ServerAPIResponseDto>('auctionBidInfo', { params: params }).pipe(
      tap(_ => console.log("Auction Bids Info : " + auctionId)),
      catchError(this.errorService.handleError("Error while getting auction bids data : " + auctionId)))
  }

  getAuctionBidDetailsLanding(): Observable<any> {
    return this.httpClient.get<ServerAPIResponseDto>('auctionBidInfoLanding').pipe(
      tap(_ => console.log("Auction Bids Info : Landing")),
      catchError(this.errorService.handleError("Error while getting auction bids data : Landing")))
  }

  doBids(auctionBidsDto: AuctionBidsDto) {
    return this.httpClient.post<ServerAPIResponseDto>('doBids', auctionBidsDto).pipe(
      tap(_ => console.log("Bids Placed" + auctionBidsDto.bidPrice)),
      catchError(this.errorService.handleError<any>("Error while Bids Placed" + auctionBidsDto.bidPrice)));
  }

  doMaxBids(auctionBidsDto: AuctionBidsDto) {
    return this.httpClient.post<ServerAPIResponseDto>('managed-bucketSize', auctionBidsDto).pipe(
      tap(_ => console.log("Max Bids Placed" + auctionBidsDto.bidPrice)),
      catchError(this.errorService.handleError<any>("Error while Max Bids Placed" + auctionBidsDto.bidPrice)));
  }
}
