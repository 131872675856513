<div *ngIf="totalStep == 4">
  <ng-container style="display: contents;"
    *ngTemplateOutlet="currentStep == 2 ? register2 : currentStep == 3 ? register3 : currentStep == 4 ? register5 : register1">
  </ng-container>
</div>

<div *ngIf="totalStep == 5">
  <ng-container style="display: contents;"
    *ngTemplateOutlet="currentStep == 2 ? register2 : currentStep == 3 ? register3 : currentStep == 4 ? register4 : currentStep == 5 ? register5 : register1">
  </ng-container>
</div>

<ng-template #register1>
  <div class="e-popup ee-width-m" id="e-popupregister">
    <div class="e-popupblock ea-popupremove"></div>
    <div class="e-popuppage">
      <div class="e-popupalert ee-error" *ngIf="this.authService.errorMsg">{{authService.errorMsg}}</div>
      <header class="e-popupheader">
        <h1>Register (Step 1 of {{totalStep}})</h1><a class="e-popupclose ea-popupremove" (click)="closeModal()">Close</a>
      </header>
      <div class="e-popupmain">
        <div class="e-maintext e-maintext-m">
          <p>Already registered? Please <a class="ea-popupremove ea-popupload"
              (click)="openModal(loginModal)">login</a>.</p>
          <form [formGroup]="formGroup" autocomplete="off" class="needs-validation" novalidate >
            <div class="e-formrow ee-pad-l">
              <div class="e-formcol em-width-50">
                <div class="e-field ea-valid-required ea-valid-email"
                  [ngClass]="{'ee-error': (_validationErrorPresent$ |async) && formGroup.controls['email'].invalid}">
                  <label>Email</label>
                  <div class="e-fieldtext">
                    <input type="text" id="email" formControlName="email" />
                  </div>
                  <div *ngIf="fc.email.invalid && (fc.email.dirty || fc.email.touched)">
                    <p class="e-fielderror e-fielderror-required" *ngIf="fc.email.errors?.required">Required</p>
                    <p class="e-fielderror e-fielderror-required" *ngIf="fc.email.errors?.pattern">Invalid email address
                    </p>
                    <p class="e-fielderror e-fielderror-required" *ngIf="fc.email.value && fc.email.value.length > 50">Maximum Length
                      Exceed</p>
                  </div>
                </div>
              </div>
              <div class="e-formcol em-width-50">
                <div class="e-field ea-valid-required"
                  [ngClass]="{'ee-error': (_validationErrorPresent$ |async) && formGroup.controls['mobileNo'].invalid}">
                  <label>Mobile Number</label>
                  <div class="e-fieldtext">
                    <input type="text" id="mobileNo" formControlName="mobileNo" required />
                  </div>
                  <!-- <p class="e-fielderror e-fielderror-required" *ngIf="(_validationErrorPresent$ |async) && this.formGroup.controls['mobileNo'].invalid">Required</p> -->
                  <div *ngIf="fc.mobileNo.invalid && (fc.mobileNo.dirty || fc.mobileNo.touched)">
                    <p class="e-fielderror e-fielderror-required" *ngIf="fc.mobileNo.errors?.required">Required
                    </p>
                    <p class="e-fielderror e-fielderror-required" *ngIf="fc.mobileNo.errors?.pattern">Invalid</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="e-formrow ee-pad-l">
              <div class="e-formcol em-width-50">
                <div class="e-field ea-valid-required ea-valid-password"
                  [ngClass]="{'ee-error': (_validationErrorPresent$ |async) && formGroup.controls['password'].invalid}">
                  <label>Password</label>
                  <div class="e-fieldtext">
                    <span class="ee-suffix ee-size-s" [ngClass]="isPasswordVisible ? 'ei-visible' : 'ei-visible-not'" (click)="togglePassword()"></span>
                    <input id="ea-valid-password" name="" [type]="isPasswordVisible ? 'text' : 'password'"
                      formControlName="password" autocomplete="new-password" (keyup)="onChange()" (paste)="onChange()" />
                    </div>
                  <div *ngIf="fc.password.invalid && (fc.password.dirty || fc.password.touched)">
                    <p class="e-fielderror e-fielderror-required" *ngIf="fc.password.invalid && (!fc.password.value)">
                      Required</p>
                    <p class="e-fielderror e-fielderror-required" *ngIf="fc.password.invalid &&  (fc.password.value)">
                      Password must be more than 8 characters, containing at least one capital letter, one lowercase letter, one number and one special character.</p>
                  </div>
                </div>
              </div>
              <div class="e-formcol em-width-50">
                <div class="e-field ea-valid-required ea-valid-password ea-valid-match" data-match="#ea-valid-password"
                  [ngClass]="{'ee-error': (_validationErrorPresent$ |async) && formGroup.controls['repeatPassword'].invalid}">
                  <label>Confirm Password</label>
                  <div class="e-fieldtext">
                    <span class="ee-suffix ee-size-s" [ngClass]="isPasswordVisibleRepeat ? 'ei-visible' : 'ei-visible-not'" (click)="togglePasswordRepeat()"></span>
                    <input name="" formControlName="repeatPassword" [type]="isPasswordVisibleRepeat ? 'text' : 'password'"
                      (keyup)="onChange()" (paste)="onChange()" />
                  </div>
                  <p class="e-fielderror e-fielderror-required" *ngIf="fc.repeatPassword.invalid && (fc.repeatPassword.dirty || fc.repeatPassword.touched)">Required</p>
                  <p class="e-fielderror e-fielderror-required" *ngIf="!fc.repeatPassword.invalid && !(_isPasswordMatch$ | async)">
                    Password Not Match
                  </p>
                </div>
              </div>
            </div>
            <div class="e-formsubmit">
              <a class="e-buttontext ee-size-m ee-vis-s ea-popupremove" (click)="closeModal()">Cancel</a>
              <a class="e-buttontext ee-size-m ee-vis-l ea-popupremove ea-popupload" (click)="next()">Next</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

</ng-template>
<ng-template #register2>
  <div class="e-popup ee-width-m" id="e-popupregister2">
    <div class="e-popupblock ea-popupremove"></div>
    <div class="e-popuppage">
      <div class="e-popupalert ee-error" *ngIf="this.authService.errorMsg">{{authService.errorMsg}}</div>
      <header class="e-popupheader">
        <h1>Register (Step 2 of {{totalStep}})</h1><a class="e-popupclose ea-popupremove" (click)="closeModal()" ngbAutofocus>Close</a>
      </header>
      <div class="e-popupmain">
        <div class="e-maintext e-maintext-m">
          <form [formGroup]="formGroup" autocomplete="off" class="needs-validation" novalidate >
            <div class="e-formrow ee-pad-l">
              <div class="e-formcol em-width-50">
                <div class="e-field ea-valid-required"
                  [ngClass]="{'ee-error': (_validationErrorPresent$ |async) && formGroup.controls['fullName'].invalid}">
                  <label>Your Name</label>
                  <div class="e-fieldtext"><input name="" type="text" formControlName="fullName" /></div>
                  <p class="e-fielderror e-fielderror-required"
                    *ngIf="(_validationErrorPresent$ |async) && this.formGroup.controls['fullName'].invalid">Required
                  </p>
                </div>
              </div>
              <div class="e-formcol em-width-50">
                <div class="e-field ea-valid-required"
                  [ngClass]="{'ee-error': (_validationErrorPresent$ |async) && formGroup.controls['officeName'].invalid}">
                  <label>Company Name</label>
                  <div class="e-fieldtext"><input name="" type="text" formControlName="officeName" /></div>
                  <!-- <p class="e-fielderror e-fielderror-required" *ngIf="(_validationErrorPresent$ |async) && this.formGroup.controls['officeNumber'].invalid">Required</p> -->

                </div>
              </div>
            </div>
            <div class="e-formrow ee-pad-l">
              <div class="e-formcol em-width-50">
                <div class="e-field ea-valid-required"
                  [ngClass]="{'ee-error': (_validationErrorPresent$ |async) && formGroup.controls['officeNumber'].invalid}">
                  <label>Office Number</label>
                  <div class="e-fieldtext"><input name="" type="text" formControlName="officeNumber" /></div>
                  <!-- <p class="e-fielderror e-fielderror-required" *ngIf="(_validationErrorPresent$ |async) && this.formGroup.controls['officeNumber'].invalid">Required</p> -->
                  <div
                    *ngIf="fc.officeNumber.invalid && (fc.officeNumber.dirty || fc.officeNumber.touched) || (_validationErrorPresent$ |async)">
                    <!-- <p class="e-fielderror e-fielderror-required" *ngIf="fc.officeNumber.errors?.required">Required</p> -->
                    <p class="e-fielderror e-fielderror-required" *ngIf="fc.officeNumber.errors?.pattern">Invalid</p>
                  </div>
                </div>
              </div>
              <div class="e-formcol em-width-50">
                <div class="e-field ea-valid-required"
                  [ngClass]="{'ee-error': (_validationErrorPresent$ |async) && formGroup.controls['addressLine1'].invalid}">
                  <label>Address Line 1</label>
                  <div class="e-fieldtext"><input name="" type="text" formControlName="addressLine1" /></div>
                  <p class="e-fielderror e-fielderror-required"
                    *ngIf="(_validationErrorPresent$ |async) && this.formGroup.controls['addressLine1'].invalid">
                    Required</p>
                </div>
              </div>

            </div>
            <div class="e-formrow ee-pad-l">
              <div class="e-formcol em-width-50">
                <div class="e-field"><label>Address Line 2</label>
                  <div class="e-fieldtext"><input name="" type="text" formControlName="addressLine2" /></div>
                </div>
              </div>
              <div class="e-formcol em-width-50">
                <div class="e-field ea-valid-required"
                  [ngClass]="{'ee-error': (_validationErrorPresent$ |async) && formGroup.controls['city'].invalid}">
                  <label>City</label>
                  <div class="e-fieldtext"><input name="" type="text" formControlName="city" /></div>
                  <p class="e-fielderror e-fielderror-required"
                    *ngIf="(_validationErrorPresent$ |async) && this.formGroup.controls['city'].invalid">Required</p>
                </div>
              </div>

            </div>
            <div class="e-formrow ee-pad-l">
              <div class="e-formcol em-width-50">
                <div class="e-field ea-valid-required"
                  [ngClass]="{'ee-error': (_validationErrorPresent$ |async) && formGroup.controls['state'].invalid}">
                  <label>State</label>
                  <div class="e-fieldselect">
                    <select formControlName="state">
                      <option value="" selected>Select . . .</option>
                      <option *ngFor="let state of supplierService.states" [value]="state">{{state}}</option>
                    </select>
                  </div>
                  <p class="e-fielderror e-fielderror-required"
                    *ngIf="(_validationErrorPresent$ |async) && this.formGroup.controls['state'].invalid">Required</p>
                </div>
              </div>
              <div class="e-formcol em-width-50">
                <div class="e-field ea-valid-required"
                  [ngClass]="{'ee-error': (_validationErrorPresent$ |async) && formGroup.controls['zipCode'].invalid}">
                  <label>Zip Code</label>
                  <div class="e-fieldtext"><input name="" type="text" formControlName="zipCode" (keyup)="checkValidValue()"/></div>
                  <p class="e-fielderror e-fielderror-required"
                    *ngIf="(_validationErrorPresent$ |async) && this.formGroup.controls['zipCode'].invalid">Required</p>
                  <p class="e-fielderror e-fielderror-required" *ngIf="fc.zipCode.errors?.maxlength">Invalid Zip Code</p>
                  <p class="e-fielderror e-fielderror-required" *ngIf="fc.zipCode.errors?.minlength">Invalid Zip Code</p>
                </div>
              </div>
            </div>
            <div class="e-formsubmit">
              <a class="e-buttontext ee-size-m ee-vis-s ea-popupremove" (click)="closeModal()">Cancel</a>
              <a class="e-buttontext ee-size-m ee-vis-l ea-popupremove ea-popupload" (click)="next()">Next</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #register3>
  <div class="e-popup ee-width-m" id="e-popupregister3">
    <div class="e-popupblock ea-popupremove"></div>
    <div class="e-popuppage">
      <div class="e-popupalert ee-error" *ngIf="this.authService.errorMsg">{{authService.errorMsg}}</div>
      <header class="e-popupheader">
        <h1>Register (Step 3 of {{totalStep}})</h1><a class="e-popupclose ea-popupremove" (click)="closeModal()">Close</a>
      </header>
      <div class="e-popupmain">
        <div class="e-maintext e-maintext-m">
          <p>Before you can bid you must accept the terms and conditions. Please Read them carefully before accepting.
          </p>
          <div class="e-croptext ee-size-s" [ngClass]="{'ee-active' : show }">
            <div class="ee-outer" [ngClass]="{'show-more' : show }">
              <div class="ee-inner">
                <h1>TERMS &amp; CONDITIONS</h1>
                <p #termsConditions></p>
              </div>
            </div>
            <a (click)="toggleShowMore()"> {{ show ? 'Hide More': 'Show More' }}</a>
          </div>
          <form [formGroup]="formGroup" autocomplete="off" class="needs-validation" novalidate >
            <div class="row align-items-center">
              <div class="col-auto e-field ea-valid-checked pe-1">
                <div class="e-fieldcheck ea-fieldcheck" (click)="changeTerms()">
                  <input name="agreeToTerms" type="checkbox" formControlName="agreeToTerms"/>
                  <label>I accept the Terms &amp; Conditions
                  </label>
                </div>
                <p class="e-fielderror e-fielderror-checked"
                  *ngIf="(_validationErrorPresent$ |async) &&
                  (this.formGroup.controls['agreeToTerms'].value == false || this.formGroup.controls['agreeToTerms'].value == null)">
                  Required</p>
              </div>
              <div class="col-auto p-0">
                <p><a class="btn-terms" (click)="openTermsOfUseModal()"> & Terms of Use</a></p>
              </div>
            </div>
            <div class="e-formsubmit">
              <a class="e-buttontext ee-size-m ee-vis-s ea-popupremove"  (click)="closeModal()">Cancel</a>
              <a class="e-buttontext ee-size-m ee-vis-l ea-popupremove ea-popupload"  (click)="next()">Accept Terms &amp;
                Conditions</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #register4>
  <div class="e-popup ee-width-m" id="e-popupregister4">
    <div class="e-popupblock ea-popupremove"></div>
    <div class="e-popuppage">
      <div class="e-popupalert ee-error" *ngIf="this.authService.errorMsg">{{authService.errorMsg}}</div>
      <header class="e-popupheader">
        <h1>Register (Step 4 of {{totalStep}})</h1><a class="e-popupclose ea-popupremove" (click)="closeModal()">Close</a>
      </header>
      <div class="e-popupmain">
        <div class="e-maintext e-maintext-m">
          <p>Before you can bid you must read and agree to the confidentiality agreement. Please read it carefully
            before accepting.</p>
          <div class="e-croptext ee-size-s" [ngClass]="{'ee-active' : show }">
            <div class="ee-outer" [ngClass]="{'show-more' : show }">
              <div class="ee-inner">
                <h1>CONFIDENTIALITY AGREEMENT</h1>
                <p #confidentialAgreement></p>
              </div>
            </div>
            <a (click)="toggleShowMore()"> {{ show ? 'Hide More': 'Show More' }}</a>
          </div>
          <form [formGroup]="formGroup" autocomplete="off" class="needs-validation" novalidate (keyup.enter)="next()">
            <div class="e-field ea-valid-checked"
              [ngClass]="{'ee-error': (_validationErrorPresent$ |async) && formGroup.controls['agreeToConfidentialityTerms'].invalid}">
              <div class="e-fieldcheck ea-fieldcheck" (click)="changeConfidentialityTerms()">
                <input name="agreeToConfidentialityTerms" type="checkbox"
                  formControlName="agreeToConfidentialityTerms" />
                <label>I agree to the Confidentiality Agreement</label>
              </div>
              <p class="e-fielderror e-fielderror-checked"
                *ngIf="(_validationErrorPresent$ |async) &&
                (this.formGroup.controls['agreeToConfidentialityTerms'].value == false || this.formGroup.controls['agreeToConfidentialityTerms'].value == null)">
                Required</p>
            </div>
            <div class="e-field ea-valid-required"
              [ngClass]="{'ee-error': (_validationErrorPresent$ |async) && formGroup.controls['signatureName'].invalid}">
              <label>Enter your name to signify your agreement of the
                confidentiality agreement</label>
              <div class="e-fieldtext"><input name="" placeholder="Enter your name " type="text"
                  formControlName="signatureName" /></div>
              <p class="e-fielderror e-fielderror-required"
                *ngIf="(_validationErrorPresent$ |async) && this.formGroup.controls['signatureName'].invalid">Required
              </p>
            </div>
            <div class="e-formsubmit">
              <a class="e-buttontext ee-size-m ee-vis-s ea-popupremove" (click)="closeModal()">Cancel</a>
              <a class="e-buttontext ee-size-m ee-vis-l ea-submit" [ngClass]="{'disabled':_isSaveButtonDisable$ | async}"
                (click)="next()">Accept &amp; Register</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #register5>
  <div class="e-popup ee-width-s" id="e-popupregister">
    <div class="e-popupblock ea-popupremove"></div>
    <div class="e-popuppage">
      <div class="e-popupalert ee-error" *ngIf="this.authService.errorMsg">{{authService.errorMsg}}</div>
      <div class="e-popupalert ee-success" *ngIf="_showSuccessMsg$ | async">{{_successMsg$ | async}}</div>
      <header class="e-popupheader">
        <h1>Register (Step {{totalStep}} of {{totalStep}})</h1><a class="e-popupclose ea-popupremove" (click)="closeModal()">Close</a>
      </header>
      <div class="e-popupmain">
        <div class="e-maintext e-maintext-m">
          <p>Enter the One Time Password (OTP) which was just sent to the email address and phone number you registered with. Be sure to check your spam folder.</p>
          <form [formGroup]="formGroup" autocomplete="off" class="needs-validation" novalidate (keyup.enter)="handleValidSubmit()">
            <div class="e-formrow ee-pad-l">
              <div class="e-formcol em-width-100">
                <div class="e-field ea-valid-required ea-valid-otp"
                  [ngClass]="{'ee-error': (_validationErrorPresent$ |async) && formGroup.controls['otp'].invalid}">
                  <label>OTP</label>
                  <div class="e-fieldtext">
                    <input type="text" id="otp" formControlName="otp" />
                  </div>
                  <div *ngIf="fc.otp.invalid && (fc.otp.dirty || fc.otp.touched)">
                    <p class="e-fielderror e-fielderror-required" *ngIf="fc.otp.errors?.required">Required</p>
                  </div>
                </div>
              </div>
              </div>
            <div class="e-formsubmit">
              <a class="e-buttontext ee-size-m ee-vis-s ea-popupremove" (click)="closeModal()">Cancel</a>
              <a class="e-buttontext ee-size-m ee-vis-l ea-popupremove ea-popupload" (click)="handleValidSubmit()">Verify</a>
            </div>
          </form>
          <p>
            <a class="ea-popupremove ea-popupload" style="margin-right: 100px;" (click)="resendOTP()">Resend OTP ?</a>
            <a class="ea-popupremove ea-popupload" style="cursor: pointer; " (click)="skipOTPVerification()">Verify Later</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #loginModal>
  <app-login></app-login>
</ng-template>
<app-loader class="mt-4" #appLoader></app-loader>
