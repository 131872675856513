<header id="e-header">
  <nav id="e-headernav">
    <a id="e-headertitle" *ngIf="_companyDto$ | async" href="{{companyDto?.companyUrl}}" target="_blank" >
      <img class="" alt="{{companyDto?.companyName}}" title="{{companyDto?.companyName}}" style="height: 100%; object-fit: contain;"
      src="/downloadLandingBlob?fileId={{companyDto?.logo?.fileId!}}" /></a>
    <div class="e-buttonrow" id="e-headerlogin" *ngIf="!userService.getUserEntity()">
      <a class="ea-popupload" *ngIf="(userService.getLoginContextSensitivity$ | async)"
        (click)="openModal(loginModal)">Login</a>
      <a class="ea-popupload" *ngIf="(userService.getLoginContextSensitivity$ | async)"
        (click)="openModal(registrationModal)">Register</a>
    </div>
    <div *ngIf="userService.getUserEntity()" ngbDropdown #dropdown="ngbDropdown">
      <a ngbDropdownToggle class="ea-menushow<?php if ($error == 'terms') { echo ' ee-alert'; } ?>"
        id="e-headeruser"><span class="ee-icon">MK</span><span class="ee-text"><small>Logged in as</small>
          <strong>{{userService.getUserEntity().name}}</strong></span></a>
      <ul class="e-headermenu" id="e-headermenu-user" ngbDropdownMenu>
        <li (click)="openModal(myDetails)"><a class="ei-list ea-popupload ea-menuhide" data-post="101">My Details</a>
        </li>
        <!-- <li><a class="ei-file ea-popupload ea-menuhide<?php if ($error == 'terms') { echo ' ee-alert'; } ?>"
            data-file="popup-terms.html" data-id="#e-popupterms" data-post="101">Accept T &amp; Cs</a></li> -->
        <li (click)="openModal(changePassword)"><a class="ei-lock ea-popupload ea-menuhide" data-post="101">Change
            Password</a></li>
        <li (click)="logout()"><a class="ei-enter">Logout</a></li>
      </ul>
    </div>
    <div ngbDropdown #menuDropdown="ngbDropdown">
      <a class="ea-menushow" id="e-headerhamburger" ngbDropdownToggle
        [ngClass]="{'ee-active' : menuDropdown.isOpen()}"><span class="ee-icon"><span></span></span>Menu</a>
      <ul class="e-headermenu" id="e-headermenu-hamburger" ngbDropdownMenu>
        <li>
          <a class="ei-image ea-popupload ea-menuhide" data-file="popup-gallery.html" data-id="#e-popupgallery"
            data-post="" (click)="openGalleryModal()">Gallery</a>
        </li>
        <li *ngIf="auctionEntityDto?.showAddress">
          <a class="ei-location" (click)="openGoogleMap('map')"
            [ngClass]="{'disabled': !auctionEntityDto?.latitude}">Map View</a>
        </li>
        <li *ngIf="auctionEntityDto?.showAddress">
          <a class="ei-street" (click)="openGoogleMap('street-view')"
            [ngClass]="{'disabled': !auctionEntityDto?.latitude}">Street View</a>
        </li>
        <li *ngIf="isAdminUserLoggedIn()">
          <a class="ei-grid ea-popupload ea-menuhide"
            data-post="" (click)="redirectToAdminDashbord()">Auction Dashboard</a>
        </li>
        <li *ngIf="!userService.getUserEntity()">
          <a class="ei-enter ea-popupload ea-menuhide" (click)="openModal(loginModal)">Login</a>
          <a class="ei-enter ea-popupload ea-menuhide" (click)="openModal(registrationModal)">Register</a>
        </li>
      </ul>
    </div>
  </nav>
</header>

<ng-template #registrationModal>
  <app-registration></app-registration>
</ng-template>

<ng-template #loginModal>
  <app-login></app-login>
</ng-template>

<ng-template #changePassword>
  <app-change-password></app-change-password>
</ng-template>

<ng-template #myDetails>
  <app-my-details></app-my-details>
</ng-template>

<app-loader #appLoader></app-loader>
