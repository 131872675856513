import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BasicAuthHtppInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler) {

    let auctionSessionId = localStorage.getItem('AUC_SESSION_ID');

    if (auctionSessionId) {

      request = request.clone({
        headers: new HttpHeaders().set('Authorization', "JSESSIONID=" + auctionSessionId).
                                  set("Cache-Control", 'no-cache').
                                  set("Cache-control", 'no-store').
                                  set("Pragma", 'no-cache').
                                  set("Expites", '0')
      })

    }


    // req = req.clone({
    //   headers: {
    //     Authorization: auctionSessionId,
    //     'AUC_DOMAIN_NAME': sessionStorage.getItem('AUC_DOMAIN_NAME'),
    //     'Cache-Control': 'no-cache',
    //     'Cache-control': 'no-store',
    //     'Pragma': 'no-cache',
    //     'Expires': '0',
    //   }
    // })


    return next.handle(request);

  }
}
