import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, debounceTime } from 'rxjs';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { UserEntityDto } from 'src/app/shared/models/UserEntityDto';
import { AucProBidTosterService } from 'src/app/shared/services/common/Aucprobid-toaster-service';
import { NotificationService } from 'src/app/shared/services/notification-service';
import { UserService } from 'src/app/shared/services/user.service';
import { AuctionwareConstants } from 'src/app/shared/util/AuctionwareConstants';
import { AuctionwareUtil } from 'src/app/shared/util/AuctionwareUtil';
import { Pattern } from 'src/app/shared/util/Patterns';

@Component({
  selector: 'app-my-details',
  templateUrl: './my-details.component.html',
  styleUrls: ['./my-details.component.sass']
})
export class MyDetailsComponent implements OnInit {

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  errorMsg?: string;
  isLoading: boolean = false;
  isEnabledSaveButton$ = new BehaviorSubject<boolean>(false);

  formGroup = this.formBuilder.group({
    applicantName: new FormControl('', Validators.required),
    mobileNumber: new FormControl('', [Validators.required, Validators.pattern(Pattern.mobile)]),
    officeName: new FormControl(''),
    officeNumber: new FormControl('', [Validators.pattern(Pattern.mobile)]),
    emailId: new FormControl({value: '', disabled: true }),
    addressLine1: new FormControl('', Validators.required),
    addressLine2: new FormControl(''),
    city: new FormControl('', Validators.required),
    state: new FormControl('', Validators.required),
    zipCode: new FormControl('', [Validators.required, Validators.maxLength(5), Validators.minLength(5)]),
    paddleNo: new FormControl({value :'', disabled: true}),
    signatureName: new FormControl({value: '', disabled: true }),
    registrationTime: new FormControl({value: '', disabled: true })
  })

  constructor(private formBuilder: FormBuilder, private modalService: NgbModal,
    public userService: UserService, private notificationService: NotificationService,
    private toasterService: AucProBidTosterService) { }

  ngOnInit() {
    this.formGroup.valueChanges.pipe(debounceTime(400)).subscribe(() => {
      if (this.formGroup.invalid) {
        this.isEnabledSaveButton$.next(false);
      } else {
        this.isEnabledSaveButton$.next(true);
      }
    });

    this.userService.getUserEntity$.subscribe((data) => {
      if (data) {
        this.populateFormGroup(data);
      }
    })
  }

  get fc(): any { return this.formGroup.controls; }

  populateFormGroup(userEntityDto: UserEntityDto) {
    this.formGroup.controls['applicantName'].setValue(userEntityDto.name ?? '');
    this.formGroup.controls['signatureName'].setValue(userEntityDto.signatureName ?? '');
    this.formGroup.controls['mobileNumber'].setValue(userEntityDto.mobileNo ?? '');
    this.formGroup.controls['officeName'].setValue(userEntityDto.officeName ?? '');
    this.formGroup.controls['officeNumber'].setValue(userEntityDto.officeNo ?? '');
    this.formGroup.controls['emailId'].setValue(userEntityDto.emailId ?? '');
    this.formGroup.controls['addressLine1'].setValue(userEntityDto.addressLine1 ?? '');
    this.formGroup.controls['addressLine2'].setValue(userEntityDto.addressLine2 ? userEntityDto.addressLine2 : '');
    this.formGroup.controls['city'].setValue(userEntityDto.city ?? '');
    this.formGroup.controls['state'].setValue(userEntityDto.state ?? '');
    this.formGroup.controls['zipCode'].setValue(userEntityDto.zipCode ?? '');
    this.formGroup.controls['paddleNo'].setValue(userEntityDto.paddleNo ?? '');
    this.formGroup.controls['registrationTime'].setValue(userEntityDto.registrationTime ?? '');
    this.formGroup.updateValueAndValidity();
  }

  populateUserEntityData() {
    let userEntityDto = this.userService.getUserEntity();
    userEntityDto.name = this.formGroup.controls['applicantName'].value!;
    userEntityDto.signatureName = this.formGroup.controls['signatureName'].value!;
    userEntityDto.mobileNo = this.formGroup.controls['mobileNumber'].value!;
    userEntityDto.officeName = this.formGroup.controls['officeName'].value!;
    userEntityDto.officeNo = this.formGroup.controls['officeNumber'].value!;
    userEntityDto.emailId = this.formGroup.controls['emailId'].value!;
    userEntityDto.addressLine1 = this.formGroup.controls['addressLine1'].value!;
    userEntityDto.addressLine2 = this.formGroup.controls['addressLine2'].value ? this.formGroup.controls['addressLine2'].value : '';
    userEntityDto.city = this.formGroup.controls['city'].value!;
    userEntityDto.state = this.formGroup.controls['state'].value!;
    userEntityDto.zipCode = this.formGroup.controls['zipCode'].value!;

    return userEntityDto;
  }

  handleValidSubmit() {
    if (this.formGroup.invalid) {
      return;
    }

    this.isLoading = true;
    let userEntityDto = this.populateUserEntityData();

    this.userService.updateUserDetails(userEntityDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == AuctionwareConstants.SUCCESS_CODE) {
          let data = apiResponseDto.data as UserEntityDto;
          this.userService.setUserDto(data);
          this.isLoading = false;
          this._showSuccessToast$.next(true);
          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal();
            this.toasterService.openSuccessToster("Details Updated Successfully");
          }, 2000)
        } else {
          this.isLoading = false;
          this.errorMsg = apiResponseDto.message;
          this._showErrorToast$.next(true);
          setTimeout(() => {
            this._showErrorToast$.next(false);
          }, 2000)
        }
      },
      error: (err) => {
        this.isLoading = false;
        this.errorMsg = "Error while updating details";
        this._showErrorToast$.next(true);
        setTimeout(() => {
          this._showErrorToast$.next(false);
        }, 2000);
        this.toasterService.openErrorToster("Error while updating details");
      }
    })
  }

  checkValidValue() {
    let value = this.formGroup.controls['zipCode'].value as string;
    this.formGroup.controls['zipCode'].setValue(AuctionwareUtil.onlyNumbers(value));
  }

  closeModal() {
    this.modalService.dismissAll();
  }

}
