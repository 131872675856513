import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, tap } from 'rxjs';
import { UserAuctionEligibleStatus } from '../models/auction/userAuction/UserAuctionEligibleStatus';
import { RegistrationUserModelDto } from '../models/RegistrationUserModelDto';
import { ServerAPIResponseDto } from '../models/ServerAPIResponseDto';
import { SessionInfoDto } from '../models/SessionInfoDto';
import { ChangePasswordDto } from '../models/user/ChangePasswordDto';
import { ForgotPasswordDto } from '../models/user/ForgotPasswordDto';
import { UserEntityDto } from '../models/UserEntityDto';
import { AuctionwareUtil } from '../util/AuctionwareUtil';
import { ErrorService } from './error.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private userEntity?: UserEntityDto;
  private _userEntity$ = new BehaviorSubject<UserEntityDto | null>(null);
  private sessionInfoDto?: SessionInfoDto;
  private _sessionExpired$ : Observable<boolean>;

  _showLoginRegistrationButton$ = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient, private errorService: ErrorService) {
      this.checkLoginButtonContextSensitivity();
      this._sessionExpired$ = this.errorService.getSessionExpired$;
      this._sessionExpired$.subscribe(data => {
        if(data && data == true){
          this.clear();
        }
      })
  }

  checkLoginButtonContextSensitivity(){

    let auctionSessionId = localStorage.getItem('AUC_SESSION_ID');
    if(auctionSessionId){
      this._showLoginRegistrationButton$.next(false);
    }else{
      this._showLoginRegistrationButton$.next(true);
    }

  }

  get getUserEntity$() { return this._userEntity$.asObservable(); }
  get getLoginContextSensitivity$(){ return this._showLoginRegistrationButton$.asObservable(); }

  public getUserEntity(): UserEntityDto {
    return this.userEntity!;
  }

  setUserDto(userEntityDto: UserEntityDto) {
    this.userEntity = userEntityDto;
    this._userEntity$.next(this.userEntity);
  }

  // Update User Registration Status
  updateRegistrationStatus(newStatus: string) {
    if (this.userEntity) {
      if (newStatus && newStatus != this.userEntity.registrationStatus) {
        this.userEntity.registrationStatus = newStatus as UserAuctionEligibleStatus;
        this._userEntity$.next(this.userEntity);
      }
    }
  }

  isDemoAccount(){
    return this.sessionInfoDto!.demoAccount;
  }

  setDemoAccount(demoAccount: boolean){
    this.sessionInfoDto!.demoAccount = demoAccount;
  }

  // Set the user to this service after authentication
  public setUser(sessionInfo: SessionInfoDto): void {
    this.userEntity = sessionInfo.userEntityDto;
    this._userEntity$.next(this.userEntity!);
    this.sessionInfoDto = sessionInfo;
    this._showLoginRegistrationButton$.next(false);
  }

  registerUsers(registrationUserModalDto: RegistrationUserModelDto, emailId: string, password: string): Observable<any> {
    const headers = new HttpHeaders({ Authorization: 'Basic ' + window.btoa(encodeURIComponent(emailId!) + ':' + password) });

    return this.http.post<ServerAPIResponseDto>('registerUsers', registrationUserModalDto, {headers}).pipe(
      tap(_ => console.log("User Registered Successfully : " + registrationUserModalDto.name)),
      catchError(this.errorService.handleError("Error while user registration : " + registrationUserModalDto.name)))
  }

  generateOTP(emailId: string, name: string, mobileNo: string, workflowName: string): Observable<any> {
    let params = new HttpParams().set('emailId', emailId!).set('name', name).set('mobileNo', mobileNo).set('workFlowName', workflowName);
    return this.http.get<ServerAPIResponseDto>('OTPS', { params }).pipe(
      tap(_ => console.log("OTP Generate Successfully : " + emailId)),
      catchError(this.errorService.handleError("Error while generate : " + emailId)))
  }

  resendOTP(emailId: string, name: string, mobileNo: string, otpId:string): Observable<any> {
    let params = new HttpParams().set('emailId', emailId!).set('name', name!).set('mobileNo', mobileNo).set("otpId",otpId);
    return this.http.get<ServerAPIResponseDto>('resendOTPS', { params }).pipe(
      tap(_ => console.log("Resend OTP Generate Successfully : " + emailId)),
      catchError(this.errorService.handleError("Error while generate : " + emailId)))
  }

  verifyOTP(otpId:string, userEnteredOtp: string): Observable<any> {
    let params = new HttpParams().set("otpId",otpId).set('userEnteredOtp', userEnteredOtp!);
    return this.http.get<ServerAPIResponseDto>('verifyEmail', { params }).pipe(
      tap(_ => console.log("OTP Verification Successfully : " + otpId)),
      catchError(this.errorService.handleError("Error while verification OTP : " + otpId)))
  }

  logout(email?: string): Observable<ServerAPIResponseDto> {
    let params = new HttpParams().set('emailId', email!);
    return this.http.get<any>('logout', { params }).pipe(
      tap(_ => console.log("logout succesfully")),
      catchError(this.errorService.handleError<any>('Error while doing logout')))
  }

  changePassword(changePasswordDto: ChangePasswordDto): Observable<any> {
    return this.http.post<ServerAPIResponseDto>('changePassword', changePasswordDto).pipe(
      tap(_ => console.log("Password Reset Successfully for User : " + changePasswordDto.userName)),
      catchError(this.errorService.handleError("Error while resetting password for user : " + changePasswordDto.userName)))
  }

  updateUserDetails(userEntityDto: UserEntityDto): Observable<any> {
    return this.http.post<ServerAPIResponseDto>('users', userEntityDto).pipe(
      tap(_ => console.log("Update Details Successfully for User : " + userEntityDto.emailId)),
      catchError(this.errorService.handleError("Error while updating details for user : " + userEntityDto.emailId)))
  }

  forgotPassword(forgotPasswordDto: ForgotPasswordDto, email: string, password: string): Observable<any> {
    let headers = new HttpHeaders({ Authorization: 'Basic ' + window.btoa(encodeURIComponent(email) + ':' + password) });

    return this.http.post<ServerAPIResponseDto>('forgotPassword', forgotPasswordDto, { headers}).pipe(
      tap(_ => console.log("Forgot password successfully for User : " + email)),
      catchError(this.errorService.handleError("Error while forgot password for user : " + email)))
  }

  getUserDetailsWithSesssion(sessionId: string) {
    const headers = new HttpHeaders().set('Authorization', "JSESSIONID=" +sessionId).
      set("Cache-Control", 'no-cache').
      set("Cache-control", 'no-store').
      set("Pragma", 'no-cache').
      set("Expites", '0');
    return this.http.get<string>('userDetails', { headers: headers }).pipe(
      tap(_ => console.log("got data for user by sessionId")),
      catchError(this.errorService.handleError<any>('Error while getting data for user by sessionId' + sessionId)))
  }

  clear() {
    this.sessionInfoDto = null!;
    this.userEntity = null!;
    this._userEntity$.next(this.userEntity!);
    this._showLoginRegistrationButton$.next(true);
  }

}


