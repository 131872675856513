import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LandingDashboardService } from '../../services/landing-auction-service';

@Component({
  selector: 'app-terms-of-use-for-auction-auctionware',
  templateUrl: './terms-of-use-for-auction-auctionware.component.html',
  styleUrls: ['./terms-of-use-for-auction-auctionware.component.sass'],
  encapsulation: ViewEncapsulation.ShadowDom,

})
export class TermsOfUseForAuctionAuctionwareComponent implements OnInit {

  isTermsPage: boolean = false;
  @Input() content?: string;
  @Input() sellerContent?: string;
  @Input() bidderContent?: string;
  @Input() headerName?: string;

  @ViewChild('viewContent') viewContent?: ElementRef

  constructor(
    public landingDashboardService: LandingDashboardService,
    public modalService: NgbModal,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    let urls = this.activatedRoute.snapshot.url;
    if (urls && urls.length > 0) {
      this.isTermsPage = urls[0].path == 'terms-of-use';

    }
   
  }

  ngAfterViewInit(): void {
    if (this.content) {
      this.viewContent!.nativeElement.innerHTML = this.content!;
    }
    
  }



  modalClose(){
    this.modalService.dismissAll()
  }

  moveTo(el: HTMLElement) {
    el.scrollIntoView();
  }


}
