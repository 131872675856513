import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LandingDashboardService } from '../../services/landing-auction-service';


@Component({
  selector: 'app-terms-of-use-for-auction',
  templateUrl: './terms-of-use-for-auction.component.html',
  styleUrls: ['./terms-of-use-for-auction.component.sass'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class TermsOfUseForAuctionComponent implements OnInit, AfterViewInit {
  @Input() isTermsPage: boolean = false;
  @Input() content?: string;
  @Input() sellerContent?: string;
  @Input() bidderContent?: string;
  @Input() headerName?: string;

  @ViewChild('viewContent') viewContent?: ElementRef

  constructor(
    public landingDashboardService: LandingDashboardService,
    public modalService: NgbModal,
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if (this.content) {
      this.viewContent!.nativeElement.innerHTML = this.content!;
    }
  }


  modalClose() {
    if (this.isTermsPage) {
      this.activeModal.close();
    } else {
      this.modalService.dismissAll()
    }
  }

  moveTo(el: HTMLElement) {
    el.scrollIntoView();
  }
}
