import { Injectable } from '@angular/core';
import {
  Firestore,
  doc,
  onSnapshot, query, collection} from "@angular/fire/firestore";
import { AuctionBidsDetailsUIDto } from '../models/AuctionBidsDetailsUIDto';
import { AuctionEntityDto } from '../models/AuctionEntityDto';
import { AuctionwareUtil } from '../util/AuctionwareUtil';
import { AdminAuctionService } from './admin-auction.service';
import { SupplierAuctionService } from './supplier-auction-service';
import { UserService } from './user.service';
import { where } from 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class FirestoreListenerService {
  collectionCompany: string = "COMPANY_AUCTIONWARE";
  collectionAuction: string = "AUCTION_AUCTIONWARE";
  collectionBidsDetails: string = "AUCTION_BIDS_DETAILS_AUCTIONWARE";
  collectionBidsDetailsLanding: string = "AUCTION_BIDS_DETAILS_LANDING_AUCTIONWARE";


  constructor(
    private firestore: Firestore,
    private userService: UserService,
    private supplierAuctionService: SupplierAuctionService,
    private adminAuctionService: AdminAuctionService
  ) { }

  initializeListener() {
    this.companyListener();
    this.auctionListener();
    this.auctionLandingBidsDetailsListener();
  }

  companyListener() {
    let domains  = [];
    domains.push(AuctionwareUtil.getFullDomainName());

    if(isUsePartSubdomain()){
      domains.push(AuctionwareUtil.getPartSubDomainName());
    }
    let queryCompany = query(collection(this.firestore, this.collectionCompany), where('domainName', 'in', domains))

    onSnapshot(queryCompany, (querySnapshot) => {
      //console.log("company data: ", snapshot.data());
      let snapshot = querySnapshot.docs[0]
      if (snapshot &&  snapshot.exists()) {
        this.supplierAuctionService.updateCompanyDto(snapshot.data());
        this.adminAuctionService.updateCompanyDto(snapshot.data());
      }
    });
  }

  auctionListener() {
    let domains  = [];
    domains.push(AuctionwareUtil.getFullDomainName());

    if(isUsePartSubdomain()){
      domains.push(AuctionwareUtil.getPartSubDomainName());
    }
    let queryAuction = query(collection(this.firestore, this.collectionAuction), where('domainName', 'in', domains))

    onSnapshot(queryAuction, (querySnapshot) => {
      //console.log("auction data: ", snapshot.data());
      let snapshot = querySnapshot.docs[0]
      if (snapshot && snapshot.exists()) {
        this.supplierAuctionService.updateAuctionEntityDto(snapshot.data());
        this.adminAuctionService.updateAuctionEntityDto(snapshot.data());
      } else {
        this.supplierAuctionService.updateAuctionEntityDto(new AuctionEntityDto());
        this.adminAuctionService.updateAuctionEntityDto(new AuctionEntityDto());
      }
    });
  }

  auctionBidsDetailsListener() {
    let userEntityDto = this.userService.getUserEntity();
    let sessionId = localStorage.getItem('AUC_SESSION_ID');

    //console.log(`userId : ${userEntityDto?.userId}`);
    //console.log(`sessionId : ${sessionId}`);

    if (userEntityDto && sessionId) {
      onSnapshot(doc(this.firestore, this.collectionBidsDetails, sessionId), (snapshot) => {
        console.log("auction bids data: ", snapshot.data());

        if (snapshot.exists() && userEntityDto != null) {
          let auctionBidsDetails = AuctionBidsDetailsUIDto.populateDataFromFirestore(snapshot.data());
          this.supplierAuctionService.updateAuctionBidsDetails(auctionBidsDetails);
          //console.log("populate bids data: ", auctionBidsDetails);
        }
      });
    }
  }

  /// Landing Bids Details
  auctionLandingBidsDetailsListener() {
    let domains  = [];
    domains.push(AuctionwareUtil.getFullDomainName());

    if(isUsePartSubdomain()){
      domains.push(AuctionwareUtil.getPartSubDomainName());
    }

    let queryBids = query(collection(this.firestore, this.collectionBidsDetailsLanding), where('domainName', 'in', domains))

    onSnapshot(queryBids, (querySnapshot) => {
      //console.log("auction bids data (Landing): ", snapshot.data());

      let userEntityDto = this.userService.getUserEntity();
      let snapshot = querySnapshot.docs[0]
      if (snapshot && snapshot.exists() && userEntityDto == null) {
        let auctionBidsDetails = AuctionBidsDetailsUIDto.populateDataFromFirestore(snapshot.data());
        this.supplierAuctionService.updateAuctionBidsDetailsLanding(auctionBidsDetails);
        //console.log("populate bids data (Landing): ", auctionBidsDetails);
      }
    });
  }
}
function isUsePartSubdomain() {
  return AuctionwareUtil.getFullDomainName().includes('auctionext.com') || AuctionwareUtil.getFullDomainName().includes('auctionware.com');
}

