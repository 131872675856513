
    <div class="e-popup ee-width-m" id="e-popuplogout">
        <div class="e-popupblock ea-popupremove"></div>
        <div class="e-popuppage">
            <header class="e-popupheader"><h1>Register</h1><a class="e-popupclose ea-popupremove" (click)="modalClose()">Close</a></header>
            <div class="e-popupmain">
    <div class="e-maintext e-maintext-m">
        <div class="e-alert ee-error"><p>You need to be registered in order to view documents and participate in this auction.</p></div>
        <div class="e-formsubmit">
            <a class="e-buttontext ee-size-m ee-vis-s ea-popupremove ea-popupload" data-file="popup-login.html" data-id="#e-popuplogin" data-post="LOGIN" (click)="openModal(loginModal)">Login</a>
            <a class="e-buttontext ee-size-m ee-vis-l ea-popupremove ea-popupload" data-file="popup-register.html" data-id="#e-popupregister" data-post="REGISTER" (click)="openModal(registrationModal)">Register</a>
        </div>
    </div>
            </div>
        </div>
    </div>
    <ng-template #loginModal>
        <app-login></app-login>
      </ng-template>

      <ng-template #registrationModal>
        <app-registration></app-registration>
      </ng-template>