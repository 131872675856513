import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { Location } from '@angular/common'
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.sass']
})
export class MapViewComponent implements OnInit, AfterViewInit {
  zoom = 12;
  position?: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    zoomControl: false,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    minZoom: 8,
  };
  marker = {
    label: { color: 'black', text: '' },
    title: '',
    options: { animation: google.maps.Animation.DROP },
    info: ''
  }

  infoContent = ''
  isMultiLine: boolean = false;

  viewType$ = new BehaviorSubject<string>('map');

  @ViewChild(GoogleMap) map!: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) info!: MapInfoWindow;

  constructor(
    private activatedRoute: ActivatedRoute,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params) {
        this.viewType$.next(params['viewType']);
        this.position = {
          lat: Number(params['lat']),
          lng: Number(params['lng'])
        }
        this.marker.label.text = params['title'];
        this.marker.title = params['title'];
        this.marker.info = params['title'];
        if (params['title'].length > 27) {
          this.isMultiLine = true;
        }
      }
    })
  }

  ngAfterViewInit() {
    const streetView = this.map.getStreetView();

    this.viewType$.subscribe((data) => {
      if (data && data == 'street-view') {
        streetView.setOptions({
          position: this.position,
          pov: { heading: 90, pitch: 1 },
        });
        streetView.setVisible(true);
      }
    })
  }

  openInfo(marker: MapMarker, content: string) {
    this.infoContent = content;
    this.info.open(marker)
  }

  back() {
    this.location.back();
  }

}
