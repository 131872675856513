<div class="container-fluid">
  <div class="row" style="height: 56px; ">
    <div class="col">
      <header class="e-popupheader">
        <h1 *ngIf="!isMultiLine">{{marker.label.text}}</h1>
        <h4 *ngIf="isMultiLine" class="my-1 me-1">{{marker.label.text}}</h4>
        <a class="e-popupclose ea-popupremove" (click)="back()" ngbAutofocus>Close</a>
      </header>
    </div>
  </div>
  <div class="row my-google-map">
    <div class="col">
      <google-map height="90vh" width="100%" [options]="options" [center]="position!" [zoom]="zoom">
        <map-marker #markerElem="mapMarker" [position]="position!" [title]="marker.title" [options]="marker.options"
          (mapClick)="openInfo(markerElem, marker.info)">
        </map-marker>
        <map-info-window>{{ infoContent }}</map-info-window>
      </google-map>
    </div>
  </div>
</div>