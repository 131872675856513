<div class="e-popup ee-width-s" id="e-popuppassword">
  <div class="e-popupblock ea-popupremove"></div>
  <div class="e-popuppage">
    <div class="e-popupalert ee-success" *ngIf="_showSuccessToast$ | async">Change Password Successfully</div>
    <div class="e-popupalert ee-error" *ngIf="_showErrorToast$ | async">{{errorMsg}}</div>
    <header class="e-popupheader">
      <h1>Password</h1><a class="e-popupclose ea-popupremove" (click)="closeModal()">Close</a>
    </header>
    <div class="e-popupmain">
      <div class="e-spinner" *ngIf="isLoading"></div>
      <div class="e-maintext e-maintext-m">
        <form autocomplete="off" [formGroup]="formGroup" (keyup.enter)="changePassword()">
          <div class="e-field e-valid-required"><label>Old Password</label>
            <div class="e-fieldtext">
              <span class="ee-suffix ee-size-s" [ngClass]="isPasswordVisibleOld ? 'ei-visible' : 'ei-visible-not'" (click)="togglePasswordOld()"></span>
              <input name="" [type]="isPasswordVisibleOld ? 'text' : 'password'" formControlName="oldPassword" required autocomplete="old-password"/>
            </div>
            <div *ngIf="fc.oldPassword.invalid && (fc.oldPassword.dirty || fc.oldPassword.touched)">
              <p class="e-fielderror e-fielderror-required" *ngIf="fc.oldPassword.invalid && (!fc.oldPassword.value)">Required</p>
            </div>
          </div>
          <div class="e-field e-valid-required e-valid-password"><label>New Password</label>
            <div class="e-fieldtext">
              <span class="ee-suffix ee-size-s" [ngClass]="isPasswordVisibleNew ? 'ei-visible' : 'ei-visible-not'" (click)="togglePasswordNew()"></span>
              <input id="e-valid-password" name="" [type]="isPasswordVisibleNew ? 'text' : 'password'" formControlName="newPassword" required autocomplete="new-password"/>
            </div>
            <div *ngIf="fc.newPassword.invalid && (fc.newPassword.dirty || fc.newPassword.touched)">
              <p class="e-fielderror e-fielderror-required" *ngIf="fc.newPassword.invalid && (!fc.newPassword.value)">
                Required</p>
              <p class="e-fielderror e-fielderror-required" *ngIf="fc.newPassword.invalid &&  (fc.newPassword.value)">
                Password must be more than 8 characters, containing at least one capital letter, one lowercase letter, one number and one special character.</p>
            </div>
          </div>
          <div class="e-field e-valid-required e-valid-password e-valid-match" data-match="#e-valid-password">
            <label>Confirm New Password</label>
            <div class="e-fieldtext">
              <span class="ee-suffix ee-size-s" [ngClass]="isPasswordVisibleRepeat ? 'ei-visible' : 'ei-visible-not'" (click)="togglePasswordRepeat()"></span>
              <input name="" [type]="isPasswordVisibleRepeat ? 'text' : 'password'" formControlName="repeatPassword" required/>
            </div>
            <div *ngIf="fc.repeatPassword.invalid && (fc.repeatPassword.dirty || fc.repeatPassword.touched)">
              <p class="e-fielderror e-fielderror-required" *ngIf="fc.repeatPassword.invalid && (!fc.repeatPassword.value)">Required</p>
            </div>
          </div>
          <div class="e-formsubmit">
            <a class="e-buttontext ee-size-m ee-vis-s ea-popupremove" (click)="closeModal()">Cancel</a>
            <a class="e-buttontext ee-size-m ee-vis-l ea-submit" [ngClass]="{'disabled': formGroup.invalid}" (click)="changePassword()">Save</a>
          </div>
        </form>
      </div>
    </div>
  </div>
