<body class="c25">
  <div class="e-popup ee-width-m" id="e-popupdetail">
    <div class="e-popupblock ea-popupremove"></div>
    <div class="e-popuppage">
      <header class="e-popupheader">
        <a class="e-popupclose ea-popupremove" (click)="closeModal()">Close</a>
      </header>
      <div class="e-popupmain">
        <div class=" doc-content e-maintext e-maintext-m" #header>
         
          <div class="p-3">
            <h4 id="h.nxtvpmv2bslx" class="mt-0 text-center c20 text-uppercase">
             <span class="c12 c24"> {{headerName}} PRIVACY POLICY</span>
            </h4>
            <div  #viewContent></div>
          </div>
          <!-- <h4 class="c20" id="h.nxtvpmv2bslx">
            <span class="c12 c24"></span></h4>
          <div  #viewContent></div> -->
        </div>
  
        <div class="btn-scroll-top" [ngClass]="{'btn-scroll-top-terms' : isPrivacyPolicy}" (click)="moveTo(header)">
          <a class="e-buttonbullet ee-size-s ei-up scroll-top " style="margin:4px;">
          </a>
        </div>
      </div>
    </div>
    </div> 
</body>



