import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { MessagePopupComponent } from '../components/message-popup/message-popup.component';
import { ErrorHandlerService } from './error-handler.service';
// import Swal from 'sweetalert2'

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  showSessionExpiredDialog: boolean = true;
  modelService: NgbModal;

  _sessionExpired$ = new BehaviorSubject<boolean>(false);

  constructor(
    private errorHandlerService: ErrorHandlerService,
    private router: Router,
    modelService: NgbModal
  ) {
    this.modelService = modelService;
  }

  get getSessionExpired$(){ return this._sessionExpired$.asObservable(); }
  public handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      console.log(`${operation} failed: ${error.message}`);
      if (error.status == 401 || this.isRedirectedToRoot(error)) {
        this._sessionExpired$.next(true);
        this.modelService.dismissAll()
        if (this.showSessionExpiredDialog) {
          this.showSessionExpiredDialog = false;
          localStorage.clear();
          // Swal.fire({
          //   title: 'Session Expired',
          //   showConfirmButton: false,
          //   showCancelButton: false,
          //   timer: 1000
          // });
          let modalRef = this.modelService.open(MessagePopupComponent);
          modalRef.componentInstance.message = 'Session Expired';
          setTimeout(() => {
            this.modelService.dismissAll();
          }, 1000);
        }
        this.router.navigate(['/']);
      } else {
        if (error.status != 504) {
          this.errorHandlerService.sendErrorToServer(`${operation}`, error);
        }
      }

      // TODO: better job of transforming error for user consumption
      //this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    }
  }

  isRedirectedToRoot(error: any){
    if(error.error && error.error.text){
      if(error.error.text.includes("<app-root></app-root>")){
        return true;
      }
    }

    return false;
  }

  setResetDialogFlag() {
    this.showSessionExpiredDialog = true;
    this._sessionExpired$.next(false);
  }
}
