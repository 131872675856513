import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  _loaderEnable$ = new BehaviorSubject<boolean>(false);
  actionName?: string;
  loadingText: string = "Loading Please Wait...";

  constructor() { }

  ngOnInit() {
  }

  openLoaderIcon(action: string, loadingtext: string) {
    this._loaderEnable$.next(true);
    this.loadingText = loadingtext;
    this.actionName = action;
  }

  closeLoaderIcon(action: string) {
    if (this.actionName == action) {
      this._loaderEnable$.next(false);
      this.loadingText = '';
      this.actionName = '';
    }
  }

  closeLoaderForcefully() {
    this._loaderEnable$.next(false);
    this.loadingText = '';
    this.actionName = '';
  }

}
