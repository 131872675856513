import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { AuctionEntityDto } from './shared/models/AuctionEntityDto';
import { SupplierAuctionService } from './shared/services/supplier-auction-service';
import { AuctionwareUtil } from './shared/util/AuctionwareUtil';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit {
  showScrolledS: boolean = false;
  showScrolledM: boolean = false;
  showScrolledL: boolean = false;

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e:any) {
    this.showScrolledS = window.pageYOffset > 80
    this.showScrolledM = window.pageYOffset > 160
    this.showScrolledL = window.pageYOffset > 240 && window.pageYOffset <= 0
  }

  constructor(
    private meta: Meta,
    private title: Title,
    private datePipe: DatePipe,
    private supplierAuctionService: SupplierAuctionService
  ) { }

  // if ($scrollTop1 > 80) { $('body').addClass('ee-scrolled-s'); } else { $('body').removeClass('ee-scrolled-s'); }
	// 	if ($scrollTop1 > 160) { $('body').addClass('ee-scrolled-m'); } else { $('body').removeClass('ee-scrolled-m'); }
	// 	if ($scrollTop1 > 240 && $scrollTop1 <= $scrollTop) { $('body').addClass('ee-scrolled-l'); } else { $('body').removeClass('ee-scrolled-l'); }

  ngOnInit(): void {
    
    this.disableApplicationLoader();
    this.supplierAuctionService.auctionEntityDto$.subscribe((data) => {
      if (data) {
        this.updateMetaTag(data);
      }
    })
  }

  disableApplicationLoader(){
    let element = document.getElementById('idForDataLoader' );
    if(element){
      element.style.display = 'none';
      console.log("Loader disable successfully.")
    }
  }
  updateMetaTag(auctionEntityDto: AuctionEntityDto) {
    let auctionTitle = auctionEntityDto.locationTitle;
    let description = '';
    if(auctionEntityDto.locationDescription){
      description = auctionEntityDto.locationDescription!.replace(/<\/?[^>]+(>|$)/g, "");
    }
    let endDate = auctionEntityDto.endDate;
    let addressLine1 = auctionEntityDto.addressLine1;
    let addressLine2 = auctionEntityDto.addressLine2;
    let city = auctionEntityDto.city;
    let state = auctionEntityDto.state;
    let zipCode = auctionEntityDto.zipCode;

    let address = addressLine2 ? `${addressLine1}, ${addressLine2}, ${city}, ${state}, ${zipCode}`
      : `${addressLine1}, ${city}, ${state}, ${zipCode}`;

    this.title.setTitle('');
    if(endDate){
    this.title.setTitle(`${auctionTitle} | ${address} | Auction date ${AuctionwareUtil.getDateInMMMdd(endDate!, this.datePipe)}`);
    }
    this.meta.updateTag({ name: 'description', content: `${description}` });
  }
}
