<button class="btn btn-sm text-white m-1 share-button" [style.background]="getConfig.color" (click)="share()">
  <img class="d-block me-2" src="assets/icon/{{getIcon}}.svg" height="18">
  {{getConfig.text}}
</button>


<ng-template #barCodeTemp>
  <div class="e-popup ee-width-s" id="e-popupproperty">
    <div class="e-popupblock ea-popupremove"></div>
    <div class="e-popuppage">
      <header class="e-popupheader">
        <h1 class="modal-header" style="border: 0; padding: 0;">Bar Code</h1>
        <a class="e-popupclose ea-popupremove" (click)="closeModal()">Close</a>
      </header>
      <div class="e-popupmain">
        <div class="e-maintext e-maintext-m text-center">
          <div class="m-3">
            <div class="col-12">
              <img src="/downloadLandingBlob?fileId={{fileInfoDto?.fileId!}}" alt="BarCode">
            </div>
          </div>

          <div class="e-formsubmit text-center">
            <a role="button" class="e-buttontext ee-size-m ee-vis-l ea-submit" (click)="downloadQRCode()">Download</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>