<div class="e-carousel ee-activefirst" data-active="0" data-animate="slide" data-image="cover" data-menu="icon"
  data-ratio="16-9" data-shift="1" id="e-carousel-main">
  <div class="ee-body">
    <ul class="ee-list" [style.left]="'-'+ activeImage*100 + '%'">
      <li *ngFor="let item of propertyImages; index as i" [style.left]="i*100 + '%'"
        [ngClass]="{'ee-active': activeImage == i}">
        <div class="ee-image">
          <img alt="image-{{i}}" src="/downloadLandingBlob?fileId={{item.fileId}}"
            [ngClass]="{'img-portrait': item.dataType == 'Portrait'}" *ngIf="!isValidUrl(item.fileId!)">
          <video class="video-carousel" #videoCarousel [src]="item.fileId"
            *ngIf="isValidUrl(item.fileId!) && item.fileType! != 'embed'" [muted]="true" controls></video>
          <iframe class="video-carousel" #iframeCarousel [src]="item.fileId! | safe"
            *ngIf="isValidUrl(item.fileId!) && item.fileType! == 'embed'"></iframe>
        </div>
      </li>
    </ul>
    <a class="ee-shift ee-shiftprev" (click)="prevImage()" *ngIf="activeImage != 0">Prev</a>
    <a class="ee-shift ee-shiftnext" (click)="nextImage()" *ngIf="activeImage != (propertyImages.length - 1)">Next</a>
  </div>
  <div class="ee-foot carousel-footer">
    <ul class="ee-menu" style="margin-left: 0px;">
      <li class="more-carousel" *ngIf="currentPage > 0"></li>
      <li *ngFor="let item of getPagedImages(); let i=index"
        [ngClass]="{'ee-active': (currentPage * pageSize) + i === activeImage}"
        (click)="changeImage(currentPage * pageSize + i)">
        {{ (currentPage * pageSize) + i + 1 }}
      </li>
      <li class="more-carousel" *ngIf="currentPage < (maxPages - 1)"></li>
    </ul>
  </div>
</div>