import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientBrandDetailsDto } from '../../models/ClientBrandDetailsDto';
import { LandingDashboardService } from '../../services/landing-auction-service';


@Component({
  selector: 'app-privacy-policy-auctionware',
  templateUrl: './privacy-policy-auctionware.component.html',
  styleUrls: ['./privacy-policy-auctionware.component.sass'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class PrivacyPolicyAuctionwareComponent implements OnInit {

  isPrivacyPolicy: boolean = false;

  @Input() content?: string;
  @Input() headerName?: string;
  @ViewChild('viewContent') viewContent?: ElementRef

  constructor(
    public modalService: NgbModal,
  ) {

  }
  ngOnInit(): void {
   
  }


 ngAfterViewInit(): void {
  if (this.content) {
    this.viewContent!.nativeElement.innerHTML = this.content!;
  }
 }

  moveTo(el: HTMLElement) {
    setTimeout(() => {
      el.scrollIntoView();
    }, 100)
  }

  closeModal() {
    this.modalService.dismissAll()
  }


}

