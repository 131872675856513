import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationTrackingService {
  notificationDisplayed: Array<string> = [];

  constructor() { }

  addNotification(notification: string) {
    this.notificationDisplayed.push(notification);
  }
}
