import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DataLoadingEvent } from '../models/DataLoadingEvent';

@Injectable({ providedIn: 'root' })
export class NotificationService {

  constructor() { }

  private _userPQChangeNotification$ = new BehaviorSubject<any>("");
  private _userTenderChangeNotification$ = new BehaviorSubject<any>("");

  private _addUserOnboard$ = new BehaviorSubject<boolean>(true);

  private _auctionChangeNotification$ = new BehaviorSubject<any>("");

  private _auctionCreateNotification$ = new BehaviorSubject<any>("");
  private _auctionConfigSettingDrawer$ = new BehaviorSubject<boolean>(true);

  private _dataLoading$ = new BehaviorSubject<DataLoadingEvent | null>(null);

  private _activeModule$ = new BehaviorSubject<string>("user");

  private _manualAuctionDrawerOpen$ = new BehaviorSubject<boolean>(true);
  private _manualAuctionDrawerSecondStep$ = new BehaviorSubject<boolean>(true);

  getAuctionChangeNotification$() { return this._auctionChangeNotification$.asObservable(); }
  getAuctionCreateNotification$() { return this._auctionCreateNotification$.asObservable(); }
  getAuctionConfigSettingDrawer$() { return this._auctionConfigSettingDrawer$.asObservable(); }
  getManualAuctionDrawerOpen$() { return this._manualAuctionDrawerOpen$.asObservable(); }
  getManulDrawerSecondStep$() { return this._manualAuctionDrawerSecondStep$.asObservable(); }

  getDataLoading$() { return this._dataLoading$.asObservable(); }
  getAddUserOnboard$() { return this._addUserOnboard$.asObservable(); }

  getActiveModuleTab$() { return this._activeModule$.asObservable(); }

  getUserPQChangeNotification$() { return this._userPQChangeNotification$.asObservable(); }
  getUserTenderChangeNotification$() { return this._userTenderChangeNotification$.asObservable(); }

  setAuctionConfigSettingDrawer$(value: boolean) {
    this._auctionConfigSettingDrawer$.next(value);
  }

  setAddUserOnboard$(value: boolean) {
    this._addUserOnboard$.next(value);
  }

  public notifyChangeOnAuction(auctionData: any) {
    this._auctionChangeNotification$.next(auctionData);
  }

  public notifyAuctionCreate(auctionData: any) {
    this._auctionCreateNotification$.next(auctionData);
  }

  public notifyDataLoading(event: DataLoadingEvent) {
    this._dataLoading$.next(event);
  }

  public updateActiveModuleTab(moduleName: string) {
    this._activeModule$.next(moduleName);
  }

  public notifyChangeOnUserPQ(event: any) {
    this._userPQChangeNotification$.next(event);
  }

  public notifyChangeOnUserTender(event: any) {
    this._userTenderChangeNotification$.next(event);
  }

}
