import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class navigationService {

  private emitChangeSource = new Subject<any>();
  private emitLogoutEvent = new Subject<any>();
  private emitSupplierMandatoryEvent = new Subject<any>();
  private emitsupplierBasicDetailEvent = new Subject<any>()

  changeEmitted$ = this.emitChangeSource.asObservable();
  logoutEvenEmitted$ = this.emitLogoutEvent.asObservable();
  supplierMandatoryEvenEmitted$ = this.emitSupplierMandatoryEvent.asObservable();
  emitsupplierBasicDetailEventEmitted$ = this.emitsupplierBasicDetailEvent.asObservable();

  emitChange(change: any) {
    this.emitChangeSource.next(change);
  }

  emitSupplierMandatoryInfo(change: any) {
    this.emitSupplierMandatoryEvent.next(change);
  }

  emitLogout() {
    this.emitLogoutEvent.next(void 0);
  }

  emitBasicDetail(change: any) {
    this.emitsupplierBasicDetailEvent.next(change);
  }

}
