import { AfterContentInit, Component, Input, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuctionEntityDto } from '../../models/AuctionEntityDto';
import { AuthenticationService } from '../../services/common/authentication.service';
import { UserService } from '../../services/user.service';
import { AuctionwareConstants } from '../../util/AuctionwareConstants';
import { LoaderComponent } from '../loader/loader.component';
import { GalleryImagesComponent } from '../gallery-images/gallery-images.component';
import { CompanyDto } from '../../models/CompanyDto';
import { UserPrivilege } from '../../models/UserPrivilege';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements AfterContentInit {

  @ViewChild('appLoader', { static: false })
  appLoader?: LoaderComponent;

  @Input() auctionEntityDto$?: Observable<AuctionEntityDto | null>;

  @Input() companyDto$? : Observable<CompanyDto | null>;

  companyDto?: CompanyDto;
  _companyDto$ = new BehaviorSubject<CompanyDto | null>(null);
  
  auctionEntityDto?: AuctionEntityDto;

  constructor(
    public modalService: NgbModal,
    public userService: UserService,
    private authService: AuthenticationService,
    private router: Router
  ) { }

  ngAfterContentInit(): any{

    this.auctionEntityDto$?.subscribe(data => {
      if (data) {
        this.auctionEntityDto = data;}
    })

    this.companyDto$?.subscribe(data => {
      if(data){
        this.companyDto = data;
        this._companyDto$.next(this.companyDto);
      }
    })
  }

  openModal(content: any) {
    this.authService.errorMsg = "";
    this.modalService.open(content, {
      size: 'md'
    });
  }

  openGalleryModal() {
    let modalRef = this.modalService.open(GalleryImagesComponent);
    modalRef.componentInstance.auctionEntity$ = this.auctionEntityDto$;
  }

  openGoogleMap(type: string) {
    if (this.auctionEntityDto != null) {
      this.router.navigate(['/map-view'], {
        queryParams: {
          lat: this.auctionEntityDto.latitude, 
          lng: this.auctionEntityDto.longitude,
          viewType: type,
          title: this.auctionEntityDto?.addressLine1
        }
      });
    }
  }

  logout() {
    this.appLoader?.openLoaderIcon(AuctionwareConstants.USER_REGISTRATION_PROGRESS, "Please wait logging in...");
    this.authService.logout();
  }

  isAdminUserLoggedIn(){
    let user = this.userService.getUserEntity();
    if(user && user?.userPrivilege == UserPrivilege.AUCTIONEER || user?.userPrivilege == UserPrivilege.SUPER_ADMIN){
      return true;
    }
    return false;
  }
  redirectToAdminDashbord(){
    this.router.navigate(['Admin/Dashboard'])
  }

}
