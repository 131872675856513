import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SubDomainService {

  private subdomain: string = location.href;
  private ip?: string;
  private hostname: string = location.hostname;


  constructor(private http: HttpClient) {
    // Initialize all these values

  }

  getSubDomain(): string {
    return this.subdomain = location.href;
  }

  getHostName(): string {
    return this.hostname = location.hostname;
  }
}
