import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './common/page-not-found/page-not-found.component';
import { MapViewComponent } from './layouts/common/map-view/map-view.component';

const routes: Routes = [
  {
    path: 'Admin',
    loadChildren: () => import('./shared/routing-module/admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'Bidder',
    loadChildren: () => import('./shared/routing-module/supplier/supplier.module').then(m => m.SupplierModule)
  },
  {
    path: '',
    loadChildren: () => import('./shared/routing-module/landing/landing.module').then(m => m.LandingModule)
  },
  { path: 'map-view', component: MapViewComponent },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
