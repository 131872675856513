import { AuctionStatus } from "./AuctionStatus";
import { BidderRequirementsDto } from "./BidderRequirementsDto";
import { BidIncrementalWrapperDto } from "./BidIncrementalWrapperDto";
import { FileInfoDto } from "./FileInfoDto";
import { ListingContactDto } from "./ListingContactDto";

export class AuctionEntityDto {
  auctionId?: string;
  auctionType?: string;
  startDate?: string;
  endDate?: string;
  startTime?: string;
  endTime?: string;
  timeZone?: string;
  startingBidValue?: number;
  incrementalWrapperDto?: BidIncrementalWrapperDto;
  reserveBidValue?: number;
  publishReserve?: boolean;
  propertyImages?: Array<FileInfoDto>;
  propertyDocs?: Array<FileInfoDto>;
  status?: AuctionStatus;
  antiSnipe?: number;
  domainName?: string;
  brandName?:String;

  barcodeImageDto?: FileInfoDto


  //Property Setup
  locationTitle?: string;
  locationDescription?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  zipCode?: string;
  state?: string;
  showAddress?: boolean;
  propertyTitle?: string;
  propertyHighlightsTitle?: string;
  propertyHighlights?: string;
  propertyDetailsTitle?: string;
  propertyDetails?: string;
  locationUrl?: string;
  latitude?: number;
  longitude?: number;
  termsConditions?: string;
  confidentialAgreement?: string;
  stateAbbreviate?: string;


  //bidder releted
  listingContactDtos?: Array<ListingContactDto>;
  bidderRequirementsDtos?: Array<BidderRequirementsDto>;
  bidderApproval?: string;

  auctionDisable?: boolean;
}
