import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/shared/services/common/authentication.service';

@Component({
  selector: 'app-logout-popup-modal',
  templateUrl: './logout-popup-modal.component.html',
  styleUrls: ['./logout-popup-modal.component.sass']
})
export class LogoutPopupModalComponent {
 constructor( public modalService: NgbModal,
  private authService: AuthenticationService){

 }

 modalClose(){
  this.modalService.dismissAll()
 }
 openModal(content: any) {
  this.authService.errorMsg = "";
  this.modalService.open(content, {
    size: 'md'
  });
}
}
