<div class="e-popup ee-width-m" id="e-popupuser">
  <div class="e-popupblock ea-popupremove"></div>
  <div class="e-popuppage">
    <div class="e-spinner" *ngIf="isLoading"></div>
    <div class="e-popupalert ee-success" *ngIf="_showSuccessToast$ | async">Details Updated Successfully</div>
    <div class="e-popupalert ee-error" *ngIf="_showErrorToast$ | async">{{errorMsg}}</div>
    <header class="e-popupheader">
      <h1>My Details</h1>
      <a class="e-popupclose ea-popupremove" (click)="closeModal()">Close</a>
    </header>
    <div class="e-popupmain">
      <div class="e-maintext e-maintext-m">
        <form autocomplete="off" [formGroup]="formGroup" (keyup.enter)="handleValidSubmit()">
          <div class="e-formrow ee-pad-l">
            <div class="e-formcol em-width-50">
              <div class="e-field ea-valid-required"><label>Applicant Name</label>
                <div class="e-fieldtext">
                  <input name="" placeholder="Applicant Name" type="text" formControlName="applicantName" />
                </div>
                <div
                  *ngIf="fc.applicantName && fc.applicantName.invalid && (fc.applicantName.dirty || fc.applicantName.touched)">
                  <p class="e-fielderror e-fielderror-required" *ngIf="fc.applicantName.errors?.required">Required</p>
                </div>
              </div>
            </div>
            <div class="e-formcol em-width-50">
              <div class="e-field ea-valid-required ea-valid-email"><label>Email</label>
                <div class="e-fieldtext new"><input name="" type="text" formControlName="emailId" disable/></div>
                <!-- <div *ngIf="fc.emailId && fc.emailId.invalid && (fc.emailId.dirty || fc.emailId.touched)">
                  <p class="e-fielderror e-fielderror-required" *ngIf="fc.emailId.errors?.required">Required</p>
                  <p class="e-fielderror e-fielderror-required" *ngIf="fc.emailId.errors?.pattern">Invalid</p>
                </div> -->
              </div>
            </div>
          </div>
          <div class="e-formrow ee-pad-l">

            <div class="e-formcol em-width-50">
              <div class="e-field ea-valid-required"><label>Mobile Number</label>
                <div class="e-fieldtext"><input name="" placeholder="Mobile Number" type="text"
                    formControlName="mobileNumber" /></div>
                <div
                  *ngIf="fc.mobileNumber && fc.mobileNumber.invalid && (fc.mobileNumber.dirty || fc.mobileNumber.touched)">
                  <p class="e-fielderror e-fielderror-required" *ngIf="fc.mobileNumber.errors?.required">Required</p>
                  <p class="e-fielderror e-fielderror-required" *ngIf="fc.mobileNumber.errors?.pattern">Invalid</p>
                </div>
              </div>
            </div>
            <div class="e-formcol em-width-50">
              <div class="e-field ea-valid-required"><label>Company Name</label>
                <div class="e-fieldtext"><input name="" placeholder="Company Name" type="text"
                    formControlName="officeName" /></div>
              </div>
            </div>
          </div>
          <div class="e-formrow ee-pad-l">


            <div class="e-formcol em-width-50">
              <div class="e-field ea-valid-required"><label>Office Number</label>
                <div class="e-fieldtext"><input name="" placeholder="Office Number" type="text"
                    formControlName="officeNumber" /></div>
                <div
                  *ngIf="fc.officeNumber && fc.officeNumber.invalid && (fc.officeNumber.dirty || fc.officeNumber.touched)">
                  <p class="e-fielderror e-fielderror-required" *ngIf="fc.officeNumber.errors?.pattern">Invalid</p>
                </div>
              </div>
            </div>
            <div class="e-formcol em-width-50">
              <div class="e-field ea-valid-required"><label>Address Line 1</label>
                <div class="e-fieldtext"><input name="" placeholder="Address Line 1" type="text"
                    formControlName="addressLine1" /></div>
                <div
                  *ngIf="fc.addressLine1 && fc.addressLine1.invalid && (fc.addressLine1.dirty || fc.addressLine1.touched)">
                  <p class="e-fielderror e-fielderror-required" *ngIf="fc.addressLine1.errors?.required">Required</p>
                </div>
              </div>
            </div>
          </div>
          <div class="e-formrow ee-pad-l">


            <div class="e-formcol em-width-50">
              <div class="e-field ea-valid-required"><label>Address Line 2</label>
                <div class="e-fieldtext"><input name="" placeholder="Address Line 2" type="text"
                    formControlName="addressLine2" /></div>
              </div>
            </div>
            <div class="e-formcol em-width-50">
              <div class="e-field ea-valid-required"><label>City</label>
                <div class="e-fieldtext"><input name="" placeholder="City" type="text" formControlName="city" />
                </div>
                <div *ngIf="fc.city && fc.city.invalid && (fc.city.dirty || fc.city.touched)">
                  <p class="e-fielderror e-fielderror-required" *ngIf="fc.city.errors?.required">Required</p>
                </div>
              </div>
            </div>
          </div>
          <div class="e-formrow ee-pad-l">

            <div class="e-formcol em-width-50">
              <div class="e-field ea-valid-required"><label>State</label>
                <div class="e-fieldtext"><input name="" placeholder="State" type="text" formControlName="state" />
                </div>
                <div *ngIf="fc.state && fc.state.invalid && (fc.state.dirty || fc.state.touched)">
                  <p class="e-fielderror e-fielderror-required" *ngIf="fc.state.errors?.required">Required</p>
                </div>
              </div>
            </div>
            <div class="e-formcol em-width-50">
              <div class="e-field ea-valid-required"><label>Zip</label>
                <div class="e-fieldtext">
                  <input name="" placeholder="Zip" type="text" formControlName="zipCode" maxlength="5" (keyup)="checkValidValue()"/>
                </div>
                <div *ngIf="fc.zipCode && fc.zipCode.invalid && (fc.zipCode.dirty || fc.zipCode.touched)">
                  <p class="e-fielderror e-fielderror-required" *ngIf="fc.zipCode.errors?.required">Required</p>
                  <p class="e-fielderror e-fielderror-required" *ngIf="fc.zipCode.errors?.maxlength">Invalid Zip Code</p>
                  <p class="e-fielderror e-fielderror-required" *ngIf="fc.zipCode.errors?.minlength">Invalid Zip Code</p>
                </div>
              </div>
            </div>
          </div>
          <div class="e-formrow ee-pad-l">
            <div class="e-formcol em-width-50" *ngIf="userService.getUserEntity()?.supplier == true">
              <div class="e-field ea-valid-required"><label>Signatory Name</label>
                <div class="e-fieldtext new">
                  <input name="" placeholder="Signatory Name" type="text" formControlName="signatureName" disable/>
                </div>

              </div>
            </div>
            <div class="e-formcol em-width-50" *ngIf="userService.getUserEntity()?.supplier == true">
              <div class="e-field ea-valid-required"><label>Time of Registration</label>
                <div class="e-fieldtext new">
                  <input name="" placeholder="Time of Registration" type="text" formControlName="registrationTime" disable/>
                </div>

              </div>
            </div>
          </div>
          <div class="e-formrow ee-pad-l">
            <!-- <div class="e-formcol em-width-50">
              <div class="e-field"><label>Country</label>
                <div class="e-fieldselect">
                  <select name="" formControlName="country">
                    <option value="" disabled>Select . . .</option>
                    <option value="United States">United States</option>
                  </select>
                </div>
                <div *ngIf="fc.country && fc.country.invalid && (fc.country.dirty || fc.country.touched)">
                  <p class="e-fielderror e-fielderror-required" *ngIf="fc.country.errors?.required">Required</p>
                </div>
              </div>
            </div> -->

            <div class="e-formcol em-width-50" *ngIf="userService.getUserEntity()?.supplier == true">
              <div class="e-field ea-valid-required"><label>Bidder#</label>
                <div class="e-fieldtext new">
                  <input name="" placeholder="Bidder#" type="text" formControlName="paddleNo" />
                </div>
                <div *ngIf="fc.paddleNo && fc.paddleNo.invalid && (fc.paddleNo.dirty || fc.paddleNo.touched)">
                  <p class="e-fielderror e-fielderror-required" *ngIf="fc.paddleNoe.errors?.required">Required</p>
                </div>
              </div>
            </div>
          </div>

          <div class="e-formsubmit">
            <a class="e-buttontext ee-size-m ee-vis-s ea-popupremove" (click)="closeModal()">Cancel</a>
            <a class="e-buttontext ee-size-m ee-vis-l ea-submit"
              [ngClass]="{'disabled': !(isEnabledSaveButton$ | async) || isLoading}"
              (click)="handleValidSubmit()">Save</a>
          </div>
        </form>
      </div>
    </div>
  </div>
