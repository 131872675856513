import { Timestamp } from "firebase/firestore";

export class AuctionBidsDto {
  userId?: string;
  userName?: string;
  auctionId?: string;
  bidPrice?: number;
  noOfBidsPlaced?: number;
  emailId?: string;
  systemBid?: boolean = false;
  bidTimeUTC?: Timestamp;
}
