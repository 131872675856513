import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-seller-user-terms-auctionware',
  templateUrl: './seller-user-terms-auctionware.component.html',
  styleUrls: ['./seller-user-terms-auctionware.component.sass'],
  encapsulation: ViewEncapsulation.Emulated
})
export class SellerUserTermsAuctionwareComponent implements OnInit {
  @Input() isTermsPage: boolean = false;
  @Input() content?: string;
  @ViewChild('viewContent') viewContent?: ElementRef

  constructor(
    public modalService: NgbModal,
  ) { }
  ngOnInit(): void {
   
  }

  ngAfterViewInit(): void {
    if (this.content) {
      this.viewContent!.nativeElement.innerHTML = this.content!;
    }
  }
  modalClose(){
    this.modalService.dismissAll()
   }


}
