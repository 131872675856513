import { DatePipe } from '@angular/common';
import { OnDestroy, Component, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuctionEntityDto } from 'src/app/shared/models/AuctionEntityDto';
import { BehaviorSubject, debounceTime, distinctUntilChanged, Observable, Subscription } from 'rxjs';
import { AuctionBidsDto } from 'src/app/shared/models/AuctionBidsDto';
import { AuctionBidsDetailsUIDto } from 'src/app/shared/models/AuctionBidsDetailsUIDto';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { AuctionwareConstants } from 'src/app/shared/util/AuctionwareConstants';
import { AucProBidTosterService } from 'src/app/shared/services/common/Aucprobid-toaster-service';
import { UserService } from 'src/app/shared/services/user.service';
import { FormControl, Validators } from '@angular/forms';
import { UserAuctionEligibleStatus } from 'src/app/shared/models/auction/userAuction/UserAuctionEligibleStatus';
import { AuctionwareUtil } from 'src/app/shared/util/AuctionwareUtil';
import { AuctionStatus } from 'src/app/shared/models/AuctionStatus';
import { SupplierAuctionService } from 'src/app/shared/services/supplier-auction-service';
import { FirestoreListenerService } from 'src/app/shared/services/firestore-listener.service';
import { LandingDashboardService } from 'src/app/shared/services/landing-auction-service';
import { BidIncrementalDto } from 'src/app/shared/models/BidIncrementalDto';
import { UserMaxBidsDto } from 'src/app/shared/models/UserMaxBidsDto';
import { NotificationTrackingService } from 'src/app/shared/services/notification-tracking.service';
import { AudioService } from 'src/app/shared/services/common/audio-service';
import { UserEntityDto } from 'src/app/shared/models/UserEntityDto';
import { LoadingPopupComponent } from 'src/app/shared/components/loading-popup/loading-popup.component';

@Component({
  selector: 'app-supplier-dashboard-sidebar',
  templateUrl: './supplier-dashboard-sidebar.component.html',
  styleUrls: ['./supplier-dashboard-sidebar.component.sass']
})

export class SupplierDashboardSidebarComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() auctionEntityDto$?: Observable<AuctionEntityDto | null>;

  userInputMaxBid: FormControl = new FormControl('', Validators.required);
  userInputCurrentBid: FormControl = new FormControl('', Validators.required);

  incrementBidValue?: string;
  bidIncrementalList?: Array<BidIncrementalDto> = [];

  // isBid: string | undefined = "neutral"
  isLoggedIn: boolean = false

  shortDateFrom = new Date();
  shortDateTo = new Date('tomorrow');

  sidebarTitle: string = ''
  splashDateFrom: string = '';
  splashDateTo: string = '';

  auctionBidsDetails?: AuctionBidsDetailsUIDto;
  currentBidPrice$ = new BehaviorSubject<number>(0);
  highestBidPrice$ = new BehaviorSubject<number>(0);
  incrementalBidPrice$ = new BehaviorSubject<number>(0);
  isReservedNotMet$ = new BehaviorSubject<boolean>(false);

  _showBidPlaceButton$ = new BehaviorSubject<boolean>(false);
  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _showErrorButtonToast$ = new BehaviorSubject<boolean>(false);
  _disableBidButton$ = new BehaviorSubject<boolean>(false);

  _auctionBidDetailsSubscription?: Subscription;
  _auctionBidDetailsLandingSubscription?: Subscription;

  countdownTimerDays = 0;
  countdownTimerHours = 0;
  countdownTimerMinutes = 0;
  countdownTimerSeconds = 0;
  counterFunction: any;
  highestBidUserId?: string;
  conflictingModelBidCondition: boolean = false;
  conflictingModelMaxBidCondition: boolean = false;

  responseMessage?: string;
  responseValue?: string;
  isLoading$ = new BehaviorSubject<boolean>(false);

  auctionEntityDto?: AuctionEntityDto;
  currentBidPrice: number = 0;
  nextEligibleBidPrice$ = new BehaviorSubject<number>(0);
  nextEligibleMaxBidPrice$ = new BehaviorSubject<number>(0);
  sortedBidIncrementalDtoList?: Array<BidIncrementalDto>;

  @ViewChild("BidderApproved") BidderApproved!: NgbModal;
  @ViewChild("BidderRejected") BidderRejected!: NgbModal;
  @ViewChild("ConflictingOutbid") conflictingOutbidRef!: NgbModal;
  placeBidModel!: NgbActiveModal;
  maxBidModal!: NgbActiveModal;
  conflictingModel!: NgbActiveModal;
  bidPriceForSound?: number;

  modalRefLoader?: NgbModalRef;

  constructor(
    private audioService: AudioService,
    private datePipe: DatePipe,
    private modalService: NgbModal,
    private supplierService: SupplierAuctionService,
    private userService: UserService,
    public toasterService: AucProBidTosterService,
    public landingDashboardService: LandingDashboardService,
    private notificationTrackingService: NotificationTrackingService
  ) { }

  ngOnInit(): void {
    this.userService.getUserEntity$.subscribe((userEntity) => {
      this.isLoggedIn = userEntity != null;
    });

    this._auctionBidDetailsLandingSubscription = this.supplierService.getAuctionBidsDetailsLanding$?.subscribe((data) => {
      if (data) {
        this.highestBidUserId = data.highestBidUserId;

        this.highestBidPrice$.next(Number.parseFloat(data.highestBidPrice!.toFixed(2)));
        this.incrementalBidPrice$.next(this.getCalculatedIncrementalBid(this.highestBidPrice$.value));

        if (data.highestBidUserId) {
          this.currentBidPrice$.next(this.highestBidPrice$.value + this.incrementalBidPrice$.value);
        } else {
          this.currentBidPrice$.next(this.highestBidPrice$.value);
        }
        this.userInputCurrentBid.patchValue(this.getFormattedPrice(this.currentBidPrice$.value));

        this.refreshView();
      }
    })

    this._auctionBidDetailsSubscription = this.supplierService.getAuctionBidsDetails$?.subscribe((data) => {
      if (data) {
        this.auctionBidsDetails = data;
        this.highestBidUserId = data.highestBidUserId;

        this.playBidSound();

        this.highestBidPrice$.next(Number.parseFloat(this.auctionBidsDetails.highestBidPrice!.toFixed(2)));
        this.incrementalBidPrice$.next(this.getCalculatedIncrementalBid(this.highestBidPrice$.value));

        if (this.auctionBidsDetails.highestBidUserId) {
          this.currentBidPrice$.next(this.highestBidPrice$.value + this.incrementalBidPrice$.value);
        } else {
          this.currentBidPrice$.next(this.highestBidPrice$.value);
        }
        this.userInputCurrentBid.patchValue(this.getFormattedPrice(this.currentBidPrice$.value));

        this.showRegistrationChangeMsg(this.auctionBidsDetails);
        this.userService.updateRegistrationStatus(data.registrationStatus!);
        this.checkConflictingOutbid();

        this.refreshView();
      }
    })

    this.auctionEntityDto$?.subscribe(data => {
      if (data) {
        this.auctionEntityDto = data;
        this.sortedBidIncrementalDtoList = this.auctionEntityDto.incrementalWrapperDto?.bidIncrementalDtoList?.sort((a, b) => a.upToAmount! - b.upToAmount!);
        this.refreshView();
      }
    })

    this.userInputMaxBid.valueChanges.
      pipe(debounceTime(1000)).
      pipe(distinctUntilChanged()).subscribe((value) => {
        if (value) {

          let formatedValue = this.resetPriceFormat(value);
          let incrementalBidPriceBasedOnSlab = this.getCalculatedIncrementalBid(this.highestBidPrice$.value);
          // Get Current Bid Price
          this.currentBidPrice$.next(this.highestBidPrice$.value + incrementalBidPriceBasedOnSlab);
          // Update Next Eligible Max Bid Price
          this.nextEligibleMaxBidPrice$.next(this.currentBidPrice$.value);

          if (Number(formatedValue) > this.highestBidPrice$.value) {
            while (this.nextEligibleMaxBidPrice$.value < Number(formatedValue)) {
              incrementalBidPriceBasedOnSlab = this.getCalculatedIncrementalBid(this.nextEligibleMaxBidPrice$.value);
              this.nextEligibleMaxBidPrice$.next(this.incrementBid(this.nextEligibleMaxBidPrice$.value, incrementalBidPriceBasedOnSlab))
            }

            if (this.nextEligibleMaxBidPrice$.value != Number(formatedValue)) {
              this.responseMessage = "Your Max Bid is not in multiple of Increment Amount. Next Eligible Max Bid is ";
              this._showErrorButtonToast$.next(true);
            } else {
              this._showErrorButtonToast$.next(false);
            }
          } else {
            this.responseMessage = "Your Bid is not in multiple of Increment Amount. Next Eligible Bid is ";
            this._showErrorButtonToast$.next(true);
          }
        }
      })
  }

  ngAfterViewInit(): void {
    this.modalRefLoader = this.modalService.open(LoadingPopupComponent, { backdrop: 'static', keyboard: false, centered: true });
  }

  ngOnDestroy(): void {
    if (this._auctionBidDetailsLandingSubscription) {
      this._auctionBidDetailsLandingSubscription.unsubscribe();
    }
    if (this._auctionBidDetailsSubscription) {
      this._auctionBidDetailsSubscription.unsubscribe();
    }
    this.bidPriceForSound = undefined;
  }

  showRegistrationChangeMsg(data: AuctionBidsDetailsUIDto){
    let userEntityDto = this.userService.getUserEntity();
    if(userEntityDto && userEntityDto.registrationStatus && data.registrationStatus && (data.registrationStatus != userEntityDto.registrationStatus)){
      //console.log("registrationStatus: "+userEntityDto.registrationStatus);
      //console.log("newRegistrationStatus: "+data.registrationStatus);
      if(data.registrationStatus == "APPROVED_BIDDERS"){
        this.modalService.open(this.BidderApproved, {
          size: 'md'
        });

      }else if(data.registrationStatus == "REJECTED_BIDDERS"){
        this.modalService.open(this.BidderRejected, {
          size: 'md'
        });

      }
    }
  }

  countdown() {
    if (this.counterFunction) {
      //console.log("Clearing counter function :" + this.counterFunction);
      clearInterval(this.counterFunction);
    }
    this.counterFunction = setInterval(() => {
      let date = this.auctionEntityDto?.status == 'LIVE' ? this.auctionEntityDto?.endDate : this.auctionEntityDto?.startDate;
      let time = this.auctionEntityDto?.status == 'LIVE' ? this.auctionEntityDto?.endTime : this.auctionEntityDto?.startTime;

      this.countdownTimerDays = this.getCountdownTimerDays(date, time, this.auctionEntityDto?.timeZone);
      this.countdownTimerHours = this.getCountdownTimerHours(date, time, this.auctionEntityDto?.timeZone);
      this.countdownTimerMinutes = this.getCountdownTimerMinutes(date, time, this.auctionEntityDto?.timeZone);
      this.countdownTimerSeconds = this.getCountdownTimerSeconds(date, time, this.auctionEntityDto?.timeZone);

    }, 1000);

    console.log("Creating a new countdown function " + this.counterFunction);
  }

  incrementBid(bid: number , incrementBidValueBasedOnSlab: number ) {
    return parseFloat((parseFloat(bid.toFixed(2)) + incrementBidValueBasedOnSlab).toFixed(2));
  }

  decrementBid(bid: number, incrementBidValueBasedOnSlab: number) {
    return parseFloat((parseFloat(bid.toFixed(2)) - incrementBidValueBasedOnSlab).toFixed(2));
  }

  incrementUserBid() {
    if (!this.userService.getUserEntity()) {
      this.landingDashboardService.setLoginButtonVisibility(true);
    }

    if(this.userInputCurrentBid.value < this.highestBidPrice$.value){
      this.currentBidPrice$.next(this.highestBidPrice$.value);
      this.userInputCurrentBid.patchValue(this.getFormattedPrice(this.currentBidPrice$.value));
    }

    this.currentBidPrice$.next(this.resetPriceFormat(this.userInputCurrentBid.value));

    let incrementBidValueBasedOnSlab = this.getCalculatedIncrementalBid(this.currentBidPrice$.value);
    this.currentBidPrice$.next(this.incrementBid(this.currentBidPrice$.value, incrementBidValueBasedOnSlab));
    this.incrementalBidPrice$.next(this.getConfigureBidPriceBasedOnSlab(this.currentBidPrice$.value));
    this.userInputCurrentBid.patchValue(this.getFormattedPrice(this.currentBidPrice$.value));
  }

  getCalculatedIncrementalBid(inputBidPrice: number): number{
    let isPriceOnEdge = false;

    if(this.checkPriceOnEdge(inputBidPrice)){
      isPriceOnEdge = true;
    }

    let incrementBidValueBasedOnSlab;
    if(isPriceOnEdge){
      incrementBidValueBasedOnSlab = this.getHigherSlabIncrementValue(inputBidPrice);
    }else{
      incrementBidValueBasedOnSlab = this.getConfigureBidPriceBasedOnSlab(inputBidPrice);
    }

    return incrementBidValueBasedOnSlab;
  }

  /**
   * Use the upper slab value for decrement and once the calculation is done use the lower slab to display
   * @returns
   */
  decrementUserBid() {
    if (!this.userService.getUserEntity()) {
      this.landingDashboardService.setLoginButtonVisibility(true);
    }

    let changeSlab = false;
    let isPriceOnEdge = false;

    this.currentBidPrice$.next(this.resetPriceFormat(this.userInputCurrentBid.value));

    if(this.isDecrementChangeSlab(this.currentBidPrice$.value)){
      changeSlab = true;
    }

    if(this.checkPriceOnEdge(this.currentBidPrice$.value)){
      isPriceOnEdge = true;
    }

    let decrementBidValueBasedOnSlab;

    // if price is on the edge
    if(isPriceOnEdge){
      decrementBidValueBasedOnSlab = this.getLowerSlabIncrementValue(this.currentBidPrice$.value);
    }else{
      decrementBidValueBasedOnSlab = this.getConfigureBidPriceBasedOnSlab(this.currentBidPrice$.value);
    }

    if(changeSlab && !isPriceOnEdge){
      decrementBidValueBasedOnSlab = this.getLowerSlabIncrementValue(this.currentBidPrice$.value);
    }else{
      decrementBidValueBasedOnSlab = this.getConfigureBidPriceBasedOnSlab(this.currentBidPrice$.value);
    }

    this.incrementalBidPrice$.next(decrementBidValueBasedOnSlab);

    let bid = this.decrementBid(this.currentBidPrice$.value, decrementBidValueBasedOnSlab);

    // if any bid is placed make sure to check the decremented bid price with highest bid value
    if(this.auctionBidsDetails?.highestBidUserId){
      if ( bid == this.auctionBidsDetails?.highestBidPrice! || bid < this.auctionBidsDetails?.highestBidPrice!) {
        return;
      } else {
        this.currentBidPrice$.next(this.decrementBid(this.currentBidPrice$.value, decrementBidValueBasedOnSlab));
        this.userInputCurrentBid.patchValue(this.getFormattedPrice(this.currentBidPrice$.value));
      }
    }else{
      if ( bid < this.auctionBidsDetails?.highestBidPrice!) {
        return;
      } else {
        this.currentBidPrice$.next(this.decrementBid(this.currentBidPrice$.value, decrementBidValueBasedOnSlab));
        this.userInputCurrentBid.patchValue(this.getFormattedPrice(this.currentBidPrice$.value));
      }
    }



    this.incrementalBidPrice$.next(this.getConfigureBidPriceBasedOnSlab(this.currentBidPrice$.value));

  }

  refreshView() {
    if (this.auctionEntityDto?.status == 'LIVE') {
      if ( this.auctionBidsDetails?.myBidIsHighest || this.auctionBidsDetails?.myMaxBidIsHighest) {
        this.sidebarTitle = 'You Are The High Bidder';
      } else if (this.auctionBidsDetails?.bidPlaced && !this.auctionBidsDetails?.myBidIsHighest) {
        this.sidebarTitle = 'You Have Been Outbid';
      } else {
        this.sidebarTitle = 'Bidding is Open';
      }
    }

    if (this.auctionEntityDto?.auctionType == 'Reserve' && this.auctionEntityDto?.publishReserve) {
      if (this.currentBidPrice$.value < this.auctionEntityDto?.reserveBidValue!) {
        this.isReservedNotMet$.next(true);
      } else {
        this.isReservedNotMet$.next(false);
      }
    }

    this.checkPlaceBidButton();
    this.countdown();

    if (this.auctionEntityDto && this.incrementalBidPrice$.value) {
      this.modalRefLoader?.close();
    }
  }

  checkPlaceBidButton() {
    let userEntityDto = this.userService.getUserEntity();

    if (userEntityDto && this.auctionEntityDto?.status == AuctionStatus.LIVE
      && !this.auctionEntityDto?.auctionDisable
      && userEntityDto.registrationStatus == UserAuctionEligibleStatus.APPROVED_BIDDERS
      && AuctionwareUtil.isTimeGreaterThenCurrentTime(this.auctionEntityDto?.endDate! + " " + this.auctionEntityDto?.endTime!, this.auctionEntityDto?.timeZone!)) {
      this._showBidPlaceButton$.next(true);
    } else {
      this._showBidPlaceButton$.next(false);
    }
  }

  checkConflictingOutbid() {
    let notificationTrackingId = AuctionBidsDetailsUIDto.auctionBidsDetailsToString(this.auctionBidsDetails!);

    /**
     * Show notification only when
     * 1. My last bid price or max bid price is equal to highest bid price
     * 2. I am not highest bidder
     */

    let bidPriceMatch = this.auctionBidsDetails?.highestBidPrice == this.auctionBidsDetails?.myLastBidPrice;
    let notHighestBidder = !this.auctionBidsDetails?.myBidIsHighest;

    /**
     *  Do not show conflicting modal if user has a MAX BID which is greater than HIGHEST BID PRICE
     */
    let maxBidCheck = true;
    if (this.auctionBidsDetails?.myLastMaxBidPrice &&
      this.auctionBidsDetails?.myLastMaxBidPrice > this.auctionBidsDetails?.highestBidPrice!) {
      maxBidCheck = false;
    }

    /**
     *  Show conflicting modal when
     *  1. User LAST BID is matching with HIGHEST BID PRICE
     *  2. User does not have a MAX BID or user MAX BID is less than HIGHEST BID PRICE
     *  3. User is not HIGHEST BIDDER
     */
    if (bidPriceMatch && maxBidCheck && notHighestBidder && !this.notificationTrackingService.notificationDisplayed.includes(notificationTrackingId)) {
      this.notificationTrackingService.addNotification(notificationTrackingId);
      this.modalService.dismissAll();
      this.conflictingModelBidCondition = true;
      this.conflictingModelMaxBidCondition = false;
      this.conflictingModel = this.modalService.open(this.conflictingOutbidRef, {
        size: 'md'
      });
    }

    let maxBidPriceMatch = false
    if (this.auctionBidsDetails?.highestBidPrice == this.auctionBidsDetails?.myLastMaxBidPrice &&
      !this.auctionBidsDetails?.myMaxBidIsHighest) {
      maxBidPriceMatch = true;
    }

    console.log("bidPriceMatch: " + bidPriceMatch);
    console.log("maxBidPriceMatch: " + maxBidPriceMatch);
    console.log("notHighestBidder: " + notHighestBidder);
    console.log("myMaxBidIsHighest: " + this.auctionBidsDetails?.myMaxBidIsHighest);
    console.log("maxBidCheck: " + maxBidCheck);

    if (maxBidPriceMatch && notHighestBidder && !this.notificationTrackingService.notificationDisplayed.includes(notificationTrackingId)) {
      this.notificationTrackingService.addNotification(notificationTrackingId);
      this.modalService.dismissAll();
      this.conflictingModelBidCondition = false;
      this.conflictingModelMaxBidCondition = true;
      this.conflictingModel = this.modalService.open(this.conflictingOutbidRef, {
        size: 'md'
      });
    }

    let myBidPriceNowLess = false;
    let myMaxBidPriceNowLess = false
    if (this.auctionBidsDetails?.highestBidPrice && this.auctionBidsDetails?.myLastBidPrice) {
      myBidPriceNowLess = this.conflictingModelBidCondition && this.auctionBidsDetails?.highestBidPrice > this.auctionBidsDetails?.myLastBidPrice
    }

    if (this.auctionBidsDetails?.highestBidPrice && this.auctionBidsDetails?.myLastMaxBidPrice) {
      myMaxBidPriceNowLess = this.conflictingModelMaxBidCondition && this.auctionBidsDetails?.highestBidPrice > this.auctionBidsDetails?.myLastMaxBidPrice
    }

    console.log("myBidPriceNowLess" + myBidPriceNowLess);
    console.log("myMaxBidPriceNowLess" + myMaxBidPriceNowLess);

    if ((myBidPriceNowLess || myMaxBidPriceNowLess) && this.modalService.hasOpenModals()) {
      if (this.conflictingModel) {
        this.conflictingModel.close();
        this.conflictingModelBidCondition = false;
        this.conflictingModelMaxBidCondition = false;
      }
    };

  }

  doBid(bidPrice: number) {
    this._showErrorButtonToast$.next(false);
    this.isLoading$.next(true);
    this._disableBidButton$.next(true);
    let userEntityDto = this.userService.getUserEntity();

    let auctionBidsDto = new AuctionBidsDto();
    auctionBidsDto.userId = userEntityDto.userId;
    auctionBidsDto.userName = userEntityDto.name;
    auctionBidsDto.emailId = userEntityDto.emailId;
    auctionBidsDto.auctionId = this.auctionBidsDetails?.auctionId;
    auctionBidsDto.bidPrice = bidPrice;
    auctionBidsDto.noOfBidsPlaced = 1;

    this.supplierService.doBids(auctionBidsDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == AuctionwareConstants.SUCCESS_CODE) {
          // update auction bid details
          // this.supplierService.updateAuctionBidsDetails(apiResponseDto.data!);
          this.auctionBidsDetails = Object.assign(this.auctionBidsDetails!, apiResponseDto.data!);
          this._showSuccessToast$.next(true);
          this.hideToaster(false, false, this.placeBidModel);
        } else {
          this._showErrorToast$.next(true);
          this.hideToaster(true, true, this.placeBidModel);
        }
        if (apiResponseDto && apiResponseDto.message) {
          this.responseMessage = apiResponseDto.message;
        } else {
          this.responseMessage = "Error while doing bid!";
        }
      },
      error: (error) => {
        console.error(error);
        this._showErrorToast$.next(true);
        this.responseMessage = "Error while doing bid!";
        this.hideToaster(true, true, this.placeBidModel);
      }
    })
  }

  doMaxBid() {
    this._showErrorButtonToast$.next(false);
    if (this.userInputMaxBid.invalid) {
      return;
    }

    this.isLoading$.next(true);
    this._disableBidButton$.next(true);
    let userEntityDto = this.userService.getUserEntity();

    let userMaxBidDto = new UserMaxBidsDto();
    userMaxBidDto.userId = userEntityDto.userId;
    userMaxBidDto.userName = userEntityDto.name;
    userMaxBidDto.emailId = userEntityDto.emailId;
    userMaxBidDto.auctionId = this.auctionBidsDetails?.auctionId;
    userMaxBidDto.bucketSize = Number(this.resetPriceFormat(this.userInputMaxBid.value));


    this.supplierService.doMaxBids(userMaxBidDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        console.log(apiResponseDto);

        if (apiResponseDto) {
          if (apiResponseDto.message) {
            this.responseMessage = apiResponseDto.message;
          } else {
            this.responseMessage = "Error while do Max bid!";
          }

          if (apiResponseDto.code == AuctionwareConstants.SUCCESS_CODE) {
            this.auctionBidsDetails = Object.assign(this.auctionBidsDetails!, apiResponseDto.data!);
            this._showSuccessToast$.next(true);
            this.hideToaster(false, false, this.maxBidModal);
          } else {
            if (apiResponseDto.param1) {
              this.responseValue = this.getFormattedPrice(apiResponseDto.param1);
              this._showErrorButtonToast$.next(true);
              this.hideToaster(true, false, this.maxBidModal);
            } else {
              this._showErrorToast$.next(true);
              this.hideToaster(true, true, this.maxBidModal);
            }
          }
        }

      },
      error: (error) => {
        console.error(error);
        this._showErrorToast$.next(true);
        this.responseMessage = "Error while do max bid!";
        this.hideToaster(true, true, this.maxBidModal);
      }
    })
  }

  shortDateCountdown() {
    return this.datePipe.transform(this.shortDateTo, 'Y/m/d')
  }

  openBidPlaceModal(content: any) {
    this._disableBidButton$.next(false);

    // Reset Next Eligible Bid Price
    let incrementPrice = this.getCalculatedIncrementalBid(this.highestBidPrice$.value)
    if(this.auctionBidsDetails?.highestBidUserId){
      this.nextEligibleBidPrice$.next(this.highestBidPrice$.value + incrementPrice);
    }else{
      this.nextEligibleBidPrice$.next(this.highestBidPrice$.value);
    }
    // Update Current Bid Price from Input Value
    let inputBidPrice = this.resetPriceFormat(this.userInputCurrentBid.value);
    this.currentBidPrice$.next(inputBidPrice)


    if (this.nextEligibleBidPrice$.value != inputBidPrice) {
      while (this.nextEligibleBidPrice$.value < inputBidPrice) {
        let incrementBidValueBasedOnSlab = this.getCalculatedIncrementalBid(this.nextEligibleBidPrice$.value);
        this.nextEligibleBidPrice$.next(this.incrementBid(this.nextEligibleBidPrice$.value, incrementBidValueBasedOnSlab))
      }

      if (this.nextEligibleBidPrice$.value != inputBidPrice) {
        this.responseMessage = "Your Bid is not in multiple of Increment Amount. Next Eligible Bid is ";
        this._showErrorButtonToast$.next(true);
      } else {
        this.currentBidPrice$.next(this.nextEligibleBidPrice$.value)
      }
    }

    this.currentBidPrice = inputBidPrice;

    if(this.conflictingModel){
      this.conflictingModel.close();
    }

    this.placeBidModel = this.modalService.open(content, {
      size: 'sm'
    });
  }

  openMaxBidPlaceModal(content: any) {
    this.userInputMaxBid.reset();
    this._disableBidButton$.next(false);
    this.maxBidModal = this.modalService.open(content, {
      size: 'sm'
    });
  }

  openLogoutModal(content: any) {
    this.modalService.open(content, {
      size: 'md'
    });
  }

  closeModal(model: NgbActiveModal) {
    this._showErrorButtonToast$.next(false);
    if(model){
      model.close();
    }
  }

  closeModelFromUserAction(){
    this._showErrorButtonToast$.next(false);
    this.modalService.dismissAll();
  }

  modalClose(){
    this.modalService.dismissAll()
  }

  populateFormValues() {
    if (this.auctionEntityDto?.incrementalWrapperDto) {
      let bidIncrementalDtoList = this.auctionEntityDto.incrementalWrapperDto.bidIncrementalDtoList;
      if (bidIncrementalDtoList && bidIncrementalDtoList.length > 0) {

        this.bidIncrementalList = [];
        bidIncrementalDtoList.forEach(config => {
          if (config.upToAmount != 999999999999999) {

            let bidIncrementalDto = new BidIncrementalDto();
            bidIncrementalDto.upToAmount = config.upToAmount;
            bidIncrementalDto.incrementValue = config.incrementValue;
            this.bidIncrementalList?.push(bidIncrementalDto);
          } else {

            this.incrementBidValue = this.getFormattedPrice(config.incrementValue);
          }
        })
      }
    }
  }

  openModal(content: any) {
    if (this.auctionEntityDto) {
      this.populateFormValues();
    }
    this.modalService.open(content, {
      size: 'md'
    });
  }

  updateCurrentBidPrice() {
    this.currentBidPrice$.next(this.nextEligibleBidPrice$.value);
    this.currentBidPrice = this.nextEligibleBidPrice$.value;
    this._showErrorButtonToast$.next(false);
  }

  updateMaxBidPrice() {
    this.userInputMaxBid.patchValue(this.getFormattedPrice(this.nextEligibleMaxBidPrice$.value));
    this.userInputMaxBid.updateValueAndValidity();
    this._showErrorButtonToast$.next(false);
    this._disableBidButton$.next(false);
  }

  // Remove Toaster
  hideToaster(isError: boolean, forceClose: boolean, model: NgbActiveModal) {
    this.isLoading$.next(false);
    setTimeout(() => {
      if (!isError) this._disableBidButton$.next(false);
      this._showSuccessToast$.next(false);
      this._showErrorToast$.next(false);
      if (!isError) this.userInputMaxBid.reset();
      if (!isError) this.closeModal(model);
      if (isError && forceClose) this.closeModal(model);
    }, 6000);
  }

  // Countdown Timer
  getCountdownTimerDays(date?: string, time?: string, timezone?: string) {
    if (date != undefined && time != undefined) {
      let convertedDate = date + " " + time;
      return AuctionwareUtil.getCountdownTimerDays(convertedDate, timezone!);
    } return 0
  }

  getCountdownTimerHours(date?: string, time?: string, timezone?: string) {
    if (date != undefined && time != undefined) {
      let convertedDate = date + " " + time;
      return AuctionwareUtil.getCountdownTimerHours(convertedDate, timezone!);
    } return 0
  }

  getCountdownTimerMinutes(date?: string, time?: string, timezone?: string) {
    if (date != undefined && time != undefined) {
      let convertedDate = date + " " + time;
      return AuctionwareUtil.getCountdownTimerMinutes(convertedDate, timezone!);
    } return 0
  }

  getCountdownTimerSeconds(date?: string, time?: string, timezone?: string) {
    if (date != undefined && time != undefined) {
      let convertedDate = date + " " + time;
      return AuctionwareUtil.getCountdownTimerSeconds(convertedDate, timezone!);
    } return 0
  }

  getFormattedPrice(s: any) {
    if (s != null) {
      return Number(s.toString()).toLocaleString('en-US');
    }
    return '0';
  }

  resetPriceFormat(currency: string): number {
    if (currency != undefined) 0;
    return Number((currency+"").replace(/[^0-9.-]+/g, ""));
  }

  getBucket(auctionBidsDetails: AuctionBidsDetailsUIDto) {
    let toReturn = '';
    if (auctionBidsDetails) {
      if (!auctionBidsDetails.userBucketSize) {
        toReturn = "";
      } else {
        if (auctionBidsDetails.userBucketSize && auctionBidsDetails.outplacedPreLotBid) {
          toReturn = "";
        } else {
          toReturn = "$" + " " + this.getFormattedPrice(auctionBidsDetails.userBucketSize);
        }
      }
    }
    return toReturn;
  }

  checkPriceOnEdge(inputBidPrice: number): boolean {

    if (this.sortedBidIncrementalDtoList && this.sortedBidIncrementalDtoList.length > 0) {
      for (var i = 0; i < this.sortedBidIncrementalDtoList!.length; i++) {
        if (inputBidPrice == this.sortedBidIncrementalDtoList![i].upToAmount!) {
          return true;
        }
      }
    }
    return false;
  }
  getConfigureBidPriceBasedOnSlab(inputBidPrice: number): number {

    if (this.sortedBidIncrementalDtoList && this.sortedBidIncrementalDtoList.length > 0) {
      for (var i = 0; i < this.sortedBidIncrementalDtoList!.length; i++) {
        if (inputBidPrice <= this.sortedBidIncrementalDtoList![i].upToAmount!) {
          return parseFloat(parseFloat(this.sortedBidIncrementalDtoList![i].incrementValue!+"").toFixed(2));
        }
      }
    }
    return 0;
  }

  getIncrementBidPriceBasedOnSlabDecrement(inputBidPrice: number): number {

    if (this.sortedBidIncrementalDtoList && this.sortedBidIncrementalDtoList.length > 0) {
      for (var i = 0; i < this.sortedBidIncrementalDtoList!.length; i++) {
        if (inputBidPrice < this.sortedBidIncrementalDtoList![i].upToAmount!) {
          return parseFloat(parseFloat(this.sortedBidIncrementalDtoList![i].incrementValue!+"").toFixed(2));
        }
      }
    }
    return 0;
  }

  isDecrementChangeSlab(inputBidPrice : number): boolean{
    let incrementPrice = this.getConfigureBidPriceBasedOnSlab(inputBidPrice);
    let decrementValue = inputBidPrice - incrementPrice;
    let recalulateIncrementPrice = this.getIncrementBidPriceBasedOnSlabDecrement(decrementValue);
    if(incrementPrice != recalulateIncrementPrice){
      //console.log("Input Price is changing slab " + inputBidPrice);
      return true;
    }
    return false;
  }

  isIncrementChangeSlab(inputBidPrice : number): boolean{
    let incrementPrice = this.getConfigureBidPriceBasedOnSlab(inputBidPrice);
    let decrementValue = inputBidPrice + incrementPrice;
    let recalulateIncrementPrice = this.getConfigureBidPriceBasedOnSlab(decrementValue);
    if(incrementPrice != recalulateIncrementPrice){
      //console.log("Input Price is changing slab " + inputBidPrice);
      return true;
    }
    return false;
  }

  getLowerSlabIncrementValue(inputBidPrice : number): number{
    let incrementPrice = this.getConfigureBidPriceBasedOnSlab(inputBidPrice);
    let decrementValue = inputBidPrice - incrementPrice;
    let recalulateIncrementPrice = this.getConfigureBidPriceBasedOnSlab(decrementValue);
    return recalulateIncrementPrice;
  }

  getHigherSlabIncrementValue(inputBidPrice : number): number{
    let incrementPrice = this.getConfigureBidPriceBasedOnSlab(inputBidPrice);
    let incrementedValue = inputBidPrice + incrementPrice;
    let recalulateIncrementPrice = this.getConfigureBidPriceBasedOnSlab(incrementedValue);
    return recalulateIncrementPrice;
  }

  checkUserInputCurrentBid(){
    this.userInputCurrentBid.patchValue(this.getFormattedPrice(this.resetPriceFormat(this.userInputCurrentBid.value)));
  }

  checkUserInputMaxCurrentBid(){
    this.userInputMaxBid.patchValue(this.getFormattedPrice(this.resetPriceFormat(this.userInputMaxBid.value)));
  }

  playBidSound() {
    if (this.bidPriceForSound) {
      let userEntityDto = this.userService.getUserEntity();
      if (userEntityDto && this.auctionBidsDetails) {
        if (this.highestBidUserId && userEntityDto?.userId != this.highestBidUserId && this.bidPriceForSound != this.auctionBidsDetails?.highestBidPrice) {
          this.audioService.playBidAudio();
          this.bidPriceForSound = this.auctionBidsDetails?.highestBidPrice;
        }
      }
    } else {
      this.bidPriceForSound = this.auctionBidsDetails?.highestBidPrice;
    }
  }
}
