import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-bidder-user-terms-auctionware',
  templateUrl: './bidder-user-terms-auctionware.component.html',
  styleUrls: ['./bidder-user-terms-auctionware.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class BidderUserTermsAuctionwareComponent implements OnInit {
  @Input() isTermsPage: boolean = false;
  @Input() content?: string;

  @ViewChild('viewContent') viewContent?: ElementRef
  constructor(
    public modalService: NgbModal,
  ) { }
  ngOnInit(): void {
   
  }

  ngAfterViewInit(): void {
    if (this.content) {
      this.viewContent!.nativeElement.innerHTML = this.content!;
    }
  }
  modalClose(){
    this.modalService.dismissAll()
   }

}
