<div class="e-popup ee-width-m" id="e-popupdetail">
	<div class="e-popupblock ea-popupremove"></div>
	<div class="e-popuppage">
		<header class="e-popupheader">
			<a class="e-popupclose ea-popupremove" (click)="closeModal()">Close</a>
		</header>
		<div class="e-popupmain">
			<div class=" doc-content e-maintext e-maintext-m" #header>
       
        <div class="p-3 " style="font-size: 18px; font-weight: 400;">
          <h4 id="h.nxtvpmv2bslx" class="mt-0 text-center">
            {{headerName}} PRIVACY POLICY
          </h4>
          <div  #viewContent></div>
        </div>
        <!-- <h4 class="c20" id="h.nxtvpmv2bslx">
          <span class="c12 c24"></span></h4>
				<div  #viewContent></div> -->
			</div>

      <div class="btn-scroll-top" [ngClass]="{'btn-scroll-top-terms' : isPrivacyPolicy}" (click)="moveTo(header)">
        <a class="e-buttonbullet ee-size-s ei-up scroll-top " style="margin:4px;">
        </a>
      </div>
		</div>
	</div>



<!--
  <body class="c25 doc-content" style="height: 75vh; overflow-y: auto;">
    <header class="e-popupheader" #header><a class="e-popupclose ea-popupremove" (click)="modalClose()">Close</a></header>
    <h4 class="c20" id="h.nxtvpmv2bslx"><span class="c12 c24">AUCTIONWARE PRIVACY POLICY</span></h4>
    <p class="c2"><span class="c10">Any reference to </span><span class="c1">we</span><span class="c10">&nbsp;</span><span
        class="c10">or </span><span class="c1">us</span><span class="c4">&nbsp;in this Privacy Policy shall mean
        AuctionWare, located &nbsp;at 1350 Avenue of the Americas, Suite 278; New York, NY 10019.</span></p>
    <p class="c2"><span class="c4">This Privacy Notice tells you about your privacy rights and sets out how we, as a
        controller, collect, use, process and disclose your personal data relating to your interactions with us. Our
        Privacy Policy is regularly reviewed to ensure that we continue to respect your privacy. We reserve the right to
        update this Privacy Policy from time to time by publishing the updated version on the Websites. We therefore
        encourage you to review our Privacy Policy periodically for the most up to date information.</span></p>
    <p class="c2"><span class="c4">We take great care of any information that you give us, and in the following paragraphs
        we set out how any personal information you give us is treated. This Website is not intended for children and we
        do not knowingly collect data relating to children.</span></p>
    <p class="c2"><span class="c4">If you have any questions regarding our Privacy Policy, please contact us
        directly.</span></p>
    <p class="c2"><span class="c1 c12">WHY DO WE HAVE THIS POLICY?</span></p>
    <p class="c2"><span class="c4">Your privacy is important to us, and we are committed to protecting your personal
        information from unauthorized use or disclosure. We will therefore use your personal information, and transfer it
        to others, in accordance with this Privacy Policy. Our Privacy Policy applies whenever you visit any of our
        websites or affiliated websites, during the period you receive services from us, during the process of you
        ordering products from us and afterwards.</span></p>
    <p class="c2"><span class="c1 c12">1. WHAT INFORMATION DO WE COLLECT AND WHEN?</span></p>
    <p class="c2"><span class="c4">Personal data means any information about an individual from which that person can be
        identified. It does not include data where the identity has been removed (anonymous data). We only collect
        information that we will use for the purposes set out in this policy.</span></p>
    <p class="c2"><span class="c4">Specifically, we collect:</span></p>
    <ul class="c3 lst-kix_ipd6qks39kkg-0 start">
      <li class="c2 c13 li-bullet-0"><span class="c1">Information you submit to us</span><span class="c4">. </span></li>
    </ul>
    <p class="c2 c28"><span class="c4">You can submit information to us in the following ways:</span></p>
    <ul class="c3 lst-kix_ipd6qks39kkg-1 start">
      <li class="c7 li-bullet-0"><span class="c4">Through forms, for example signing up to an offer, news or an
          auction;</span></li>
      <li class="c7 li-bullet-0"><span class="c4">By sending us emails and text messages (SMS or MMS);</span></li>
      <li class="c7 li-bullet-0"><span class="c4">By adding posts, reviews and other comments to the Websites, any mobile
          phone applications, or other community forums;</span></li>
      <li class="c7 li-bullet-0"><span class="c4">By interacting with us on social media platforms (such as Facebook or
          X);</span></li>
      <li class="c7 li-bullet-0"><span class="c4">By speaking to us over the telephone; or</span></li>
      <li class="c7 li-bullet-0"><span class="c4">By registering accounts on the Websites or any mobile phone
          applications, including setting up passwords and preferred user names, contact details, account details, your
          preferences and interests.<br>If you do not provide us with your personal data we may not be able to provide you
          with our services, products or respond to any questions or requests you submit to us via our Website. (Please
          note: If you submit details to us of any other person (e.g. a friend), please make sure you have their
          permission before doing so.)</span></li>
    </ul>
    <ul class="c3 lst-kix_ipd6qks39kkg-0">
      <li class="c2 c13 li-bullet-0"><span class="c1 c12">Information we collect about you:</span></li>
    </ul>
    <ul class="c3 lst-kix_ipd6qks39kkg-1 start">
      <li class="c7 li-bullet-0"><span class="c1">Full details of the purchases you make</span><span class="c4">&nbsp;on
          the Websites or on the telephone, including the time and date of purchase, the products you purchase, any
          relevant delivery address and details of any relevant payment card.</span></li>
      <li class="c7 li-bullet-0"><span class="c1">Information on what you search for, view, click on and
          access</span><span class="c4">&nbsp;in and through the Websites, our marketing emails and text messages (SMS or
          MMS). We may collect the time and geographic location of your device when you do so. This information may also
          include where you came to the Websites from, and where you went when you left it. We also track how often you
          visit and use the Websites. We do this through the use of email and website cookies.</span></li>
      <li class="c7 li-bullet-0"><span class="c1">Technical information about the devices you use</span><span
          class="c4">&nbsp;to access the Websites. We collect each device&rsquo;s unique identifying code (MAC address),
          relevant IP address, operating system and version, web browser and version, and geographic location.</span></li>
      <li class="c7 li-bullet-0"><span class="c1">Your social media content (where this is in the public domain) and any
          messages you send us via social media.</span><span class="c4">&nbsp;This information can include posts,
          comments, pictures and video (see paragraph 4 for more information).</span></li>
      <li class="c7 li-bullet-0"><span class="c1">Profile information and insight from companies who already have
          information on you, such as credit reference agencies (customer insight companies).</span><span
          class="c4">&nbsp;They give us their views on what your household may look like, your status, likely preferences
          and behaviors.</span></li>
    </ul>
    <p class="c2"><span class="c4">We do not collect any special categories of Personal Data about you (this includes
        details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political
        opinions, trade union membership, information about your health and genetic and biometric data). Nor do we collect
        any information about criminal convictions and offenses.</span></p>
    <p class="c2"><span class="c1 c12">2. HOW DO WE USE YOUR INFORMATION?</span></p>
    <p class="c2"><span class="c4">We will only use your personal data for the purposes and legal bases set out in the
        table below.</span></p><a id="t.4d2e5e350e6f8551a3647bc7a25ac12eb1e3917f"></a><a id="t.0"></a>
    <table class="c23">
      <tr class="c27">
        <td class="c18" colspan="1" rowspan="1">
          <p class="c0"><span class="c1">Why we use your personal data</span></p>
        </td>
        <td class="c5" colspan="1" rowspan="1">
          <p class="c0"><span class="c1">What our legal basis is for doing so</span></p>
        </td>
      </tr>
      <tr class="c14">
        <td class="c18" colspan="1" rowspan="1">
          <p class="c0"><span class="c1">To provide you with the Website</span><span class="c4">, which requires a certain
              amount of technical information to be collected in order to work properly.</span></p>
        </td>
        <td class="c5" colspan="1" rowspan="1">
          <p class="c0"><span class="c4">The processing is necessary to support our legitimate interests in managing our
              business (to keep our records updated and to study how Website users use our products/services) provided
              such interests are not overridden by your interests and rights.</span></p>
        </td>
      </tr>
      <tr class="c14">
        <td class="c18" colspan="1" rowspan="1">
          <p class="c0"><span class="c1">To power security measures and related services relating to your access to the
              Website</span><span class="c4">&nbsp;for example to enable us to recognize your username and password and
              reset these if you forget them.</span></p>
        </td>
        <td class="c5" colspan="1" rowspan="1">
          <p class="c0"><span class="c4">The processing is necessary to support our legitimate interests in managing our
              business (to keep our records updated and to study how Website users use our products/services) provided
              such interests are not overridden by your interests and rights.</span></p>
        </td>
      </tr>
      <tr class="c16">
        <td class="c18" colspan="1" rowspan="1">
          <p class="c0"><span class="c1">To enable us to run auctions and offers for which you have signed up</span><span
              class="c4">, about which we need to be able to communicate with you.</span></p>
        </td>
        <td class="c5" colspan="1" rowspan="1">
          <p class="c0"><span class="c4">Where you have given consent to the processing of your personal data for direct
              marketing &ndash; which you may withdraw at any time.</span></p>
        </td>
      </tr>
      <tr class="c8">
        <td class="c18" colspan="1" rowspan="1">
          <p class="c0"><span class="c1">To gather feedback from you</span><span class="c4">&nbsp;about the Website, our
              products, other services and activities from time to time. We may invite you to provide this feedback on
              occasion, for example by emailing you to ask if you would like to review a product you have bought. We may
              use independent research and feedback providers to do so on our behalf.</span></p>
        </td>
        <td class="c5" colspan="1" rowspan="1">
          <p class="c0"><span class="c4">The processing is necessary to support our legitimate interests in managing our
              business (to define types of customers for our products and services, to keep our Site updated and relevant,
              to develop our business and to inform our marketing strategy) provided such interests are not overridden by
              your interests and rights.</span></p>
        </td>
      </tr>
      <tr class="c16">
        <td class="c18" colspan="1" rowspan="1">
          <p class="c0"><span class="c1">To contact you from time to time about things you have told us you want to hear
              about</span><span class="c4">, for example our products, news, offers, new auctions and sponsored
              events.</span></p>
        </td>
        <td class="c5" colspan="1" rowspan="1">
          <p class="c0"><span class="c4">Where you have given consent to the processing of your personal data for direct
              marketing &ndash; which you may withdraw at any time.</span></p>
        </td>
      </tr>
      <tr class="c17">
        <td class="c18" colspan="1" rowspan="1">
          <p class="c0"><span class="c1">To respond to any questions, suggestions, issues or complaints you have
              raised</span><span class="c4">&nbsp;with us.</span></p>
        </td>
        <td class="c5" colspan="1" rowspan="1">
          <p class="c0"><span class="c4">The processing is necessary to support our legitimate interests in managing our
              business (to keep our records updated and to review issues with our products/services) provided such
              interests are not overridden by your interests and rights.</span></p>
          <p class="c0 c11"><span class="c4"></span></p>
          <p class="c0"><span class="c4">The processing is necessary to perform a contract or enter into a contract with
              you.</span></p>
        </td>
      </tr>
      <tr class="c15">
        <td class="c18" colspan="1" rowspan="1">
          <p class="c0"><span class="c1">To respond to any social media posts or other public comments you
              make</span><span class="c4">&nbsp;where these are made to or about us, the Website, our products, services
              or other activities.</span></p>
        </td>
        <td class="c5" colspan="1" rowspan="1">
          <p class="c0"><span class="c4">The processing is necessary to support our legitimate interests in managing our
              business (to keep our records updated and to review issues with our products/services) provided such
              interests are not overridden by your interests and rights.</span></p>
        </td>
      </tr>
      <tr class="c17">
        <td class="c18" colspan="1" rowspan="1">
          <p class="c0"><span class="c1">To communicate with you about operational changes to the Website, our products,
              services,</span><span class="c4">&nbsp;for example if we were to withdraw or change this privacy
              policy.</span></p>
        </td>
        <td class="c5" colspan="1" rowspan="1">
          <p class="c0"><span class="c4">The processing is necessary to support our legitimate interests in managing our
              business (to keep our records updated and to review issues with our products/services) provided such
              interests are not overridden by your interests and rights.</span></p>
          <p class="c0 c11"><span class="c4"></span></p>
          <p class="c0"><span class="c4">The processing is necessary for us to comply with legal and regulatory
              obligations.</span></p>
        </td>
      </tr>
      <tr class="c6">
        <td class="c18" colspan="1" rowspan="1">
          <p class="c0"><span class="c1">To perform any contract we have entered into with you</span><span
              class="c4">&nbsp;but also to enforce a contract against you if you do not honor it, including seeking to
              collect any debts that you may owe us.</span></p>
        </td>
        <td class="c5" colspan="1" rowspan="1">
          <p class="c0"><span class="c4">The processing is necessary for us to comply with legal and regulatory
              obligations.</span></p>
          <p class="c0"><span class="c4">The processing is necessary to support our legitimate interests in managing our
              business (to recover debts due to us) provided such interests are not overridden by your interests and
              rights.</span></p>
          <p class="c0"><span class="c4">The processing is necessary to perform a contract or enter into a contract with
              you.</span></p>
        </td>
      </tr>
      <tr class="c17">
        <td class="c18" colspan="1" rowspan="1">
          <p class="c0"><span class="c1">To gather statistics about how you and other people use the Website, and what you
              think of our adverts, offers, news, product information, auctions, sponsored events, social media and other
              digital content</span><span class="c4">. We then analyze these statistics to understand if these things meet
              most people&rsquo;s needs, or if they should be improved, and if so, what design or other changes (for
              example, around the nature and timing of communications) would be most beneficial for our customers and our
              business.</span></p>
        </td>
        <td class="c5" colspan="1" rowspan="1">
          <p class="c0"><span class="c4">The processing is necessary to support our legitimate interests in managing our
              business (to define types of customers for our products and services, to keep our Site updated and relevant,
              to develop our business and to inform our marketing strategy) provided such interests are not overridden by
              your interests and rights.</span></p>
        </td>
      </tr>
      <tr class="c9">
        <td class="c18" colspan="1" rowspan="1">
          <p class="c0"><span class="c1">To check that you have or are likely to have the means to pay f</span><span
              class="c4">or any auction you have one.</span></p>
        </td>
        <td class="c5" colspan="1" rowspan="1">
          <p class="c0"><span class="c4">The processing is necessary to support our legitimate interests in managing our
              business (to recover debts due to us) provided such interests are not overridden by your interests and
              rights.</span></p>
          <p class="c0"><span class="c4">The processing is necessary to perform a contract or enter into a contract with
              you.</span></p>
        </td>
      </tr>
      <tr class="c8">
        <td class="c18" colspan="1" rowspan="1">
          <p class="c0"><span class="c1">To monitor use of the Website to see if it is being abused or
              threatened</span><span class="c4">&nbsp;for example by people posting inappropriate comments in review areas
              or by potential hackers looking to undermine their security.</span></p>
        </td>
        <td class="c5" colspan="1" rowspan="1">
          <p class="c0"><span class="c4">The processing is necessary to support our legitimate interests in managing our
              business (to keep our records updated and to review issues with our products/services) provided such
              interests are not overridden by your interests and rights.</span></p>
          <p class="c0"><span class="c4">The processing is necessary for us to comply with legal and regulatory
              obligations</span></p>
        </td>
      </tr>
      <tr class="c8">
        <td class="c18" colspan="1" rowspan="1">
          <p class="c0"><span class="c1">To protect you and our business against any other criminal behavior</span><span
              class="c4">, including identity theft and fraud.</span></p>
        </td>
        <td class="c5" colspan="1" rowspan="1">
          <p class="c0"><span class="c4">The processing is necessary to support our legitimate interests in managing our
              business (to keep our records updated and to review issues with our products/services) provided such
              interests are not overridden by your interests and rights.</span></p>
          <p class="c0"><span class="c4">The processing is necessary for us to comply with legal and regulatory
              obligations.</span></p>
        </td>
      </tr>
      <tr class="c8">
        <td class="c18" colspan="1" rowspan="1">
          <p class="c0"><span class="c1">To understand you better</span><span class="c4">&nbsp;and, in particular, your
              habits, your location from time to time and your personal circumstances.</span></p>
        </td>
        <td class="c5" colspan="1" rowspan="1">
          <p class="c0"><span class="c4">The processing is necessary to support our legitimate interests in managing our
              business (to define types of customers for our products and services, to keep our Site updated and relevant,
              to develop our business and to inform our marketing strategy) provided such interests are not overridden by
              your interests and rights.</span></p>
        </td>
      </tr>
      <tr class="c9">
        <td class="c18" colspan="1" rowspan="1">
          <p class="c0"><span class="c1">To maintain administrative and legal records about our business</span><span
              class="c4">&nbsp;to enable us to understand what we have sold, how, when, where and at what price and
              account to the Revenue Commissioners for the related taxes that we have to pay.</span></p>
        </td>
        <td class="c5" colspan="1" rowspan="1">
          <p class="c0"><span class="c4">The processing is necessary to support our legitimate interests in managing our
              business (to keep our records updated) provided such interests are not overridden by your interests and
              rights.</span></p>
          <p class="c0"><span class="c4">The processing is necessary for us to comply with legal and regulatory
              obligations.</span></p>
        </td>
      </tr>
      <tr class="c14">
        <td class="c18" colspan="1" rowspan="1">
          <p class="c0"><span class="c1">To enable us and our third-party service providers to plan and manage our
              day-to-day business and related services as effectively as possible</span><span class="c4">, for example in
              predicting likely sales volumes of each product, so we can try and make sure we have enough stock to meet
              likely demand.</span></p>
        </td>
        <td class="c5" colspan="1" rowspan="1">
          <p class="c0"><span class="c4">The processing is necessary to support our legitimate interests in managing our
              business (to keep our records updated and to study how Website users use our products/services) provided
              such interests are not overridden by your interests and rights.</span></p>
        </td>
      </tr>
      <tr class="c14">
        <td class="c18" colspan="1" rowspan="1">
          <p class="c0"><span class="c1">To enable us to understand what our customer and user base looks like</span><span
              class="c4">&nbsp;across our business. We do this by combining your information with information about our
              other customers and users of the Website, so we can spot trends and common factors.</span></p>
        </td>
        <td class="c5" colspan="1" rowspan="1">
          <p class="c0"><span class="c4">The processing is necessary to support our legitimate interests in managing our
              business (to keep our records updated and to study how Website users use our products/services) provided
              such interests are not overridden by your interests and rights.</span></p>
        </td>
      </tr>
      <tr class="c15">
        <td class="c18" colspan="1" rowspan="1">
          <p class="c0"><span class="c1">To enable us to conduct focused market research</span><span
              class="c4">&nbsp;based on trends that we identify, which we can then use to further improve our products and
              services for all our customers.</span></p>
        </td>
        <td class="c5" colspan="1" rowspan="1">
          <p class="c0"><span class="c4">The processing is necessary to support our legitimate interests in managing our
              business (to study how customers use our products/services, to develop them and grow our business) provided
              such interests are not overridden by your interests and rights.</span></p>
        </td>
      </tr>
      <tr class="c22">
        <td class="c18" colspan="1" rowspan="1">
          <p class="c0"><span class="c1">In the process of anonymizing your information</span><span class="c4">&nbsp;so
              that you are no longer identifiable to us.</span></p>
        </td>
        <td class="c5" colspan="1" rowspan="1">
          <p class="c0"><span class="c4">The processing is necessary to support our legitimate interests in managing our
              business (to ensure data minimization) provided such interests are not overridden by your interests and
              rights.</span></p>
        </td>
      </tr>
      <tr class="c8">
        <td class="c18" colspan="1" rowspan="1">
          <p class="c0"><span class="c1">To test new systems and processes</span><span class="c4">&nbsp;as we roll them
              out to make sure they work and will meet the high expectations we set for ourselves.</span></p>
        </td>
        <td class="c5" colspan="1" rowspan="1">
          <p class="c0"><span class="c4">The processing is necessary to support our legitimate interests in managing our
              business (for running our business, provision of administration and IT services, network security, to
              prevent fraud and in the context of a business reorganization or group restructuring exercise) provided such
              interests are not overridden by your interests and rights.</span></p>
        </td>
      </tr>
      <tr class="c15">
        <td class="c18" colspan="1" rowspan="1">
          <p class="c0"><span class="c1">To assist us in the development of new products and services over
              time</span><span class="c4">, for example to gauge whether a new product is likely to appeal to a large
              proportion of our customer base.</span></p>
        </td>
        <td class="c5" colspan="1" rowspan="1">
          <p class="c0"><span class="c4">The processing is necessary to support our legitimate interests in managing our
              business (to develop our products/services and grow our business) provided such interests are not overridden
              by your interests and rights.</span></p>
        </td>
      </tr>
      <tr class="c16">
        <td class="c18" colspan="1" rowspan="1">
          <p class="c0"><span class="c1">To analyze whether the money we spend on advertising</span><span
              class="c4">&nbsp;on trade publications and trade websites, online and in search engines represents good
              value.</span></p>
        </td>
        <td class="c5" colspan="1" rowspan="1">
          <p class="c0"><span class="c4">Where you have given consent to the processing of your personal data for direct
              marketing &ndash; which you may withdraw at any time.</span></p>
        </td>
      </tr>
    </table>
    <p class="c2"><span class="c1 c12">Where we rely on our legitimate business interests to allow us to process your
        personal data or we process your personal data for direct marketing, you have the right, at any time, to
        object.</span></p>
    <p class="c2"><span class="c1 c12">3. WHO DO WE SHARE YOUR INFORMATION WITH?</span></p>
    <p class="c2"><span class="c4">We cannot run our business or provide many of our services and benefits without
        involving other people and businesses and sometimes we pass your information to these other people and businesses
        as set out below. We only share your information where we can do so in accordance with our legal data protection
        and privacy obligations. We share the information we collect with:</span></p>
    <ul class="c3 lst-kix_ktivwg5gwe04-0 start">
      <li class="c2 c13 li-bullet-0"><span class="c4">Affiliates of AuctionWare. &nbsp;We do so because we hope to have a
          relationship with you across all these different brands and businesses, if not now, then in the future, and we
          want to be able to provide you with the same value and high quality of experience wherever you choose to
          interact with us.</span></li>
      <li class="c2 c13 li-bullet-0"><span class="c4">Other people and businesses who help us provide the Websites, and
          related services to you, for example, information technology companies who design and host the Websites and
          payment services companies who enable you to use payment cards with us.</span></li>
      <li class="c2 c13 li-bullet-0"><span class="c4">Advertiser and advertising networks which require the data to select
          and serve relevant adverts to you and others. We may use the information we have collected from you to enable us
          to comply with our advertisers&#39; wishes by displaying their advert to that target audience.</span></li>
      <li class="c2 c13 li-bullet-0"><span class="c4">Third parties who in our reasonable opinion make a claim that any
          content posted or uploaded by you to the Website constitutes a violation of their intellectual property rights,
          or of their right to privacy.</span></li>
      <li class="c2 c13 li-bullet-0"><span class="c4">Analytics and search engine providers who assist us in the
          improvement and optimisation of the Websites.</span></li>
      <li class="c2 c13 li-bullet-0"><span class="c4">Our insurers and insurance brokers were required in order for us to
          be able to obtain insurance against risks we face in running our business. They may retain this information for
          the purpose of ongoing risk assessment and insurance broking and underwriting services.</span></li>
      <li class="c2 c13 li-bullet-0"><span class="c4">Banks and finance companies where we have allowed them to offer you
          the possibility of purchasing our products on credit or through a loan arranged online. They may retain this
          information, for example if you take any service they offer, for the purpose of providing you with that
          service.</span></li>
      <li class="c2 c13 li-bullet-0"><span class="c4">Credit reference agencies who provide anti-fraud and credit score
          information to us, banks and finance companies who also provide anti-fraud services and customer insight
          companies (as set out above), in each case as necessary in order to benefit from their services. Where we do so
          for anti-fraud purposes, the recipient organization may hold your information on file for the purpose of their
          fraud prevention services in future.</span></li>
      <li class="c2 c13 li-bullet-0"><span class="c4">Business partners, suppliers and subcontractors for the performance
          of any contract we enter into with them or you.</span></li>
      <li class="c2 c13 li-bullet-0"><span class="c4">The prospective buyer of any part of our business or assets that we
          may sell.</span></li>
      <li class="c2 c13 li-bullet-0"><span class="c4">Our professional advisers for example our lawyers, accountants and
          technology consultants when they need it to provide advice to us.</span></li>
      <li class="c2 c13 li-bullet-0"><span class="c4">The police, local authorities, the courts and any other central or
          local government bodies where they request it and we may lawfully disclose it, for example for the prevention
          and detection of crime.</span></li>
      <li class="c2 c13 li-bullet-0"><span class="c4">Other people who make a subject access request to us, where we are
          allowed to do so by law (see Managing Your Information below for what we mean by a subject access
          request).</span></li>
    </ul>
    <p class="c19"><span class="c4 c26">We may also share the information we collect where we are legally obliged to do
        so, for example, to comply with a court order.</span></p>
    <p class="c2"><span class="c1 c12">4. SOCIAL MEDIA, BLOGS, REVIEWS, AND SIMILAR SERVICES</span></p>
    <p class="c2"><span class="c4">Any posts or comments you make to us on social media will be shared under the terms of
        the relevant social media platform (for example, Facebook or X) on which they are made and could be made
        public by that platform. These platforms are controlled by other people, not us, so we are not responsible for
        this sharing. You should review the terms and conditions and privacy policies of the social media platforms you
        use to ensure you understand how they will use your information, what information relating to you they will place
        in the public domain and how you can stop them from doing so if you are unhappy about it.</span></p>
    <p class="c2"><span class="c4">Any blog, review or other posts or comments you make about us, our products and
        services on any of our blog, review or user community services will be shared with all other members of that
        service and the public at large.</span></p>
    <p class="c2"><span class="c4">You should ensure that any comments you make on these services, and on social media in
        general, are fit to be read by the public, and in particular are not offensive or defamatory. You are responsible
        for ensuring that any comments you make comply with any relevant policy on acceptable use of those
        services.</span></p>
    <p class="c2"><span class="c1 c12">5. SECURITY OF YOUR INFORMATION</span></p>
    <p class="c2"><span class="c4">Information provided through the Websites is stored on our secure servers or those of
        any third party we engage to provide our IT platform, and is encrypted using standard technology.</span></p>
    <p class="c2"><span class="c4">Where we have given you, or you have chosen, a password which enables you to access
        your account or certain parts of the Websites, you are responsible for keeping the password confidential and must
        not share it with anyone. You are responsible for any actions carried out using your password except where there
        has been fraud.</span></p>
    <p class="c2"><span class="c1 c12">6. INTERNATIONAL TRANSFER OF YOUR INFORMATION</span></p>
    <p class="c2"><span class="c4">Although we are based in the US, we occasionally need to use international
        suppliers.</span></p>
    <p class="c2"><span class="c4">To allow us to run our business on this basis, the information we collect may be
        transferred to, stored and used at premises in countries outside the US. When it is necessary to transfer your
        personal data outside the US, we will ensure appropriate safeguards are in place to protect the privacy and
        integrity of such personal data. Please Contact Us if you wish to obtain information concerning such
        safeguards.</span></p>
    <p class="c2"><span class="c1 c12">7. HOW LONG DO WE KEEP YOUR INFORMATION FOR?</span></p>
    <p class="c2"><span class="c4">In accordance with our legal data protection and privacy obligations, we will only
        retain your information for as long as we need it to achieve the purpose(s) for which we obtained it in the first
        place, as required by law, and for the exercise or defense of legal claims. We will then either securely delete it
        or anonymise it so that it cannot be linked back to you (see paragraph 2 for full details of those
        purposes).</span></p>
    <p class="c2"><span class="c1 c12">8. MANAGING YOUR INFORMATION</span></p>
    <p class="c2"><span class="c4">It is very important to us that all the information we hold about you remains accurate
        and up to date to reduce the risk of a misunderstanding. We need your help in doing so. If you have an online
        account with us, please ensure that the information (for example, contact information) you provide to us through
        that account remains accurate and up to date. Please review and update it regularly.</span></p>
    <p class="c2"><span class="c4">You have several rights in relation to your personal data under applicable privacy and
        data protection law, which may be subject to certain limitations and restrictions. Depending on your country of
        residence or domicile, you may have other legal rights over the information we collect from you and your devices.
        We will honor all such legal rights.</span></p>
    <p class="c2"><span class="c4">We may request proof of identification to verify your request. We reserve the right to
        charge you a small administration fee to meet our costs in honoring your legal rights, where permitted by the
        relevant law. We reserve the right not to comply with any requests we receive where we may lawfully do so, for
        example if we reasonably believe a request to be malicious, technically very onerous, to involve disproportionate
        effort or harmful to the rights of others.</span></p>
    <p class="c2"><span class="c4">If you wish to exercise any of these rights, please contact us directly. We will
        respond to any valid requests within the time periods required by applicable privacy and data protection
        law.</span></p>
    <p class="c2"><span class="c4">If you have any questions or complaints about our use of your information, please
        contact us directly. We will do our very best to answer any question and resolve any complaint to your
        satisfaction.</span></p>
    <p class="c2"><span class="c1 c12">9. THIRD PARTY LINKS</span></p>
    <p class="c2"><span class="c4">The Websites may contain links to the websites of third parties. If you follow a link
        to any third party website, please note that these websites will have their own privacy policies and we do not
        accept any responsibility or liability in respect of the same.</span></p>
    <p class="c2"><span class="c1 c12">10. CHANGES TO THIS PRIVACY NOTICE</span></p>
    <p class="c2"><span class="c4">We reserve the right to update this Privacy Notice from time to time. If we make any
        changes, we will post those changes here and update the &quot;Last Updated&quot; date at the bottom of this
        Privacy Notice. However, if we make material changes to Privacy Notice we will notify you by means of a prominent
        notice on the Website prior to the change becoming effective. Please review the Privacy Notice whenever you access
        or use this Website.</span></p>
    <p class="c2"><span class="c1 c12">11. CONTACT US - UPDATING YOUR PREFERENCES AND DETAILS</span></p>
    <p class="c2"><span class="c4">If you:</span></p>
    <ul class="c3 lst-kix_ybtlek7p5lds-0 start">
      <li class="c2 c13 li-bullet-0"><span class="c4">have any queries, comments or complaints about this Privacy
          Notice;</span></li>
      <li class="c2 c13 li-bullet-0"><span class="c4">no longer want to participate in our services; or</span></li>
      <li class="c2 c13 li-bullet-0"><span class="c4">wish to opt out of receiving further information as referred to in
          paragraph 8 of this Privacy Notice,</span></li>
    </ul>
    <p class="c2"><span class="c4">Please let us know by contacting us directly.</span></p>
    <p class="c19 c11"><span class="c12 c21"></span></p>
    <div class="btn-scroll-top" (click)="moveTo(header)">
      <a class="e-buttonbullet ee-size-s ei-up scroll-top"></a>
    </div>
  </body> -->




