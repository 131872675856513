<div class="e-splashcountdown">
  <img alt="" src="assets/blob/property/01.jpg" />
  <div class="e-countdown" data-date="2023/01/04 13:00">
    <ul class="e-countdownmenu">
      <li><strong>{{countdownTimerDays}}</strong> <span>Days</span></li>
      <li><strong>{{countdownTimerHours}}</strong> <span>Hrs</span></li>
      <li><strong>{{countdownTimerMinutes}}</strong> <span>Mins</span></li>
      <li><strong>{{countdownTimerSeconds}}</strong> <span>Secs</span></li>
    </ul>
  </div>
</div>
<div class="e-splashtext">
  <img alt="" src="assets/blob/property/01.jpg" />
  <div class="ee-row">
    <div class="ee-col">
      <h1>{{auctionEntityDto?.propertyTitle}}</h1>
      <p *ngIf="auctionEntityDto">
        <span *ngIf="auctionEntityDto?.showAddress && auctionEntityDto?.addressLine2">   {{auctionEntityDto.addressLine1}}, {{auctionEntityDto.addressLine2}}<br />
          {{auctionEntityDto.city}}, {{auctionEntityDto.stateAbbreviate}}  {{auctionEntityDto.zipCode}}</span>
        <span *ngIf="auctionEntityDto?.showAddress && !auctionEntityDto?.addressLine2">{{auctionEntityDto.addressLine1}}<br />
          {{auctionEntityDto.city}}, {{auctionEntityDto.stateAbbreviate}}  {{auctionEntityDto.zipCode}}</span>
      </p>
    </div>
    <a class="ee-icon ei-bid"></a>
    <div class="ee-col">
      <h1>BIDDING</h1>
      <p *ngIf="auctionEntityDto">
        <span><strong>STARTS</strong></span>
        <span>{{getDisplayTime(auctionEntityDto.startTime)}}<small>{{getDisplayMeridiem(auctionEntityDto.startTime)}}</small>
          {{formatTimezone(auctionEntityDto.timeZone)}}</span><span><small>{{getDisplayDay(auctionEntityDto.startDate)}}</small>
          {{getDisplayDate(auctionEntityDto.startDate)}},
          <small>{{getDisplayYear(auctionEntityDto.startDate)}}</small></span>
      </p>
      <p *ngIf="auctionEntityDto">
        <span><strong>ENDS</strong></span>
        <span>{{getDisplayTime(auctionEntityDto.endTime)}}<small>{{getDisplayMeridiem(auctionEntityDto.endTime)}}</small>
          {{formatTimezone(auctionEntityDto.timeZone)}}</span><span><small>{{getDisplayDay(auctionEntityDto.endDate)}}</small>
          {{getDisplayDate(auctionEntityDto.endDate)}},
          <small>{{getDisplayYear(auctionEntityDto.endDate)}}</small></span>
      </p>
    </div>
  </div>
</div>

<ng-template #BidderApproved>
  <div class="e-popup ee-width-m" id="e-popupconfirmbid">
    <div class="e-popupblock ea-popupremove"></div>
    <div class="e-popuppage e-popup-without-header">

      <div class="e-popupmain">
        <div class="e-maintext e-maintext-m">
          <div class="e-alert ee-success"><p>Your registration has been approved, you are all set to bid on this property</p></div>
        </div>
      </div>
      <footer class="">
        <button class="e-buttontext ee-size-s new-ok-btn ee-vis-l ea-popupload" (click)="closeModal()">Ok</button>
      </footer>
    </div>
  </div>

</ng-template>

<ng-template #BidderRejected>
  <div class="e-popup ee-width-m" id="e-popupconfirmbid">
    <div class="e-popupblock ea-popupremove"></div>
    <div class="e-popuppage e-popup-without-header">

      <div class="e-popupmain">
        <div class="e-maintext e-maintext-m">
          <div class="e-alert ee-error"><p>Your registration is been rejected, feel free to contact us if you have any questions</p></div>
        </div>
      </div>
      <footer class="">
        <button class="e-buttontext ee-size-s new-ok-btn ee-vis-l ea-popupload " (click)="closeModal()">Ok</button>
      </footer>
    </div>
  </div>

</ng-template>
