<div class="e-popup ee-width-m" id="e-popupdetail">
	<div class="e-popupblock ea-popupremove"></div>
	<div class="e-popuppage">
		<header class="e-popupheader">
			<h1>{{title}}</h1>
			<a class="e-popupclose ea-popupremove" (click)="closeModal()">Close</a>
		</header>
		<div class="e-popupmain">
			<div class="e-maintext e-maintext-m">
				<p *ngIf="title != 'Highlights'" #viewContent></p>
        <ul *ngIf="title == 'Highlights'">
            <li *ngFor="let item of getContent(content!)">
                {{item}}
            </li>
        </ul>
				<div class="e-formsubmit">
					<a class="e-buttontext ee-size-m ee-vis-s ea-popupremove" (click)="closeModal()">Close</a>
					<a class="e-buttontext ee-size-m ee-vis-l ea-popupremove ea-popupload" *ngIf="isShowEdit"
          (click)="openEditModal(propertySetupModal)">Edit</a>
				</div>
			</div>
		</div>
	</div>

<!-- Property Setup Modal -->
<ng-template #propertySetupModal>
    <app-admin-property-setup-modal></app-admin-property-setup-modal>
</ng-template>

