import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserEntityDto } from '../../models/UserEntityDto';
import { UserPrivilege } from '../../models/UserPrivilege';

@Injectable({ providedIn: 'root' })
export class RoutingService {

  constructor(private router: Router) { }

  doRouting(user?: UserEntityDto) {

    // supplier
    if (user?.userPrivilege == UserPrivilege.AUCTIONEER || user?.userPrivilege == UserPrivilege.SUPER_ADMIN) {
      this.router.navigate(['Admin/Dashboard'])
      return;
    }
    if (user?.userPrivilege == UserPrivilege.BIDDER) {
      this.router.navigate(['Bidder/Dashboard'])
      return;
    }
    this.router.navigate(['/'])
  }

}
