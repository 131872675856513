export class AuctionBidsDetailsUIDto {
  auctionId?: string;
  highestBidPrice?: number;
  myLastBidPrice?: number;
  myLastMaxBidPrice?: number;
  highestBidUserId?: string;
  highestBidDate?: string;
  highestBidTime?: string;
  userBucketSize?: number;
  incrementalBidPrice?: number;
  highestBidUserPaddleNo?: string;
  registrationStatus?: string;

  bidPlaced?: boolean;
  myBidIsHighest?: boolean;
  myMaxBidIsHighest?: boolean;
  preBidPlaced?: boolean;
  outplacedPreLotBid?: boolean;
  outplacedLotBid?: boolean;
  fullUpdate?: boolean;
  landingUpdate? : boolean;


  sessionId?: string;
  workflowName?: string;
  readStatus?: string;

  maxBidUpdateEvent?: boolean;

  static populateDataFromFirestore(data: any): AuctionBidsDetailsUIDto {
    let auctionBidsDetailsUIDto = new AuctionBidsDetailsUIDto();
    auctionBidsDetailsUIDto.auctionId = data.auctionId;
    auctionBidsDetailsUIDto.highestBidPrice = Number.parseFloat(data.highestBidPrice);
    auctionBidsDetailsUIDto.myLastBidPrice = data.myLastBidPrice != null ? Number.parseFloat(data.myLastBidPrice): undefined;
    auctionBidsDetailsUIDto.myLastMaxBidPrice = data.myLastMaxBidPrice != null ? Number.parseFloat(data.myLastMaxBidPrice): undefined;
    auctionBidsDetailsUIDto.highestBidUserId = data.highestBidUserId;
    auctionBidsDetailsUIDto.highestBidDate = data.highestBidDate;
    auctionBidsDetailsUIDto.highestBidTime = data.highestBidTime;
    auctionBidsDetailsUIDto.userBucketSize = data.userBucketSize != null ? Number.parseFloat(data.userBucketSize) : undefined;
    auctionBidsDetailsUIDto.incrementalBidPrice = Number.parseFloat(data.incrementalBidPrice);
    auctionBidsDetailsUIDto.highestBidUserPaddleNo = data.highestBidUserPaddleNo;
    auctionBidsDetailsUIDto.registrationStatus = data.registrationStatus;

    auctionBidsDetailsUIDto.bidPlaced = data.bidPlaced;
    auctionBidsDetailsUIDto.myBidIsHighest = data.myBidIsHighest;
    auctionBidsDetailsUIDto.myMaxBidIsHighest = data.myMaxBidIsHighest;
    auctionBidsDetailsUIDto.preBidPlaced = data.preBidPlaced;
    auctionBidsDetailsUIDto.outplacedPreLotBid = data.outplacedPreLotBid;
    auctionBidsDetailsUIDto.outplacedLotBid = data.outplacedLotBid;


    auctionBidsDetailsUIDto.sessionId = data.sessionId;
    auctionBidsDetailsUIDto.workflowName = data.workflowName;
    auctionBidsDetailsUIDto.readStatus = data.readStatus;
    auctionBidsDetailsUIDto.landingUpdate = data.landingUpdate;
    auctionBidsDetailsUIDto.fullUpdate = data.fullUpdate;
    auctionBidsDetailsUIDto.maxBidUpdateEvent = data.maxBidUpdateEvent;
    return auctionBidsDetailsUIDto;
  }

  static auctionBidsDetailsToString(auctionBidsDetails: AuctionBidsDetailsUIDto): string {
    return auctionBidsDetails.auctionId + "" +
      auctionBidsDetails.highestBidPrice + "" +
      auctionBidsDetails.highestBidUserId + "" +
      auctionBidsDetails.highestBidDate + "" +
      auctionBidsDetails.highestBidTime + "" +
      auctionBidsDetails.userBucketSize + "" +
      auctionBidsDetails.incrementalBidPrice + "" +
      auctionBidsDetails.highestBidUserPaddleNo + "" +
      auctionBidsDetails.registrationStatus + "" +
      auctionBidsDetails.bidPlaced + "" +
      auctionBidsDetails.myBidIsHighest + "" +
      auctionBidsDetails.myMaxBidIsHighest + "" +
      auctionBidsDetails.preBidPlaced + "" +
      auctionBidsDetails.outplacedPreLotBid + "" +
      auctionBidsDetails.outplacedLotBid + "" +
      auctionBidsDetails.myLastBidPrice + "" +
      auctionBidsDetails.myLastMaxBidPrice + ""+
      auctionBidsDetails.sessionId + "" +
      auctionBidsDetails.workflowName + "" +
      auctionBidsDetails.landingUpdate + "" +
      auctionBidsDetails.fullUpdate + "" +
      auctionBidsDetails.readStatus;
  }
}
