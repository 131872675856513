import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, timeout, tap, catchError } from 'rxjs';
import { ServerAPIResponseDto } from '../models/ServerAPIResponseDto';
import { ErrorService } from './error.service';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  private _fileUploadEvent$ = new BehaviorSubject<boolean>(false);

  get getFileUploadEvent$(){ return this._fileUploadEvent$.asObservable(); }

  constructor(
    private http: HttpClient,
    private errorService: ErrorService
  ) {}

  uploadFile(fileSave : FormData): Observable<ServerAPIResponseDto>{
    return this.http.post<any>("saveFilesBlob", fileSave);
  }

  uploadMultipleFile(formData : FormData): Observable<ServerAPIResponseDto>{
    return this.http.post<any>("saveMultiFilesBlob", formData);
  }

  fileUploadEvent(){
    this._fileUploadEvent$.next(true);
  }
  uploadAuctionTemplateForCreate(file: any): Observable<ServerAPIResponseDto> {
    return this.http.post<ServerAPIResponseDto>('managed-auctions', file);
  }

  uploadAuctionRecreateTemplateForCreate(file: any): Observable<ServerAPIResponseDto> {
    return this.http.post<ServerAPIResponseDto>('managed-lots', file,);
  }

  uploadAuctionZipForLotImages(file: any, auctionId:string): Observable<ServerAPIResponseDto> {
    let params = new HttpParams().set('auctionId', auctionId);
    return this.http.post<ServerAPIResponseDto>('managed-lot-images', file,{params}).pipe(timeout(600000));
  }

  downloadFile(fileId : string){
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    const httpOptions = {
        'responseType'  : 'arraybuffer' as 'json',
         'headers' : headers
      };
    return this.http.get<any>('downloadBlob?fileId='+fileId,httpOptions);
  }

  downloadTemplate(templateName: string){
    const headers = new HttpHeaders().set('templateName', templateName);
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    const httpOptions = {
        'responseType'  : 'arraybuffer' as 'json',
         'headers' : headers
      };
    return this.http.get<any>('downloadTemplate', httpOptions);
  }

  downloadLandingFile(fileId : string){
    const headers = new HttpHeaders({ Authorization: 'Basic YXVjcHJvYmlkOldtY2xscDE7' });
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    const httpOptions = {
        'responseType'  : 'arraybuffer' as 'json',
         'headers' : headers
      };
    return this.http.get<any>('downloadLandingBlob?fileId='+fileId,httpOptions);
  }

  createUserResource(file: any): Observable<ServerAPIResponseDto> {
    return this.http.post<ServerAPIResponseDto>('createUserResources', file);
  }

  addUserTemplateFile(file: any){
    const httpOptions = {
        'responseType'  : 'arraybuffer' as 'json',
      };
    return this.http.post<any>('managedOnboardUser',file,httpOptions);
  }
}
