import { Injectable } from "@angular/core";
import { BehaviorSubject, catchError, tap } from "rxjs";
import { ServerAPIResponseDto } from "../models/ServerAPIResponseDto";
import { AuctionwareConstants } from "../util/AuctionwareConstants";
import { ClientBrandDetailsDto } from "../models/ClientBrandDetailsDto";
import { HttpClient } from "@angular/common/http";
import { ErrorService } from "./error.service";

@Injectable({
  providedIn: 'root'
})
export class LandingDashboardService {

  constructor(private httpClient: HttpClient, private errorService: ErrorService) { }

  _isReadyToShowLoginErrorMsg$ = new BehaviorSubject<boolean>(false);
  _clientBrandDetails$ = new BehaviorSubject<ClientBrandDetailsDto | undefined>(undefined)
  _clientBrandDetails?: ClientBrandDetailsDto = undefined;

  setLoginButtonVisibility(value: boolean) {
    this._isReadyToShowLoginErrorMsg$.next(value);
  }

  get getClientBrandDetails$() { return this._clientBrandDetails$ };
  // Load Data
  getClientBrandDetailsData() {
    this.getClientBrandDetails().subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == AuctionwareConstants.SUCCESS_CODE) {
          this._clientBrandDetails = apiResponseDto.data as ClientBrandDetailsDto;
          this._clientBrandDetails$.next(this._clientBrandDetails);
        }
      },
      error: (error) => {
        console.error(error);
      }
    });
  }


  getClientBrandDetails() {
    return this.httpClient.get<ServerAPIResponseDto>('clientBrandDetails').pipe(
      tap(_ => console.log("Getting clientBrandDetails List to DB")),
      catchError(this.errorService.handleError<any>("Error while getting clientBrandDetails from DB")))
  }

}
