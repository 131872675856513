<div class="e-popup e-popupimage" id="e-popupgallery" style="display: block;">
  <a class="e-popupclose ea-popupremove" (click)="closeModal()">Close</a>
  <div class="e-carousel" data-active="0" data-animate="slide" data-image="contain" data-menu="image" data-ratio="3-2"
    data-shift="1">
    <div class="ee-body">
      <ul class="ee-list" [style.left]="'-'+ activeImage*100 + '%'" #gallery>
        <li *ngFor="let item of propertyImages; index as i" [style.left]="i*100 + '%'"
          [ngClass]="{'ee-active': activeImage == i}">
          <div class="ee-image">
            <img alt="image-{{i}}" src="/downloadLandingBlob?fileId={{item.fileId}}" *ngIf="!isValidUrl(item.fileId!)">
            <video class="video-carousel"  #videoCarousel [src]="item.fileId" *ngIf="isValidUrl(item.fileId!) && item.fileType! != 'embed'" [muted]="true"
            controls></video>
          <iframe class="video-carousel"  #iframeCarousel [src]="item.fileId! | safe"
            *ngIf="isValidUrl(item.fileId!) && item.fileType! == 'embed'"></iframe>
          </div>
        </li>
      </ul>
      <a class="ee-shift ee-shiftprev" (click)="prevImage()" *ngIf="activeImage != 0">Prev</a>
      <a class="ee-shift ee-shiftnext" (click)="nextImage()" *ngIf="activeImage != (propertyImages.length - 1)">Next</a>
    </div>
    <div class="ee-foot">
      <ul class="ee-menu" style="margin-left: 0px;">
        <li *ngFor="let item of propertyImages; index as i" [ngClass]="{'ee-active': i == activeImage}" (click)="changeImage(i)">
          <img alt="image-{{i}}" [src]="getImageUrl(item)" >
        </li>
      </ul>
    </div>
  </div>
</div>
