import { Injectable } from '@angular/core';
import { ToastrService, GlobalConfig } from 'ngx-toastr';
import { cloneDeep } from 'lodash-es';

@Injectable({ providedIn: 'root' })
export class AucProBidTosterService{

  private optionsToster: GlobalConfig;
  private types = ['success', 'error', 'info', 'warning'];
  private errorMsgs : Array<any> = [];

  constructor(private toster: ToastrService) {
    this.optionsToster = this.toster.toastrConfig;
    this.optionsToster.closeButton = true;
   }

  public openErrorToster(message?: string, tile?: string){
    this.clearErrorMsgs();
    this.addErrorMessage(message, tile, this.types[1]);
    this.openToast();
  }

  public openSuccessToster(message?: string, tile?: string){
    this.clearErrorMsgs();
    this.addErrorMessage(message, tile, this.types[0]);
    this.openToast();
  }

  public openInfoToster(message?: string, tile?: string){
    this.clearErrorMsgs();
    this.addErrorMessage(message, tile, this.types[2]);
    this.openToast();
  }

  private clearErrorMsgs(){
    this.errorMsgs = [];
  }

  private addErrorMessage(message?: string, title?: string, type?: string) {
    let msg = {
      message: message,
      title: title,
      type: type
    };
    this.errorMsgs.push(msg);
  }

  private openToast() {
    if (this.errorMsgs.length > 0) {
      this.errorMsgs.forEach(msg => {
        // Clone current config so it doesn't change when ngModel updates
        const opt = cloneDeep(this.optionsToster);
        const inserted = this.toster.show(
          msg.message,
          msg.title,
          opt,
          this.optionsToster.iconClasses[msg.type],
        );
        return inserted;
      })
    }
  }

}
